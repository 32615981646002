<template>
  <div @click="expand">
    <div class="p-grid item h-70-custom pointer" v-if="!isExpanded">
      <div class="p-col-4 p-p-0 p-as-center pic">
        <Avatar class="p-mr-2" size="xlarge" :image="target.image" />
      </div>

      <div
        class="p-col-8 p-as-center"
        :class="{ 'p-text-left': !isExpanded, 'p-text-right': isExpanded }"
      >
        <div class="vertical-container">
          <div class="p-col-12 title-mini p-pb-0">{{ target.name }}</div>
        </div>
      </div>
    </div>

    <div class="p-grid item" v-if="isExpanded">
      <div class="p-col-4 p-p-0 p-as-center pic-x">
        <div class="vertical-container">
          <Avatar class="p-mr-2" size="xlarge" :image="target.image" />
        </div>
      </div>

      <div class="p-col-8 p-as-center p-text-left">
        <div class="vertical-container">
          <div class="p-grid">
            <div class="p-col-12 p-pb-0 p-text-truncate name">
              <span class="title-mini">{{ target.name }}</span>
            </div>
            <div class="p-col-8 p-pb-0 action pointer">CLOSE</div>
            <div class="p-col-4 p-pb-0 action pointer" @click="onEdit">EDIT</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "Target",

  props: {
    target: {},
  },
  data() {
    return { isExpanded: false ,display: false};
  },
  computed: {
    ...mapState("clubModule", ["selectedClub"]),
  },
  methods: {
    onEdit() {
      this.$store.commit("targetModule/updateSelectedTarget", this.target);
      this.$router.push({ name: "update-target" });
    },
    expand() {
      this.isExpanded = !this.isExpanded;
    },
  },
};
</script>

<style lang="scss" scoped>
.title-mini {
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  color: #0a0a0a;
}

.item-x {
  background: #fbfbfb;
  border: 1px solid #cbcfd0;
  border-radius: 4px;
}

.name {
  font-weight: bold;
  font-size: 20px;
  letter-spacing: 0.15px;
  color: #000000da;
}

.sub-t {
  font-weight: bold;
  font-size: 14px;
  text-align: right;
  letter-spacing: 0.25px;
  color: #00000094;
}

.sub-value {
  font-size: 14px;
  line-height: 20px;
  text-align: right;
  letter-spacing: 0.25px;
  color: #828282;
}

.pic {
  background: #ffffff;
  height: 65px;
}

.pic-x {
  background: #ffffff;
  height: 90px;
}
</style>