<template>
  <div class="p-col-12 p-px-4 content-area">
    <div class="p-pt-3 custom-carousel">
      <div class="p-col-12 p-text-left p-pl-0 p-pt-4 p-pb-3 h-text">Participant</div>
      <Carousel :value="tiles">
        <template #item="slotProps">
          <dashboard-tile
            :title="slotProps.data.title"
            :navigateTo="slotProps.data.navigateTo"
            :iconSrc="slotProps.data.iconSrc"
          />
        </template>
      </Carousel>
      <div
        class="p-col-12 p-text-left p-pl-0 p-pt-4 p-pb-3 h-text"
        v-if="is_official && !user.isAdmin"
      >Official</div>
      <Carousel :value="Officialtiles" v-if="is_official && !user.isAdmin">
        <template #item="slotProps">
          <dashboard-tile
            :title="slotProps.data.title"
            :navigateTo="slotProps.data.navigateTo"
            :iconSrc="slotProps.data.iconSrc"
          />
        </template>
      </Carousel>
    </div>
    <div class="p-col-12 p-text-left p-pl-0 p-pt-4 p-pb-3 h-text">Today</div>
    <div
      class="p-col-12 p-text-left p-pl-0 p-pt-4 p-pb-3"
      v-if="matchSummary.length === 0"
    >Not registered to participate in any match today</div>
    <match-summary-accordion :matchSummary="matchSummary" />
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import DashboardTile from "../shared/DashboardTile.vue";
import MatchSummaryAccordion from "./MatchSummaryAccordion.vue";
export default {
  components: { DashboardTile, MatchSummaryAccordion },
  name: "participant",
  data() {
    return {
      matchSummary: []
    };
  },
  created() {
    this.tiles = [
      {
        title: this.constants.COMMON_TEXT.FIND_CLUB,
        navigateTo: "clubs",
        iconSrc: "find-club-icon.png"
      },
      {
        title: this.constants.COMMON_TEXT.FIND_MATCH,
        navigateTo: "matches",
        iconSrc: "find-match-icon.png"
      },
      {
        title: this.constants.COMMON_TEXT.REGISTERED_MATCHES,
        navigateTo: "registered-matches",
        iconSrc: "registered-matches.png"
      },
      {
        title: "My Scores",
        navigateTo: "view-scores",
        iconSrc: "registered-matches.png"
      }
    ];

    this.Officialtiles = [
      {
        title: this.constants.COMMON_TEXT.ASSIGNED_MATCHES,
        navigateTo: "assigned-matches",
        iconSrc: "assigned-matches.png"
      }
    ];

    this.$store
      .dispatch("eventModule/officialMatchesToday", this.userId)
      .then(() => {
        if (
          this.assignedMatchSummary.upcomingMatches > 0 &&
          !this.user.isAdmin
        ) {
          const data = {
            ...this.assignedMatchSummary,
            title: this.constants.COMMON_TEXT.ASSIGNED_MATCHES,
            iconSrc: "assigned-matches.png"
          };
          this.matchSummary = [...this.matchSummary, data];
        }
      });

    this.$store
      .dispatch("eventModule/participantMatchesToday", this.userId)
      .then(() => {
        if (this.participantMatchSummary.upcomingMatches > 0) {
          const data = {
            ...this.participantMatchSummary,
            title: this.constants.COMMON_TEXT.AS_PARTICIPANT,
            iconSrc: "as-participant-icon.png"
          };
          this.matchSummary = [...this.matchSummary, data];
        }
      });
  },
  computed: {
    ...mapState("authenticate", ["user", "is_official"]),
    ...mapGetters({
      userId: "authenticate/getUserId",
      assignedMatchSummary: "eventModule/getAssignedMatchesSummary",
      participantMatchSummary: "eventModule/getParticipantMatcheSummary",
      isOfficial: "authenticate/getUserIsOfficial"
    })
  }
};
</script>
<style lang="scss" scoped>
</style>