import { createApp } from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import production from "./environment/production.json";
import development from "./environment/development.json";

import "./main.scss";
import "./theme/md-light-indigo/theme.css"; //customizable theme
import "primevue/resources/primevue.min.css"; //core css
import "primeicons/primeicons.css"; //icons
import "primeflex/primeflex.css"; //

import VueClipboard from "vue3-clipboard";

import PrimeVue from "primevue/config";
import Message from "primevue/message";
import Calendar from "primevue/calendar";
import ProgressBar from "primevue/progressbar";
import InputText from "primevue/inputtext";
import Button from "primevue/button";
import Dropdown from "primevue/dropdown";
import TabMenu from "primevue/tabmenu";
import Sidebar from "primevue/sidebar";
import Avatar from "primevue/avatar";
import SelectButton from "primevue/selectbutton";
import Card from "primevue/card";
import FileUpload from "primevue/fileupload";
import InputNumber from "primevue/inputnumber";
import Checkbox from "primevue/checkbox";
import RadioButton from "primevue/radiobutton";
import Textarea from "primevue/textarea";
import AutoComplete from "primevue/autocomplete";
import Password from "primevue/password";
import InputMask from "primevue/inputmask";
import AvatarGroup from "primevue/avatargroup";
import ProgressSpinner from "primevue/progressspinner";
import MultiSelect from "primevue/multiselect";
import Carousel from "primevue/carousel";
import Skeleton from "primevue/skeleton";
import TabView from "primevue/tabview";
import TabPanel from "primevue/tabpanel";
import Divider from "primevue/divider";
import Accordion from "primevue/accordion";
import AccordionTab from "primevue/accordiontab";
import ScrollPanel from "primevue/scrollpanel";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import ColumnGroup from "primevue/columngroup";
import Row from "primevue/row";

import ConfirmationService from 'primevue/confirmationservice';
import ConfirmDialog from 'primevue/confirmdialog';
import Dialog from 'primevue/dialog';

import ToastService from "primevue/toastservice";
import Toast from "primevue/toast";

import mixin from "./helpers/mixins";

let config;

if (process.env.NODE_ENV === "production") {
  config = Object.freeze(production);
} else {
  config = Object.freeze(development);
}

let app = createApp(App);

app.mixin(mixin);

app.config.globalProperties.$BASE_URL = config.URL;
app.config.globalProperties.$FRONT_URL = config.FRONT_URL;

app
  .use(store)
  .use(router)
  .use(PrimeVue, { ripple: true })
  .use(VueClipboard, {
    autoSetContainer: true,
    appendToBody: true,
  })
  .use(ToastService)
  .use(ConfirmationService)

app
  .component("Dialog", Dialog)
  .component("ConfirmDialog", ConfirmDialog)
  .component("Message", Message)
  .component("Calendar", Calendar)
  .component("TabMenu", TabMenu)
  .component("Sidebar", Sidebar)
  .component("Avatar", Avatar)
  .component("SelectButton", SelectButton)
  .component("Card", Card)
  .component("FileUpload", FileUpload)
  .component("InputNumber", InputNumber)
  .component("ProgressBar", ProgressBar)
  .component("InputText", InputText)
  .component("Button", Button)
  .component("Checkbox", Checkbox)
  .component("RadioButton", RadioButton)
  .component("Textarea", Textarea)
  .component("Dropdown", Dropdown)
  .component("AutoComplete", AutoComplete)
  .component("Password", Password)
  .component("InputMask", InputMask)
  .component("AvatarGroup", AvatarGroup)
  .component("ProgressSpinner", ProgressSpinner)
  .component("MultiSelect", MultiSelect)
  .component("Carousel", Carousel)
  .component("Toast", Toast)
  .component("Skeleton", Skeleton)
  .component("TabView", TabView)
  .component("TabPanel", TabPanel)
  .component("Divider", Divider)
  .component("Accordion", Accordion)
  .component("AccordionTab", AccordionTab)
  .component("ScrollPanel", ScrollPanel)
  .component("DataTable", DataTable)
  .component("Column", Column)
  .component("ColumnGroup", ColumnGroup)
  .component("Row", Row)
app.mount("#app");
app.config.devtools = true;