import DivisionService from "../../services/division.service";

const state = () => ({
  selectedClubDivisions: [],
  res: "",
  selectedDivision: {},
  createDivisionStatus: "",
  updateDivisionStatus: "",
  deleteDivisionStatus:"",
  deleteDivisionCode: "",
  goBack: false,
});

// getters
const getters = {
  //
};

// actions
const actions = {
  async create({ commit }, division) {
    commit("create_division_request");
    await DivisionService.add(
      division,
      (res) => {
        commit("create_division_success", res.response);
      },
      (error) => {
        commit("create_division_error");
        console.error("error", error);
      }
    );
  },

  async update({ commit }, division) {
    commit("update_division_request");
    await DivisionService.update(
      division,
      (res) => {
        commit("update_division_success", res.response);
      },
      (error) => {
        commit("update_division_error");
        console.error("error", error);
      }
    );
  },
  divisionsForClub({ commit }, clubId) {
    DivisionService.getDivisionsByClub(
      clubId,
      (res) => {
        commit("get_divisions_success", res.response);
      },
      (error) => {
        console.error("error", error);
      }
    );
  },
  async deleteDivision({ commit }, divisionId) {
    commit("delete_division_request");
    await DivisionService.removeDivision(
      divisionId,
      (res) => {
        commit("delete_division_success", res.response);
      },
      (error) => {
        commit("delete_division_error", error.response.data.statusCode);
        console.error("error", error.response);
      }
    );
  },
};

// mutations
const mutations = {
  resetStatus(state) {
    state.createDivisionStatus = "";
    state.updateDivisionStatus = "";
    state.goBack = false;
  },
  //--create
  create_division_request(state) {
    state.createDivisionStatus = "loading";
  },
  create_division_success(state, res) {
    state.createDivisionStatus = "success";
    state.res = res;
  },
  create_division_error(state) {
    state.createDivisionStatus = "error";
  },
  //--update
  update_division_request(state) {
    state.updateDivisionStatus = "loading";
  },
  update_division_success(state, res) {
    state.updateDivisionStatus = "success";
    state.res = res;
  },
  update_division_error(state) {
    state.updateDivisionStatus = "error";
  },
  //
  updateSelectedDivision(state, division) {
    state.selectedDivision = division;
  },
  get_divisions_success(state, divisions) {
    state.selectedClubDivisions = divisions;
  },
  delete_division_request(state){
    state.deleteDivisionStatus = "loading";
  },
  delete_division_success(state, division){
    state.deleteDivisionStatus = "success";
    state.deleteDivisionCode = division;
  },

  delete_division_error(state, res){
    state.deleteDivisionStatus = "error";
    state.deleteDivisionCode = res;
  },

  create_another_division(state){
    state.goBack = true;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
