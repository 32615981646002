<template>
  <div>
    <confirmation
      v-if="updateUserProfileStatus === 'success'"
      :title="'You have successfully updated your profile '"
      :status="updateUserProfileStatus"
      :mutation="'authenticate/resetStatus'"
      :fallback="'/update-profile'"
    />
    <div class="p-grid background" v-if="updateUserProfileStatus !== 'success'">
      <div class="p-col-12">
        <div class="p-grid heading">
          <div class="p-col-2">
            <div @click="goBack">
              <i class="pi pi-arrow-left"></i>
            </div>
          </div>
          <div class="p-col-8">
            <label class="text-style">Profile</label>
          </div>
          <div class="p-col-2" />
        </div>
      </div>

      <div class="p-col-12 h-50-custom">
        <div class="open-file">
          <input
            ref="image"
            type="file"
            accept="image/*"
            @change="uploadImage($event)"
            id="upload-photo"
          />
        </div>
        <div class="picture-holder">
          <label for="upload-photo">
            <img
              class="p-mr-2 bg-grey-custom image-holder-size"
              size="xlarge"
              v-bind:src="logggedInUserModel.image"
              onerror="this.src='https://qspqa.quickscorepro.co.za/img/user-image.jpg'"
            />
          </label>
        </div>
      </div>

      <div class="p-col-12 content-area">
        <div class="content-top-space">
          <div class="p-field p-col-12">
            <div class="error-message " v-if="isSizeExceeded">
              {{ constants.IMAGE_VALIDATION.EXCEEDED_IMAGE_SIZE_ERROR }}
            </div>
            <span class="p-input-icon-left p-float-label">
              <i class="icon-padding"
                ><img class="" src="../../assets/icons/user-icon.png"
              /></i>
              <InputText
                id="firstName"
                type="text"
                placeholder="FirstName"
                v-model="logggedInUserModel.firstName"
                :class="{
                  'p-invalid': v$.logggedInUserModel.firstName.required.$invalid
                }"
              />
              <label for="firstName">Name</label>
            </span>
            <p
              class="error-message"
              v-if="v$.logggedInUserModel.firstName.required.$invalid"
            >
              * First name {{ errorMessage }}
            </p>
          </div>
          <div class="p-field p-col-12">
            <span class="p-input-icon-left p-float-label">
              <i class="icon-padding"
                ><img class="" src="../../assets/icons/user-icon.png" alt=""
              /></i>
              <InputText
                id="surname"
                type="text"
                placeholder="Surname"
                v-model="logggedInUserModel.surname"
                :class="{
                  'p-invalid': v$.logggedInUserModel.surname.required.$invalid
                }"
              />
              <label for="surname">Surname</label>
            </span>
            <p
              class="error-message"
              v-if="v$.logggedInUserModel.surname.required.$invalid"
            >
              * Surname {{ errorMessage }}
            </p>
          </div>
          <div class="p-field p-col-12">
            <span class="p-input-icon-left p-float-label">
              <i class="email-icon"
                ><img src="../../assets/icons/email.png" alt=""
              /></i>
              <InputText
                id="email"
                type="email"
                placeholder="Email address"
                v-model="logggedInUserModel.email"
                :class="{
                  'p-invalid':
                    v$.logggedInUserModel.email.required.$invalid ||
                    v$.logggedInUserModel.email.email.$invalid
                }"
              />
              <label for="firstName">Email address</label>
            </span>
            <p
              class="error-message"
              v-if="
                v$.logggedInUserModel.email.required.$invalid ||
                  v$.logggedInUserModel.email.email.$invalid
              "
            >
              * Email must be valid
            </p>
          </div>
          <div class="p-field p-col-12">
            <span class="p-input-icon-left p-float-label">
              <i class="icon-padding"
                ><img src="../../assets/icons/phone.png" alt=""
              /></i>
              <InputText
                id="id_num"
                type="id_num"
                placeholder="Cell number"
                v-model="logggedInUserModel.contactDetails"
                :class="{
                  'p-invalid':
                    v$.logggedInUserModel.contactDetails.required.$invalid
                }"
              />
              <label for="id_num">Cell Number</label>
            </span>
            <p
              class="error-message"
              v-if="v$.logggedInUserModel.contactDetails.required.$invalid"
            >
              * Cell number {{ errorMessage }}
            </p>
          </div>
          <div class="p-field p-col-12">
            <span class="p-input-icon-left p-float-label">
              <i class="icon-padding"
                ><img src="../../assets/icons/id-icon.png" alt=""
              /></i>
              <InputText
                id="id_num"
                type="id_num"
                placeholder="I.D number"
                v-model="logggedInUserModel.idNumber"
                :class="{
                  'p-invalid': v$.logggedInUserModel.idNumber.required.$invalid
                }"
              />
              <label for="id_num">I.D Number</label>
            </span>
            <p
              class="error-message"
              v-if="v$.logggedInUserModel.idNumber.required.$invalid"
            >
              * Id number {{ errorMessage }}
            </p>
          </div>
          <div class="p-field p-col-12">
            <span class="p-float-label p-input-icon-left">
              <i class="date-icon"
                ><img src="../../assets/icons/date.png" alt=""
              /></i>
              <Calendar
                id="dob"
                v-model="logggedInUserModel.dob"
                dateFormat="dd/mm/yy"
                :showTime="false"
                :showIcon="false"
                :yearNavigator="true"
                :monthNavigator="true"
                :hideOnDateTimeSelect="true"
                yearRange="1910:2030"
                :class="{
                  'p-invalid': v$.logggedInUserModel.dob.required.$invalid
                }"
              />
              <label for="inputtext-left">Date of birth</label>
            </span>
            <p
              class="error-message"
              v-if="v$.logggedInUserModel.dob.required.$invalid"
            >
              * Date of birth {{ errorMessage }}
            </p>
          </div>
          <div class="p-field p-col-12">
            <span class="p-input-icon-left p-float-label">
              <i class="icon-padding"
                ><img src="../../assets/icons/memb_no.png" alt=""
              /></i>
              <InputText
                id="membership_no"
                type="membership_no"
                placeholder="Membership Number"
                v-model="logggedInUserModel.membership_No"
              />
              <label for="membership_no">Membership No</label>
            </span>
          </div>
          <div class="p-field p-col-12">
            <span class="p-input-icon-left p-float-label">
              <i class="icon-padding"
                ><img src="../../assets/icons/user-icon.png" alt=""
              /></i>
              <InputText
                id="name"
                type="text"
                placeholder="Name"
                v-model="logggedInUserModel.name"
                :class="{
                  'p-invalid': v$.logggedInUserModel.name.required.$invalid
                }"
              />
              <label for="name">Username</label>
            </span>
            <p
              class="error-message"
              v-if="v$.logggedInUserModel.name.required.$invalid"
            >
              * User name {{ errorMessage }}
            </p>
          </div>
          <div>
            <p
              class="error-message"
              v-if="v$.logggedInUserModel.firstName.required.$invalid"
            >
              * First name {{ errorMessage }}
            </p>
            <p
              class="error-message"
              v-if="v$.logggedInUserModel.surname.required.$invalid"
            >
              * Surname {{ errorMessage }}
            </p>
          </div>
          <p
            class="error-message"
            v-if="
              v$.logggedInUserModel.email.required.$invalid ||
                v$.logggedInUserModel.email.email.$invalid
            "
          >
            * Email must be valid
          </p>
          <p
            class="error-message"
            v-if="v$.logggedInUserModel.contactDetails.required.$invalid"
          >
            * Cell number {{ errorMessage }}
          </p>
          <p
            class="error-message"
            v-if="v$.logggedInUserModel.idNumber.required.$invalid"
          >
            * Id number {{ errorMessage }}
          </p>
          <div class="p-col-12">
            <Message
              severity="error"
              v-if="updateUserProfileStatus === 'error'"
              :life="3000"
              :sticky="false"
              >Profile update failed. Please try again</Message
            >
          </div>
          <div class="p-col-12">
            <Button
              type="button"
              class="p-button-rounded p-button-raised"
              label="UPDATE"
              @click="updateProfile()"
            >
              <ProgressSpinner
                v-if="updateUserProfileStatus === 'loading'"
                class="progress-spinner-custom"
                strokeWidth="5"
                animationDuration=".5s"
              />
            </Button>
          </div>
          <Button
            label="CHANGE PASSWORD"
            class="p-button-text"
            @click="updatePassword()"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import useVuelidate from "@vuelidate/core";
import { required, email } from "@vuelidate/validators";
import successImage from "../../assets/successImage.svg";
import Confirmation from "../settings/Confirmation";
var picture = "";

export default {
  components: {
    Confirmation
  },
  name: "updateProfile",
  created() {
    this.logggedInUserModel = Object.assign(this.logggedInUserModel, this.user);
    this.logggedInUserModel.dob = new Date(this.logggedInUserModel.dob);
    this.isOpenProfile = false;
  },
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      logggedInUserModel: {
        id: "",
        firstName: "",
        name: "",
        surname: "",
        email: "",
        image: "",
        idNumber: "",
        PhoneNumber: "",
        membership_No: "",
        dob: new Date(),
        userImage: "",
        isOpenProfile: true,
        participantNo: ""
      },
      successImage: successImage,
      isSizeExceeded: false
    };
  },
  validations: {
    logggedInUserModel: {
      firstName: {
        required,
        $lazy: true
      },
      surname: {
        required,
        $lazy: true
      },
      email: {
        required,
        email,
        $lazy: true
      },
      contactDetails: {
        required,
        $lazy: true
      },
      dob: {
        required,
        $lazy: true
      },
      idNumber: {
        required,
        $lazy: true
      },
      name: {
        required,
        $lazy: true
      }
    }
  },
  computed: {
    ...mapState("authenticate", ["updateUserProfileStatus", "user"])
  },
  methods: {
    uploadImage(event) {
      this.isSizeExceeded = false;
      picture = event.target.files[0];
      const input = this.$refs.image;
      const files = input.files;
      this.fileSize = files[0].size;
      if (this.fileSize >= this.constants.IMAGE_VALIDATION.IMAGE_MAX_SIZE) {
        this.isSizeExceeded = true;
        picture = "";
      }
      if (
        files &&
        files[0] &&
        this.fileSize < this.constants.IMAGE_VALIDATION.IMAGE_MAX_SIZE
      ) {
        const reader = new FileReader();
        reader.onload = e => {
          this.logggedInUserModel.image = e.target.result;
        };
        reader.readAsDataURL(files[0]);
        this.$emit("input", files[0]);
      }
    },

    async updateProfile() {
      this.v$.$touch();
      if (!this.v$.$invalid) {
        let form = new FormData();
        form.append("id", this.logggedInUserModel.id);
        form.append("firstName", this.logggedInUserModel.firstName);
        form.append("name", this.logggedInUserModel.name);
        form.append("surname", this.logggedInUserModel.surname);
        form.append("email", this.logggedInUserModel.email);
        form.append("ID_num", this.logggedInUserModel.idNumber);
        form.append("phoneNumber", this.logggedInUserModel.contactDetails);
        form.append("membership_No", this.logggedInUserModel.membership_No);
        form.append("dob", this.logggedInUserModel.dob.toISOString());
        form.append("userImage", picture);
        await this.$store
          .dispatch("authenticate/updateProfile", form)
          .then(() => {
            this.$store.dispatch(
              "authenticate/getUserById",
              this.logggedInUserModel.id
            );
          });
      }
      this.isSizeExceeded = false;
    },
    updatePassword() {
      this.$router.push({ name: "update-password" });
    },
    goBack() {
      this.$router.push({ name: "settings" });
    }
  }
};
</script>

<style lang="scss" scoped>
.text-style {
  font-family: Roboto;
  font-weight: 500;
  font-size: 20px;
  line-height: 23px;
  text-align: center;
  color: #ffffff;
}

.image-c {
  opacity: 0;
}

.profole-text-style {
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;

  color: #3e4547;
}

.text-align {
  margin-top: 14px;
}

::v-deep .p-inputtext {
  padding-left: 45px;
}

.p-inputtext {
  margin: 0;
  margin-top: 12px;
}

.image-c {
  opacity: 0;
}

.user-image {
  width: 85px;
  height: 80px;
  border-radius: 50%;
}

.content-top-space {
  padding-top: 30px;
}

.email-icon {
  padding-top: 12px;
}

.icon-padding {
  padding-top: 7px;
}

.calendar-label {
  height: 12px;
  left: 72px;
  font-family: Roboto;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.54);
  padding-right: 225px;
  padding-bottom: 0px;
}

.date-icon {
  z-index: 1;
}
</style>
