<template>
  <div class="p-grid background">
    <div class="p-col-12">
      <div class="p-grid heading">
        <div class="p-col-2">
          <router-link to="/dashboard">
            <i class="pi pi-arrow-left"></i>
          </router-link>
        </div>
        <div class="p-col-8">
          <HeaderLogo />
          <h4>{{ constants.COMMON_TEXT.ASSIGNED_MATCHES }}</h4>
        </div>
        <div class="p-col-2"></div>
      </div>
    </div>

    <div class="p-col-12 p-pb-4 search">
      <span class="p-input-icon-left">
        <i class="pi pi-search" />
        <InputText type="text" v-model="search" placeholder="Search by club name " />
      </span>
    </div>

    <div class="p-col-12 p-p-4 content-area">
      <div class="p-col-12">
        <ProgressBar
          v-if="assignedMatchesStatus === 'loading'"
          mode="indeterminate"
          class="progress"
        />
      </div>
      <div
        class="p-col-12 p-text-center"
        v-if="
          assignedMatchesStatus === 'success' &&
            assignedMatches.length === 0 &&
            skip === 0 || clubsFiltered.length === 0
        "
      >No assigned matches</div>
      <div
        class="p-col-12 p-text-center"
        v-else-if="
          assignedMatchesStatus === 'error' && assignedMatches.length === 0
        "
      >An error occured, try reloading the page.</div>
      <div class="p-col-12" v-for="clubData in clubsFiltered" :key="clubData.matchID">
        <assigned-list-item :match="clubData" />
      </div>
      <div v-if="clubsFiltered.length > 9" @click="loadMore">
        <LoadMore />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import AssignedListItem from "./AssignedListItem.vue";
import LoadMore from "../../../main/LoadMore";
import HeaderLogo from "../../../shared/HeaderLogo.vue";
export default {
  name: "assignedMatches",
  components: {
    AssignedListItem,
    LoadMore,
    HeaderLogo
  },
  created() {
    this.skip = 0;
    this.clubList = [];
    this.$store.dispatch("clubModule/clubMatchPreviews");

    this.$store
      .dispatch("eventModule/assignedMatches", {
        userId: this.user.id,
        skip: this.skip
      })
      .then(() => {
        this.assignedMatches.forEach(x => {
          this.clubList.push({
            clubID: x.clubID,
            branchID: x.branchID,
            branchName: x.branchName,
            clubName: x.clubName,
            matchId: x.matchId,
            name: x.name,
            startDate: x.startDate,
            type: x.type,
            endDate: x.endDate,
            participants: x.participants,
            ranges: x.ranges,
            location: x.location,
            mapDirections: x.mapDirections,
            image: x.image
          });
        });
      });
  },
  data() {
    return {
      skip: 0,
      clubList: [],
      search: ""
    };
  },
  computed: {
    ...mapState("eventModule", ["assignedMatches", "assignedMatchesStatus"]),
    ...mapState("authenticate", ["user", "is_admin"]),

    clubsFiltered() {
      return this.clubList.filter(
        clubData =>
          this.search === "" ||
          clubData.clubName.toLowerCase().includes(this.search.toLowerCase())
      );
    }
  },
  methods: {
    loadMore() {
      this.skip = this.skip + 10;
      this.$store
        .dispatch("eventModule/assignedMatches", {
          userId: this.user.id,
          skip: this.skip
        })
        .then(() => {
          this.assignedMatches.forEach(x => {
            this.clubList.push({
              clubID: x.clubID,
              branchID: x.branchID,
              branchName: x.branchName,
              clubName: x.clubName,
              matchId: x.matchId,
              name: x.name,
              startDate: x.startDate,
              type: x.type,
              endDate: x.endDate,
              participants: x.participants,
              ranges: x.ranges,
              location: x.location,
              mapDirections: x.mapDirections,
              image: x.image
            });
          });
        });
    }
  }
};
</script>

<style lang="scss" scoped>
.title-mini {
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  color: #0a0a0a;
}

.sub-value-mini {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  color: #979797;
}
</style>