<template>
   <div class="p-grid centered-container h-100hv container">
      <div class="heading">
         <div class="p-col-12 p-mt-5">
            <img :src="logo" />
         </div>
         <div class="p-col-12">
            <h4>CREATE MATCH</h4>
            <p>
               You are about to create a match.
               Note that the following is required before you create a match.
               This can be set up by selecting the Club and setting up these parameters:
            <ul class="bullet-styling">
               <li>Match Categories</li>
               <li>Match Divisions</li>
               <li>Targets your club regularly use</li>
               <li>Branches of your club have different branches</li>
            </ul>
            During the creation of a match you will be able to:
            <ul class="bullet-styling">
               <li>Add Match Officials that will be able to capture scores for a specific match</li>
               <li>Select categories and add a fee for the match</li>
               <li>Select targets from your target bank and set up ranges or courses of fire</li>
               <li>Set up a match that is either a points based or time bases match or a combination of both</li>
               <li>Add sponsor logos to a Match</li>
            </ul>
            </p>
            <p>Click Continue to set you your Match</p>
         </div>
         <div class="p-col-12 p-pt-4 p-px-4 button-padding">
            <Button
               label="CONTINUE"
               class="p-button-rounded p-button-raised p-px-4 p-m-0"
               @click="continueToClub"
               />
         </div>
      </div>
   </div>
</template>
<script>
   import logo from "../../assets/logo.png";
   export default {
       data() {
           return {
               logo: logo,
           };
       },
       methods: {
           continueToClub() {
               this.$router.push({
                   name: "create-event"
               });
           },
       },
   };
</script>
<style lang="scss" scoped>
 .bullet-styling{
    text-align: left
 }
 .button-padding{
    padding-bottom: 100px
 }
</style>