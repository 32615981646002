<template>
   <div >
      <div class="p-grid item h-80-custom p-pl-1">
         <div class="p-col-3 p-as-center">
            <Avatar class="p-mr-2" size="xlarge" :image="admins.image" />
         </div>
         <div
            class="p-col-6 p-as-center p-text-left"
            @click="removeUserPopup(admins)"
            >
            <div class="vertical-container">
               <div class="p-col-12 p-text-truncate p-pt-0">
                  <span class="sub-value-mini-name">
                  {{ admins.firstName +" "+ admins.surname }}
                  </span>
               </div>
               <div class="p-col-12 p-text-truncate p-pt-0">
                  <span class="sub-value-mini">
                  {{ admins.email }}
                  </span>
                  <input type="hidden" v-bind:id="admins.firstName+admins.surname" :value="admins.email">
               </div>
            </div>
         </div>
         <div class="p-col-3 p-as-center p-text-right">
            <div class="vertical-container">
               <div class="box" >
                  <div class="text-primary" 
                     v-clipboard:success="copyEmailAddress"
                     v-clipboard:copy="`${admins.email}`"
                     v-if="!is_admin">
                     Copy
                  </div>
               </div>
            </div>
         </div>
      </div>
   </div>
</template>
<script>
import { mapState } from "vuex";
export default {
  name: "Admin",
  props: {
    admins: {},
  },
  data() {
    return { isExpanded: false };
  },
    computed: {
    ...mapState("authenticate", ["user", "is_admin"]),
  },
  methods: {
    onEdit() {
      this.$store.commit("branchModule/updateSelectedBranch", this.branch);
      this.$router.push({ name: "update-branch" });
    },
    onGoToClub() {
      this.$router.push({ name: "club" });
    },
    expand() {
      this.isExpanded = !this.isExpanded;
    },
    copyEmailAddress () {
      this.$toast.add({severity: 'info', summary: "Copied", group: 'br', life: 1000});
    },

    async removeUserPopup(admin) {
      if(this.is_admin == true && admin.clubOwner != true && admin.id != sessionStorage.getItem("id")){
        this.$store.commit("clubModule/updateSelectedAdminId", admin.id);
        this.$store.commit("clubModule/updateAdminInfo", admin);
        this.$store.commit("clubModule/updateSelectedAdmin", true);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.title-mini {
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  color: #0a0a0a;
}
.sub-t-mini {
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 14px;
  color: #979797;
}

.sub-value-mini {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: #979797;
}

.sub-value-mini-name {
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 0.15px;
  color: #000000;
}

.name {
  font-weight: bold;
  font-size: 20px;
  text-align: right;
  letter-spacing: 0.15px;
  color: #000000da;
}

.sub-t {
  font-weight: bold;
  font-size: 14px;
  text-align: right;
  letter-spacing: 0.25px;
  color: #00000094;
}

.sub-value {
  font-size: 14px;
  line-height: 20px;
  text-align: right;
  letter-spacing: 0.25px;
  color: #828282;
}

.pic {
  background: #e6e6e6;
  height: 75px;
}

.pic-x {
  background: #e6e6e6;
  height: 165px;
}

.toast{
  width:320px;
}
</style>