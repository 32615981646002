<template>
  <div class="p-grid background">
    <navbar-mobile :title="constants.COMMON_TEXT.REGISTERED_MATCHES" />
    <div class="p-col-12 p-pb-4 search">
      <span class="p-input-icon-left">
        <i class="pi pi-search" />
        <InputText type="text" v-model="search" placeholder="Search by match name" />
      </span>
    </div>
    <div class="p-col-12 p-p-4 content-area">
      <div class="p-col-12">
        <ProgressBar v-if="loading" mode="indeterminate" class="progress" />
      </div>
      <div class="p-col-12 p-text-center" v-if="requestInfo">{{requestInfo}}</div>
      <registered-match-details-accordion v-if="!loading" :matches="filteredMatches" />
      <div class="p-col-12" v-if="filteredMatches.length == 0">Not registered for any match</div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import RegisteredMatchDetailsAccordion from "./RegisteredMatchDetailsAccordion.vue";
import NavbarMobile from "../../../shared/NavbarMobile";
export default {
  components: { NavbarMobile, RegisteredMatchDetailsAccordion },
  data() {
    return {
      loading: false,
      requestInfo: "",
      search: ""
    };
  },
  computed: {
    ...mapGetters({
      getUserId: "authenticate/getUserId",
      getMatches: "eventModule/getRegisteredMatches"
    }),

    filteredMatches() {
      return this.getMatches.filter(
        a =>
          this.search === "" ||
          a.name.toLowerCase().includes(this.search.toLowerCase())
      );
    }
  },
  created() {
    this.loading = true;
    this.$store
      .dispatch("eventModule/getRegisteredMatchesById", this.getUserId)
      .then(() => null)
      .catch(err => {
        this.requestInfo = err.message;
      })
      .finally(() => (this.loading = false));
  },
  unmounted() {
    this.$store.commit("eventModule/update_registered_matches", []);
  }
};
</script>

<style lang="scss" scoped>
</style>