<template>
  <div class="p-fluid p-grid p-formgrid p-ai-start vertical-container p-m-0">
    <div class="p-col-12">
      <ProgressBar v-if="isLoading" mode="indeterminate" class="progress" />
    </div>
    <div class="p-col-12">
      <AutoComplete
        :multiple="true"
        v-model="selectedOfficials"
        :suggestions="searchUserList"
        placeholder="New official email"
        @complete="onEmailChange($event)"
        @item-select="onSelect"
        @item-unselect="unSelect"
        @clear="onClear()"
        field="email"
      />
    </div>
    <p class="error-message" v-if="!isOfficialSelected">
      * Atleast one official is required
    </p>
    <p class="error-message" v-if="!isOfficialFound && !isSearchCleared">
      * No user with that email found
    </p>
    <div class="p-col-12">
      <Message
        severity="error"
        v-if="searchStatus === 'error'"
        :life="3000"
        :sticky="false"
        >An error occured. Please try again</Message
      >
    </div>
    <div class="p-field p-col-12 p-pt-4">
      <Button
        label="Next"
        class="p-button-raised p-button-rounded p-px-2 p-m-0"
        @click="onNext"
      />
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "AddOfficial",
  data() {
    return {
      filteredList: null,
      selectedOfficials: [],
      email: "",
      query: "",
      isOfficialSelected: false,
      isOfficialFound: false,
      isSearchCleared: false,
      isLoading: false
    };
  },
  computed: {
    ...mapState("authenticate", ["searchStatus", "searchUserList"])
  },
  created() {
    this.isOfficialSelected = true;
    this.isOfficialFound = true;
  },
  methods: {
    async onEmailChange(event) {
      if (event.query.length >= 3 && event.query !== this.query) {
        if (this.searchStatus === "loading") {
          this.isLoading = true;
        }
        this.isSearchCleared = false;
        setTimeout(() => {
          this.getUsers(event.query);
        }, 500);
      }
    },

    async getUsers(query) {
      await this.$store.dispatch("authenticate/userSearch", query).then(() => {
        if (this.searchUserList.length == 0) {
          this.isOfficialFound = false;
          this.isLoading = false;
        } else {
          this.isOfficialFound = true;
        }
      });
      this.query = query;
      if (this.searchStatus === "error") {
        this.isOfficialFound = true;
        this.isLoading = false;
      }
    },

    onNext() {
      if (this.selectedOfficials.length == 0) this.isOfficialSelected = false;
      const entries = this.selectedOfficials.map(o => ({
        email: o.email
      }));
      if (entries.length > 0) this.$emit("new-officials", entries);
    },
    onSelect() {
      if (this.selectedOfficials != 0) this.isOfficialSelected = true;
    },
    unSelect() {
      if (this.selectedOfficials == 0) this.isOfficialSelected = false;
    },
    onClear() {
      this.isSearchCleared = true;
      this.isLoading = false;
    }
  }
};
</script>

<style lang="scss" scoped>
</style>