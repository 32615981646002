import { createRouter, createWebHistory } from "vue-router";
import Welcome from "../components/main/Welcome.vue";
import Faq from "../components/main/faq/Faq.vue";
import Home from "../components/main/Home.vue";
import Login from "../components/main/Login.vue";
import ForgotPassword from "../components/main/ForgotPassword.vue";
import CreateNewPassword from "../components/main/CreateNewPassword.vue";
import Register from "../components/main/Register.vue";
import EmailConfirmation from "../components/main/EmailConfirmation.vue";
import TermsAndConditions from "../components/main/terms-and-conditions/TermsAndConditions.vue";
import PrivacyPolicy from "../components/main/privacy-policy/PrivacyPolicy.vue";
import Dashboard from "../components/dashboard/Dashboard.vue";
import MatchInformation from "../components/events/MatchInformation.vue";
import CreateEvent from "../components/events/CreateEvent.vue";
import UpdateEvent from "../components/events/UpdateEvent.vue";
import Matches from "../components/events/match/Matches.vue";
import MatchPreviews from "../components/events/match/MatchPreviews.vue";
import AssignedMatches from "../components/events/match/assigned-matches/AssignedMatches.vue";
import ViewScores from "../components/events/match/capture-scores/View.vue";
import AdminMatchEdit from "../components/events/match/capture-scores/AdminMatchEdit.vue";
import AdminEditScore from "../components/events/match/capture-scores/AdminEditScores.vue";
import AdminScoreBoard from "../components/events/match/capture-scores/AdminScoreBoard.vue";
import ScoreBoard from "../components/events/match/capture-scores/ScoreBoard.vue";
import CaptureScore from "../components/events/match/capture-scores/CaptureScore.vue";
import ViewRanges from "../components/events/range/ViewMatchRanges.vue";
import ViewRangeTargets from "../components/events/range/ViewRangeTargets.vue";
import RangeScore from "../components/events/match/capture-scores/RangeScore.vue";
import Match from "../components/events/match/Match.vue";
import Settings from "../components/settings/Settings.vue";
import UpdateProfile from "../components/settings/UpdateProfile.vue";
import UpdatePassword from "../components/settings/UpdatePassword.vue";
import Notifications from "../components/notifications/Notifications.vue";
import ClubInformation from "../components/manage-clubs/ClubInformation.vue";
import CreateClub from "../components/manage-clubs/CreateClub.vue";
import Clubs from "../components/manage-clubs/Clubs.vue";
import Club from "../components/manage-clubs/Club.vue";
import UpdateClub from "../components/manage-clubs/UpdateClub.vue";
import CreateBranch from "../components/manage-clubs/branch/CreateBranch.vue";
import Branches from "../components/manage-clubs/branch/Branches.vue";
import UpdateBranch from "../components/manage-clubs/branch/UpdateBranch.vue";
import CreateTarget from "../components/manage-clubs/target/CreateTarget.vue";
import UpdateTarget from "../components/manage-clubs/target/UpdateTarget.vue";
import Targets from "../components/manage-clubs/target/Targets.vue";
import CreateCategory from "../components/manage-clubs/category/CreateCategory.vue";
import UpdateCategory from "../components/manage-clubs/category/UpdateCategory.vue";
import Categories from "../components/manage-clubs/category/Categories.vue";
import CreateDivision from "../components/manage-clubs/division/CreateDivision.vue";
import UpdateDivision from "../components/manage-clubs/division/UpdateDivision.vue";
import Divisions from "../components/manage-clubs/division/Divisions.vue";
import AddAdmin from "../components/manage-clubs/admin/AddAdmin.vue";
import RegisteredMatches from "../components/events/match/registered-matches/RegisteredMatches.vue";
import Admins from "../components/manage-clubs/admin/Admins";
import UpdateRangeItem from "../components/events/range/UpdateRangeItem.vue";
import ViewMatch from "../components/events/ViewMatch.vue";

import store from "../store";

const routes = [
  {
    path: "/",
    alias: '/dashboard',
    name: "dashboard",
    component: Dashboard,
    meta: { requiresAuth: true },
  },
  {
    path: "/welcome",
    name: "welcome",
    component: Welcome,
  },
  {
    path: "/faq",
    name: "faq",
    component: Faq,
  },
  {
    path: "/home",
    name: "home",
    component: Home,
  },
  {
    path: "/login",
    name: "login",
    component: Login,
  },
  {
    path: "/forgot-password",
    name: "forgot-password",
    component: ForgotPassword,
  },
  {
    path: "/new-password/",
    name: "new-password",
    component: CreateNewPassword,
  },
  {
    path: "/register",
    name: "register",
    component: Register,
  },
  {
    path: "/emailconfirmation/:userId?",
    name: "emailconfirmation",
    component: EmailConfirmation,
  },
  {
    path: "/terms-conditions",
    name: "terms-conditions",
    component: TermsAndConditions,
  },
  {
    path: "/private-policy",
    name: "private-policy",
    component: PrivacyPolicy,
  },
  {
    path: "/notifications",
    name: "notifications",
    component: Notifications,
    meta: { requiresAuth: true },
  },
  {
    path: "/settings",
    name: "settings",
    component: Settings,
    meta: { requiresAuth: true },
  },
  {
    path: "/update-profile",
    name: "update-profile",
    component: UpdateProfile,
    meta: { requiresAuth: true },
  },
  {
    path: "/update-password",
    name: "update-password",
    component: UpdatePassword,
    meta: { requiresAuth: true },
  },

  //
  //-- e v e n t / m a t c h
  {
    path: "/events/matchinformation",
    name: "match-information",
    component: MatchInformation,
    meta: { requiresAuth: true },
  },
  {
    path: "/events/create",
    name: "create-event",
    component: CreateEvent,
    meta: { requiresAuth: true },
  },
  {
    path: "/events/update",
    name: "update-event",
    component: UpdateEvent,
    meta: { requiresAuth: true },
  },
  {
    path: "/matches",
    name: "matches",
    component: Matches,
    meta: { requiresAuth: true },
  },
  {
    path: "/matches/assigned",
    name: "assigned-matches",
    component: AssignedMatches,
    meta: { requiresAuth: true },
  },
  {
    path: "/matches/viewscores",
    name: "view-scores",
    component: ViewScores,
    meta: { requiresAuth: true },
  },
  {
    path: "/matches/adminscoreedit",
    name: "admin-score-edit",
    component: AdminMatchEdit,
    meta: { requiresAuth: true },
  },
  {
    path: "/matches/viewmatch/:match_url?",
    name: "view-match",
    component: ViewMatch,
    meta: { requiresAuth: true },
  },
  //AdminEditScore
  {
    path: "/matches/admineditscore",
    name: "admin-participant-score-edit",
    component: AdminEditScore,
    meta: { requiresAuth: true },
  },
  //AdminScoreBoard
  {
    path: "/matches/adminscoreboard",
    name: "admin-score-board",
    component: AdminScoreBoard,
    meta: { requiresAuth: true },
  },
  {
    path: "/matches/scoreboard/:scoreBoardType?",
    name: "scoreboard",
    component: ScoreBoard,
    meta: { requiresAuth: true },
  },
  {
    path: "/match/previews",
    name: "match-previews",
    component: MatchPreviews,
    meta: { requiresAuth: true },
  },

  {
    path: "/match/:match_url?",
    name: "match",
    component: Match,
    meta: { requiresAuth: true },
  },

  {
    path: "/match/UpdateRangeItem",
    name: "UpdateRangeItem",
    component: UpdateRangeItem,
    meta: { requiresAuth: true },
  },

  //
  //-- c a p t u r e  s c o r e s
  {
    path: "/match/capture-score",
    name: "capture-score",
    component: CaptureScore,
    meta: { requiresAuth: true },
  },
  {
    path: "/match/range-score",
    name: "range-score",
    component: RangeScore,
    meta: { requiresAuth: true },
  },

  //
  //-- c l u b
  {
    path: "/clubs/clubinformation",
    name: "club-information",
    component: ClubInformation,
    meta: { requiresAuth: true },
  },
  {
    path: "/clubs/create",
    name: "create-club",
    component: CreateClub,
    meta: { requiresAuth: true },
  },
  {
    path: "/clubs/update",
    name: "update-club",
    component: UpdateClub,
    meta: { requiresAuth: true },
  },
  {
    path: "/clubs",
    name: "clubs",
    component: Clubs,
    meta: { requiresAuth: true },
  },
  {
    path: "/club",
    name: "club",
    component: Club,
    meta: { requiresAuth: true },
  },
  //-- registered matches
  {
    path: "/registered-matches",
    name: "registered-matches",
    component: RegisteredMatches,
    meta: { requiresAuth: true },
  },

  //
  //-- t a r g e t
  {
    path: "/target/create",
    name: "create-target",
    component: CreateTarget,
    meta: { requiresAuth: true },
  },
  {
    path: "/targets",
    name: "targets",
    component: Targets,
    meta: { requiresAuth: true },
  },
  {
    path: "/target/update",
    name: "update-target",
    component: UpdateTarget,
    meta: { requiresAuth: true },
  },

  //
  //-- b r a n c h
  {
    path: "/branches/create",
    name: "create-branch",
    component: CreateBranch,
    meta: { requiresAuth: true },
  },
  {
    path: "/branches",
    name: "branches",
    component: Branches,
    meta: { requiresAuth: true },
  },
  {
    path: "/branches/update",
    name: "update-branch",
    component: UpdateBranch,
    meta: { requiresAuth: true },
  },

  //
  //-- c a t e g o r y
  {
    path: "/category/create",
    name: "create-category",
    component: CreateCategory,
    meta: { requiresAuth: true },
  },
  {
    path: "/categories",
    name: "categories",
    component: Categories,
    meta: { requiresAuth: true },
  },
  {
    path: "/category/update",
    name: "update-category",
    component: UpdateCategory,
    meta: { requiresAuth: true },
  },

  //
  //-- d i v i s i o n
  {
    path: "/division/create",
    name: "create-division",
    component: CreateDivision,
    meta: { requiresAuth: true },
  },
  {
    path: "/divisions",
    name: "divisions",
    component: Divisions,
    meta: { requiresAuth: true },
  },
  {
    path: "/divisions/update",
    name: "update-division",
    component: UpdateDivision,
    meta: { requiresAuth: true },
  },
  {
    path: "/viewranges",
    name: "view-ranges",
    component: ViewRanges,
    meta: { requiresAuth: true },
  },
  {
    path: "/viewrangetargets",
    name: "view-range-targets",
    component: ViewRangeTargets,
    meta: { requiresAuth: true },
  },
    //-- d i v i s i o n

  //
  //-- a d m i n
  {
    path: "/admin/add",
    name: "add-admin",
    component: AddAdmin,
    meta: { requiresAuth: true },
  },

  {
    path: "/admins",
    name: "admins",
    component: Admins,
    meta: { requiresAuth: true },
  },

  {
    path: "/about",
    name: "About",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../components/main/About.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (!store.getters["authenticate/isAuthenticated"]) {
      next({
        path: "/welcome",
        query: { redirect: to.fullPath },
      });
    } else {
      next();
    }
  } else {
    next();
  }
});

export default router;
