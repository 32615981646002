<template>
   <div class="p-grid centered-container h-100hv container">
      <div class="heading">
         <div class="p-col-12">
            <img :src="logo" />
         </div>
         <div class="p-col-12">
            <h4>CREATE CLUB</h4>
            <p>
               You are about to create a club.
               Note that a club can only be created once based on the name and contact email.
               If you create the club, you will become the club admin with super user rights.
               This means that you can manage the club.
               Admin rights will allow you to add other admins, create matches, add targets and set divisions and categories and add officials to a match.
               If the club exists, you will be notified and you should see who the club admin is.
               In this case, you should contact the club admin and ask to be added as an admin.
            </p>
            <p>If you are authorised to create the club, please follow the next steps.</p>
         </div>
         <div class="p-col-12 p-pt-4 p-px-4">
            <Button
               label="CONTINUE"
               class="p-button-rounded p-button-raised p-px-4 p-m-0"
               @click="continueToClub"
               />
         </div>
      </div>
   </div>
</template>

<script>
import logo from "../../assets/logo.png";
export default {
    data() {
        return {
            logo: logo,
        };
    },
    methods: {
        continueToClub() {
            this.$router.push({
                name: "create-club"
            });
        },
    },
};
</script>