<template>
  <div>
    <confirmation
      v-if="registrationStatus === 'success'"
      :title="
        'You have successfully registered, a confirmation email has been sent to ' +
          registration.email
      "
      :status="registrationStatus"
      :mutation="'authenticate/resetStatus'"
      :fallback="'/login'"
    />
    <div
      class="p-grid background"
      v-show="
        page === constants.REGISTRATION.MAIN && registrationStatus !== 'success'
      "
    >
      <div class="p-col-12">
        <div class="p-grid heading">
          <div class="p-col-2">
            <router-link to="/home">
              <i class="pi pi-arrow-left"></i>
            </router-link>
          </div>
          <div class="p-col-8">
            <label class="text-style">Register</label>
          </div>
          <div class="p-col-2" />
        </div>
      </div>

      <div class="p-col-12 h-50-custom">
        <div class="open-file">
          <input
            ref="image"
            type="file"
            accept="image/*"
            @change="uploadImage($event)"
            id="upload-photo"
          />
        </div>
        <div class="picture-holder">
          <label for="upload-photo">
            <img
              class="p-mr-2 bg-grey-custom image-holder-size"
              size="xlarge"
              v-bind:src="picture"
            />
          </label>
        </div>
      </div>

      <div class="p-col-12 content-area">
        <div class="p-fluid p-formgrid p-grid avatar-botton-spacing">
          <div class="p-field p-col-12">
            <div class="error-message " v-if="isSizeExceeded">
              {{ constants.IMAGE_VALIDATION.EXCEEDED_IMAGE_SIZE_ERROR }}
            </div>
            <span class="p-float-label p-input-icon-left">
              <i class="pi pi-user" />
              <InputText
                id="name"
                type="text"
                v-model="registration.firstName"
                :class="{
                  'p-invalid': v$.registration.firstName.required.$invalid
                }"
              />
              <label for="inputtext-left">Name *</label>
            </span>
            <p
              class="error-message"
              v-if="v$.registration.firstName.required.$invalid"
            >
              First name {{ errorMessage }}
            </p>
          </div>

          <div class="p-field p-col-12">
            <span class="p-float-label p-input-icon-left">
              <i class="pi pi-user" />
              <InputText
                id="surname"
                type="text"
                v-model="registration.surname"
                :class="{
                  'p-invalid': v$.registration.surname.required.$invalid
                }"
              />
              <label for="inputtext-left">Surname *</label>
            </span>
            <p
              class="error-message"
              v-if="v$.registration.surname.required.$invalid"
            >
              Surname {{ errorMessage }}
            </p>
          </div>

          <div class="p-field p-col-12">
            <span class="p-float-label p-input-icon-left">
              <i class="pi pi-envelope" />
              <InputText
                id="email"
                type="email"
                v-model="registration.email"
                :class="{
                  'p-invalid':
                    v$.registration.email.required.$invalid ||
                    v$.registration.email.email.$invalid
                }"
              />
              <label for="inputtext-left">Email address *</label>
            </span>
            <p
              class="error-message"
              v-if="
                v$.registration.email.required.$invalid ||
                  v$.registration.email.email.$invalid
              "
            >
              Email must be valid
            </p>
          </div>

          <div class="p-field p-col-12">
            <span class="p-float-label p-input-icon-left">
              <i class="pi pi-phone" />
              <InputText
                id="cellNumber"
                v-model="registration.cellNumber"
                :class="{
                  'p-invalid': v$.registration.cellNumber.required.$invalid
                }"
              />
              <label for="inputtext-left">Cell Number *</label>
            </span>
            <p
              class="error-message"
              v-if="v$.registration.cellNumber.required.$invalid"
            >
              Cell number {{ errorMessage }}
            </p>
          </div>

          <div class="p-field p-col-12">
            <span class="p-float-label p-input-icon-left">
              <i class="pi pi-id-card" />
              <InputText
                id="id"
                v-model="registration.id"
                :class="{
                  'p-invalid': v$.registration.id.required.$invalid
                }"
              />
              <label for="inputtext-left">Id number *</label>
            </span>
            <p
              class="error-message"
              v-if="v$.registration.id.required.$invalid"
            >
              Id number {{ errorMessage }}
            </p>
          </div>

          <div class="p-field p-col-12">
            <span class="p-float-label p-input-icon-left">
              <i class="pi pi-id-card" />
              <Calendar
                id="dob"
                v-model="registration.dob"
                :showIcon="true"
                :yearNavigator="true"
                :monthNavigator="true"
                :hideOnDateTimeSelect="true"
                yearRange="1910:2030"
                :class="{
                  'p-invalid': v$.registration.dob.required.$invalid
                }"
              />
              <label for="inputtext-left">Date of birth *</label>
            </span>
            <p
              class="error-message"
              v-if="v$.registration.dob.required.$invalid"
            >
              Date of birth {{ errorMessage }}
            </p>
          </div>

          <div class="p-field p-col-12">
            <span class="p-float-label p-input-icon-left">
              <i class="pi pi-id-card" />
              <InputText
                id="MembershipNo"
                type="MembershipNo"
                v-model="registration.Membership_No"
              />
              <label for="inputtext-left">Narfo membership number</label>
            </span>
          </div>

          <div class="p-field p-col-12">
            <span class="p-float-label p-input-icon-left">
              <i class="pi pi-user" />
              <InputText
                id="username"
                type="username"
                v-model="registration.username"
                :class="{
                  'p-invalid': v$.registration.username.required.$invalid
                }"
              />
              <label for="inputtext-left">Username *</label>
            </span>
            <p
              class="error-message"
              v-if="v$.registration.username.required.$invalid"
            >
              Username {{ errorMessage }}
            </p>
          </div>

          <div class="p-field p-col-12">
            <Password
              v-model="registration.password"
              toggleMask
              placeholder="Password *"
              strongRegex="^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})"
              :class="{
                'p-invalid':
                  v$.registration.password.required.$invalid ||
                  v$.registration.password.minLength.$invalid
              }"
            >
              <template #footer>
                <Divider />
                <ul class="p-pl-2 p-ml-2 p-mt-0" style="line-height: 1.5">
                  <li>{{ lowerCase }}</li>
                  <li>{{ upperCase }}</li>
                  <li>{{ numeric }}</li>
                  <li>{{ characters }}</li>
                </ul>
              </template>
            </Password>
            <p
              class="error-message"
              v-if="v$.registration.password.required.$invalid"
            >
              Password {{ errorMessage }}
            </p>
          </div>

          <div class="p-field p-col-12">
            <Password
              id="ConfirmPass"
              v-model="registration.repeatPassword"
              toggleMask
              placeholder="Confirm password *"
              strongRegex="^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})"
              @change="() => (isPasswordValid = true)"
              :class="{
                'p-invalid':
                  v$.registration.repeatPassword.required.$invalid ||
                  !isPasswordValid
              }"
            >
            </Password>
            <p
              class="error-message"
              v-if="v$.registration.repeatPassword.required.$invalid"
            >
              Confirm password {{ errorMessage }}
            </p>
            <p
              class="error-message"
              v-if="
                registration.password !== registration.repeatPassword &&
                  registration.repeatPassword.length !== 0
              "
            >
              Confirm password must match password
            </p>
          </div>

          <div class="p-field-checkbox p-col-12 p-pt-4">
            <Checkbox id="terms" v-model="hasAcceptedTerms" :binary="true" />
            <label>
              <div class="p-text-left p-pl-2 link-c">
                I hereby agree to the
                <span @click="toggleTerms">Terms &amp; Conditions</span>
                and
                <span @click="togglePolicy">Privacy Policy</span>
              </div>
            </label>
          </div>
          <div
            class="p-col-12 error-message"
            v-if="v$.hasAcceptedTerms.checked.$invalid && isSubmit"
          >
            Terms and conditions not accepted
          </div>
          <div class="p-col-12">
            <Message
              severity="error"
              v-if="registrationStatus === 'error'"
              :life="3000"
              :sticky="false"
              >An error occured: {{ requestResponse.response }}</Message
            >
          </div>
          <div class="p-col-12">
            <div class="error-message " v-if="isSizeExceeded">
              {{ constants.IMAGE_VALIDATION.EXCEEDED_IMAGE_SIZE_ERROR }}*
            </div>
            <p
              class="error-message"
              v-if="v$.registration.firstName.required.$invalid"
            >
              First name {{ errorMessage }} *
            </p>
            <p
              class="error-message"
              v-if="v$.registration.surname.required.$invalid"
            >
              Surname {{ errorMessage }} *
            </p>
            <p
              class="error-message"
              v-if="
                v$.registration.email.required.$invalid ||
                  v$.registration.email.email.$invalid
              "
            >
              Email must be valid *
            </p>
            <p
              class="error-message"
              v-if="v$.registration.cellNumber.required.$invalid"
            >
              Cell number {{ errorMessage }} *
            </p>
            <p
              class="error-message"
              v-if="v$.registration.id.required.$invalid"
            >
              Id number {{ errorMessage }} *
            </p>
            <p
              class="error-message"
              v-if="v$.registration.dob.required.$invalid"
            >
              Date of birth {{ errorMessage }} *
            </p>
            <p
              class="error-message"
              v-if="v$.registration.username.required.$invalid"
            >
              Username {{ errorMessage }} *
            </p>
          </div>
          <div class="p-col-12 p-pt-4">
            <Button
              type="button"
              class="p-button-rounded p-button-raised"
              @click="validateForm"
              label="REGISTER"
            >
              <ProgressSpinner
                v-if="registrationStatus === 'loading'"
                class="progress-spinner-custom"
                strokeWidth="5"
                animationDuration=".5s"
              />
            </Button>
          </div>
        </div>
      </div>
    </div>

    <privacy-policy
      v-show="page === constants.REGISTRATION.POLICY"
      v-on:policy-back="onGoBack"
    />
    <terms-and-conditions
      v-show="page === constants.REGISTRATION.TERMS"
      v-on:terms-back="onGoBack"
    />
  </div>
</template>
<script>
import { mapState, mapGetters } from "vuex";
import useVuelidate from "@vuelidate/core";
import { required, email, minLength } from "@vuelidate/validators";
import PrivacyPolicy from "./privacy-policy/PrivacyPolicy.vue";
import TermsAndConditions from "./terms-and-conditions/TermsAndConditions.vue";
import CameraIcon from "../../assets/icons/camera-icon.svg";
import Confirmation from "../settings/Confirmation";

export default {
  name: "registration",
  components: { PrivacyPolicy, TermsAndConditions, Confirmation },
  created() {
    this.$store.commit("authenticate/resetStatus");
  },
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      formatedDob: "",
      errorMessage: "is required",
      page: "MAIN",
      lowerCase: "At least one lowercase",
      upperCase: "At least one uppercase",
      numeric: "At least one numeric",
      characters: "Minimum 8 characters",
      isPasswordValid: true,
      message: "",
      hasAcceptedTerms: false,
      picture: CameraIcon,
      registration: {
        firstName: "",
        surname: "",
        email: "",
        cellNumber: "",
        dob: "",
        id: "",
        Membership_No: "",
        username: "",
        password: "",
        repeatPassword: "",
        userImage: null
      },
      isSizeExceeded: false,
      isSubmit: false
    };
  },

  validations: {
    hasAcceptedTerms: {
      checked: value => value === true
    },
    registration: {
      firstName: {
        required,
        $lazy: true
      },
      surname: {
        required,
        $lazy: true
      },
      email: {
        required,
        email,
        $lazy: true
      },
      cellNumber: {
        required,
        $lazy: true
      },
      dob: {
        required,
        $lazy: true
      },
      Membership_No: {
        $lazy: true
      },
      id: {
        required,
        $lazy: true
      },
      username: {
        required,
        $lazy: true
      },
      password: {
        required,
        minLength: minLength(8),
        $lazy: true
      },
      repeatPassword: {
        required,
        $lazy: true
      },
      userImage: {
        $lazy: true
      }
    }
  },
  computed: {
    ...mapState("authenticate", ["registrationStatus", "requestResponse"]),
    ...mapGetters("authenticate", {
      isAuthenticated: "isAuthenticated"
    })
  },

  methods: {
    onGoBack() {
      this.page = this.constants.REGISTRATION.MAIN;
    },
    togglePolicy() {
      this.page = this.constants.REGISTRATION.POLICY;
    },
    toggleTerms() {
      this.page = this.constants.REGISTRATION.TERMS;
    },
    async doRegister() {
      if (!this.v$.$invalid) {
        this.formatedDob = this.registration.dob;
        this.registration.dob = this.registration.dob.toISOString();
        await this.$store.commit("authenticate/resetStatus");
        await this.$store.dispatch(
          "authenticate/registration",
          this.registration
        );
        if (this.isAuthenticated) {
          if (this.registrationStatus === "success") {
            this.isSubmit = false;
          }
        }
        if (this.registrationStatus === "error") {
          this.registration.dob = this.formatedDob;
        }
      }
    },

    uploadImage(event) {
      this.isSizeExceeded = false;
      this.registration.userImage = event.target.files[0];
      const input = this.$refs.image;
      const files = input.files;
      this.fileSize = files[0].size;
      if (this.fileSize >= this.constants.IMAGE_VALIDATION.IMAGE_MAX_SIZE) {
        this.isSizeExceeded = true;
        this.registration.userImage = null;
      }
      if (
        files &&
        files[0] &&
        this.fileSize < this.constants.IMAGE_VALIDATION.IMAGE_MAX_SIZE
      ) {
        const reader = new FileReader();
        reader.onload = e => {
          this.picture = e.target.result;
        };
        reader.readAsDataURL(files[0]);
        this.$emit("input", files[0]);
        document.getElementById("upload-photo").classList.add("image-c");
      }
    },
    async validateForm() {
      this.isSubmit = true;
      this.v$.$touch();
      if (
        this.registration.password === this.registration.repeatPassword &&
        !this.v$.$invalid
      ) {
        await this.doRegister();
      }
      if (
        !this.registration.email ||
        !this.registration.username ||
        !this.registration.firstName ||
        !this.registration.surname ||
        !this.registration.cellNumber ||
        !this.registration.id ||
        !this.registration.dob
      ) {
        await this.scrollToScreenTop();
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.link-c span {
  color: #3e4547;
  font-weight: bold;
}

.avatar-botton-spacing {
  padding-top: 30px;
}

.text-style {
  font-family: Roboto;
  font-weight: 500;
  font-size: 20px;
  line-height: 23px;
  text-align: center;
  color: #ffffff;
}

.image-c {
  opacity: 0;
}
.error-message {
  text-align: left;
}
:deep
  .p-datepicker
  .p-datepicker-header
  .p-datepicker-title
  .p-datepicker-month,
:deep .p-datepicker .p-datepicker-header .p-datepicker-title select {
  margin-right: 0.5rem;
  padding: 10px;
  border: 0;
  box-shadow: 0 5px 5px -3px rgb(0 0 0 / 20%), 0 8px 10px 1px rgb(0 0 0 / 14%),
    0 3px 14px 2px rgb(0 0 0 / 12%);
}
.error-message.p-pt-5 {
  margin-left: -50px;
}
.p-button-rounded.p-button-raised {
  margin-bottom: 20px;
}
:deep .p-avatar img {
  border-radius: 50px;
}
:deep .p-calendar .p-inputtext {
  margin-left: 30px;
}
.pi.pi-id-card {
  z-index: 1;
}

.required-image-error {
  margin-top: 40px;
}
</style>
