import { Service } from "./service";

class DivisionService extends Service {
  constructor() {
    super();
  }

  async add(payload, cb, errorCb) {
    await this.https
      .post(`division/AddDivision`, payload)
      .then((res) => {
        cb(res.data);
      })
      .catch((error) => {
        errorCb(error);
      });
  }

  async update(payload, cb, errorCb) {
    await this.https
      .put(`division/UpdateDivision`, payload)
      .then((res) => {
        cb(res.data);
      })
      .catch((error) => {
        errorCb(error);
      });
  }

  async getDivisionsByClub(clubId, cb, errorCb) {
    await this.https
      .get(`division/GetDivisions/${clubId}`)
      .then((res) => {
        cb(res.data);
      })
      .catch((error) => {
        errorCb(error);
      });
  }

  async removeDivision(divisionId, cb, errorCb) {
    await this.https
      .delete(`division/DeleteDivision/${divisionId}`)
      .then((res) => {
        cb(res.data);
      })
      .catch((error) => {
        errorCb(error);
      });
  }
}

export default new DivisionService();
