<template>
   <div class="p-grid background">
   <div class="p-col-12">
      <div class="p-grid heading">
         <div class="p-col-2" @click="onBack">
            <i class="pi pi-arrow-left"></i>
         </div>
         <div class="p-col-8">
            <h4>Privacy policy</h4>
         </div>
         <div class="p-col-2" />
         </div>
      </div>
      <div class="p-col-12 p-pt-4 content-area p-text-left">
         <p class="p-text-center p-text-bold">Privacy Policy</p>
         <p class="p-text-center">Quickscore Pro (QSP)</p>
         <p>This Privacy Policy describes how your personal information is collected, used, stored and shared when you visit <a href="www.quickscorepro.co.za">www.quickscorepro.co.za</a> (the “Site”) and your personal online profile.</p>
         <p>The database is hosted by a third party Internet Service Provider. All connection strings and passwords to electronically connect to this database from the NARFO website are encrypted and the website uses Secure Socket Layer (SSL) encryption.  </p>
         <p>We are committed to safeguarding the data and privacy of our members and website visitors. No personal information will be collected of any website visitor. </p>
         <h4>Security of your personal data</h4>
         <p>QSP will take reasonable technical and organizational precautions to prevent the loss, misuse or alteration of your personal information.</p>
         <p>We will store all the personal information you provide on servers provided by our webhosting provider.</p>
         <p>Of course, data transmission over the internet is inherently insecure, and we cannot guarantee the security of data sent over the internet.</p>
         <p>You are responsible for keeping your password and user details confidential. We will not ask you for your password.</p>
         <h4>Policy amendments</h4>
         <p>We may update this privacy policy from time-to-time by posting a new version on our website. You should check this page occasionally to ensure you are happy with any changes.</p>
         <p>[We may also notify you of changes to our privacy policy by email.]</p>
         <h4>PERSONAL INFORMATION WE COLLECT, STORE AND MAINTAIN</h4>
         <p>When you visit the Site, we may collect certain information about your device, including information about your web browser, IP address and time zone.</p>
         <p>Additionally when you join we collect certain information from you, including your name and surname, email address, ID number, username and password.  Your password is stored as an encrypted string.</p>
         <h4>HOW DO WE USE YOUR PERSONAL INFORMATION?</h4>
         <table class="table-width">
            <tr>
               <th>Information Item:</th>
               <th>Use:</th>
            </tr>
            <tr>
               <td>Name and Surname</td>
               <td>• Activity Register</td>
            </tr>
            <tr>
               <td>ID Number</td>
               <td>• Activity Register</td>
            </tr>
            <tr>
               <td>Email address</td>
               <td>• Email Communication</td>
            </tr>
         </table>
         <h4>UPDATING AND MAINTAINING PERSONAL INFORMATION</h4>
         <p>A registered user is responsible to maintain and update some of their personal information.</p>
         <p>The system will not allow First Names, Surnames and Identification numbers to be updated by the user to prevent fraudulent activities</p>
         <h4>SHARING YOUR PERSONAL INFORMATION</h4>
         <p>Personal Information that is shared with third parties are Name, Surname and Email address, for the sole purpose of making use of bulk mail systems to communicate QSP related information. Your personal information will never be sold, distributed, traded or otherwise made available to any other third party for whatever reason.</p>
         <p>We may also share your Personal Information to comply with applicable laws and regulations, to respond to a subpoena, search warrant or other lawful request for information we receive, or to otherwise protect our rights and comply with related laws, bi laws, acts and regulations related to your membership.</p>
         <p>QSP cannot be held liable if the member does not take own precautions to protect personal information by keeping log in details to The Site secured and protected at all times.</p>
         <h4>DATA RETENTION</h4>
         <p>All information you supply as a  result of your membership will be stored in our databases for as long as the system stays active. If you need to remove your details, you would need to log a call to have your files deleted.</p>
         <h4>CHANGES</h4>
         <p>We may update this privacy policy from time to time in order to reflect, for example, changes to our practices or for other operational, legal or regulatory reasons.</p>
         <div class="p-col-12">
            <Button
               type="button"
               class="p-button-rounded p-button-raised"
               @click="onBack"
               label="GO BACK"
               />
         </div>
      </div>
   </div>
</template>
<script>
export default {
  name: "privacy-policy",
  methods: {
    onBack() {
      this.$emit("policy-back");
    },
  },
};
</script>

<style lang="scss" scoped>
.table-width{
  width:100%
}
table, th, td {
  border: 1px solid black;
  border-collapse: collapse;
}
th, td {
  padding: 5px;
  text-align: left;    
}
</style>
