import AuthService from "../../services/auth.service";

const state = () => ({
  status: "",
  token: localStorage.getItem("token") || "",
  user: { isAdmin: false },
  registrationStatus: "",
  searchUserList: [],
  searchStatus: "",
  is_admin: false, //for toggling
  is_official: false,
  activeTab: 0,
  forgotPasswordStatus: "",
  resetPasswordStatus: "",
  resetPasswordResponse: "",
  updateUserProfileStatus: "",
  emailConfirmation: "",
  requestResponse: {},
});

// getters
const getters = {
  isAuthenticated: (state) => !!state.token,
  authStatus: (state) => state.status,
  token: (state) => state.token,
  getUserId: (state) => state.user.id,
  getUserIsOfficial: (state) => state.user.isOfficial,
};

// actions
const actions = {
  async login({ commit }, user) {
    commit("auth_request");
    await AuthService.login(
      user,
      (res) => {
        const token = res.response.token;
        localStorage.setItem("token", token);
        sessionStorage.setItem("id", res.response.id);
        commit("auth_success", res.response);
      },
      (error) => {
        commit("auth_error", error.response.data);
        localStorage.removeItem("token");
        localStorage.removeItem("vuex");
        console.error("error", error);
      }
    );
  },

  async forgotPassword({ commit }, email) {
    commit("forgot_password_request");
    await AuthService.forgotPassword(
      email,
      () => {
        commit("forgot_password_success");
      },
      (error) => {
        commit("forgot_password_error", error.response.data);
        console.error("error", error);
      }
    );
  },

  async resetPassword({ commit }, payload) {
    commit("reset_password_request");
    await AuthService.resetPassword(
      payload,
      (res) => {
        commit("reset_password_success", res.response);
      },
      (error) => {
        commit("reset_password_error");
        console.error("error", error);
      }
    );
  },

  async registration({ commit }, user) {
    commit("registration_request");
    await AuthService.registration(
      user,
      (res) => {
        commit("registration_success", res);
      },
      (error) => {
        commit("registration_error", error.response.data);
        console.error("error", error);
      }
    );
  },

  logout({ commit }) {
    return new Promise((resolve) => {
      commit("logout");
      AuthService.logout();
      localStorage.removeItem("token");
      localStorage.removeItem("vuex");
      sessionStorage.clear();

      AuthService.https.defaults.headers["Authorization"] = "";
      resolve();
    });
  },

  async userSearch({ commit }, query) {
    commit("search_request");
    await AuthService.userSearch(
      query,
      (res) => {
        commit("search_success", res.response);
      },
      (error) => {
        commit("search_error");
        console.error("error", error);
      }
    );
  },

  async userSearchByName({ commit }, query) {
    commit("search_request");
    await AuthService.userSearchByName(
      query,
      (res) => {
        commit("search_success", res.response);
      },
      (error) => {
        commit("search_error");
        console.error("error", error);
      }
    );
  },

  async updateProfile({ commit }, user) {
    commit("update_user_profile_request");
    await AuthService.updateUserProfile(
      user,
      (res) => {
        commit("update_user_profile_success", res.response.user);
      },
      (error) => {
        commit("update_user_profile_error");
        console.error("error", error);
      }
    );
  },

  async updatePassword({ commit }, payload) {
    commit("reset_password_request");
    await AuthService.updatePassword(
      payload,
      (res) => {
        commit("reset_password_success", res.response);
      },
      (error) => {
        commit("reset_password_error", error.response.data);
        console.error("error", error);
      }
    );
  },

  async getUserById({ commit }, query) {
    commit("auth_request");
    await AuthService.getUserById(
      query,
      (res) => {
        const token = res.response.token;
        localStorage.setItem("token", token);
        sessionStorage.setItem("id", res.response.id);
        commit("auth_success", res.response);
      },
      (error) => {
        commit("auth_error");
        localStorage.removeItem("token");
        localStorage.removeItem("vuex");
        console.error("error", error);
      }
    );
  },

  async emailConfirmation({ commit }, payload) {
    commit("email_confirmation_request");
    await AuthService.emailConfirmation(
      payload,
      (res) => {
        commit("email_confirmation_success", res.response);
      },
      (error) => {
        commit("email_confirmation_error");
        console.error("error", error);
      }
    );
  },

  async isOfficial({ commit }, userid) {
    commit("isOfficial_request");
    await AuthService.isOfficial(
      userid,
      (res) => {
        commit("isOfficial_success", res.isOfficial);
      },
      (error) => {
        commit("isOfficial_error");
        console.error("error", error);
      }
    );
  },
};

// mutations
const mutations = {
  resetStatus(state) {
    state.status = "";
    state.emailConfirmation = "";
    state.forgotPasswordStatus = "";
    state.registrationStatus = "";
    state.resetPasswordResponse = "";
    state.resetPasswordStatus = "";
    state.updateUserProfileStatus = "";
    state.requestResponse = {};
  },
  //
  auth_request(state) {
    state.status = "loading";
  },
  auth_success(state, res) {
    state.status = "success";
    state.token = res.token;
    state.user = { isAdmin: res.role[0] === "ClubAdmin", ...res };
    state.is_admin = state.user.isAdmin;
  },
  auth_error(state, res) {
    state.status = "error";
    state.requestResponse = res;
  },
  //
  registration_request(state) {
    state.registrationStatus = "loading";
  },
  registration_success(state, res) {
    state.registrationStatus = "success";
    state.requestResponse = res;
  },
  registration_error(state, res) {
    state.registrationStatus = "error";
    state.requestResponse = res;
  },
  //
  forgot_password_request(state) {
    state.forgotPasswordStatus = "loading";
  },
  forgot_password_success(state) {
    state.forgotPasswordStatus = "success";
    state.resetPasswordStatus = "";
  },
  forgot_password_error(state, res) {
    state.forgotPasswordStatus = "error";
    state.requestResponse = res
  },
  //
  reset_password_request(state) {
    state.resetPasswordStatus = "loading";
  },
  reset_password_success(state, res) {
    state.resetPasswordStatus = "success";
    state.resetPasswordResponse = res;
  },
  reset_password_error(state, res) {
    state.resetPasswordStatus = "error";
    state.requestResponse = res
  },
  //
  logout(state) {
    state.searchStatus = "";
  },
  search_request(state) {
    state.searchStatus = "loading";
  },
  search_success(state, users) {
    state.searchStatus = "success";
    state.searchUserList = users;
  },
  search_error(state) {
    state.searchStatus = "error";
  },

  //
  updateActingRole(state, role) {
    state.is_admin = role;
  },

  updateActiveTab(state, active) {
    state.activeTab = active;
  },

  updateAdmin(state) {
    state.user.isAdmin = true;
    state.is_admin = true;
  },

  update_user_profile_request(state) {
    state.updateUserProfileStatus = "loading";
  },
  update_user_profile_success(state, user) {
    state.updateUserProfileStatus = "success";
    state.user = user;
  },
  update_user_profile_error(state) {
    state.updateUserProfileStatus = "error";
  },

  email_confirmation_request(state) {
    state.emailConfirmation = "loading";
  },
  email_confirmation_success(state) {
    state.emailConfirmation = "success";
  },
  email_confirmation_error(state) {
    state.emailConfirmation = "error";
  },

  isOfficial_request(state) {
    state.is_official = "loading";
  },
  isOfficial_success(state, res) {
    state.is_official = "success";
    state.is_official = res;
  },
  isOfficial_error(state) {
    state.is_official = "error";
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
