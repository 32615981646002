<template>
  <div class="p-grid background">
    <div class="p-col-12 p-pb-0">
      <div class="p-grid heading p-pb-0">
        <div class="p-col-2">
          <router-link to="/matches">
            <i class="pi pi-arrow-left"></i>
          </router-link>
        </div>
        <div class="p-col-8 h-title">Match ranges</div>
        <div class="p-col-2"></div>
      </div>
    </div>

    <div class="p-col-12 h-sub-title p-pt-0 p-pb-4">
      {{ selectedMatch.name }}
    </div>

    <div class="p-col-12 p-p-4 content-area">
      <div class="p-col-12">
        <ProgressBar
          v-if="getMatchRangesStatus === 'loading'"
          mode="indeterminate"
          class="progress"
        />
      </div>
      <div
        class="p-col-12 p-text-center"
        v-if="getMatchRangesStatus === 'success' && matchRanges.length === 0"
      >
        No records found.
      </div>

      <div
        class="p-col-12 p-text-center"
        v-else-if="getMatchRangesStatus === 'error' && matchRanges.length === 0"
      >
        An error occured, try reloading the page.
      </div>

      <div class="p-col-12" v-for="range in matchRanges" :key="range.matchID">
        <div class="p-grid item h-80-custom range-item pointer" @click="onOpenRange(range)">
          <div class="p-col-4 p-as-center"> 
            <Avatar class="p-mr-2" size="xlarge" :image="range.imageUrl" />
          </div>

          <div class="p-col-8 p-as-center p-text-left h-text">
            <div class="vertical-container">
              <div class="box">{{ range.name }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "view-ranges",
  computed: {
    ...mapState("captureScoreModule", [
      "matchRanges",
      "getMatchRangesStatus",
      "selectedMatch"
    ]),
    ...mapState("targetModule", ["selectedRangeTarget"]),
    ...mapState("authenticate", ["user", "is_admin"]),
  },
  methods: {
    onOpenRange(range) {
      this.$store.commit("targetModule/updateRangeTarget", range);
      this.$store.commit("targetModule/updatePath", "fromMatches");
      this.$router.push({ name: "view-range-targets" });
    },
  },
};
</script>

<style lang="scss" scoped>
.range-item {
  .p-avatar.p-avatar-xl {
    width: 70px;
    height: 65px;
    font-size: 2rem;
    object-fit: contain;
    img {
      object-fit: cover;
    }
  }
}

.title-mini {
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  color: #0a0a0a;
}

.sub-value-mini {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  color: #979797;
}
</style>