<template>
  <div class="card">
    <div>
      <Message
        severity="success"
        v-if="isDownload"
        :life="3000"
        @close="onClose()"
        >Download completed</Message
      >
    </div>
    <DataTable
      :value="scoresData"
      ref="dt"
      class="p-datatable-sm"
      responsiveLayout="scroll"
    >
      <Column
        v-for="col of finalScores"
        :field="col.field"
        :header="col.header"
        :key="col.field"
        :sortable="true"
      ></Column>
    </DataTable>
  </div>
</template>
<script>
import { mapState } from "vuex";
export default {
  name: "ScoreTable",
  props: {
    finalScores: {},
    scoresData: {}
  },
  data() {
    return {
      isDownload: false
    };
  },
  computed: {
    ...mapState("captureScoreModule", ["download"]),
    ...mapState("authenticate", ["user", "is_admin"])
  },
  watch: {
    download: function() {
      if (this.download === "download") {
        this.$refs.dt.exportCSV();
        this.$store.commit("captureScoreModule/updateDownload", "");
        this.isDownload = true;
      }
    }
  },
  methods: {
    exportCSV() {
      this.$refs.dt.exportCSV();
    },

    onClose() {
      this.isDownload = false;
    }
  }
};
</script>
<style lang="scss" scoped>
:deep .p-sortable-column-icon {
  display: block;
}
</style>