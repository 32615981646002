import { DateTime } from 'luxon';

const timeFromDateString = function (dateString) {
  if (!dateString) {
    return 'none';
  }
  const date = DateTime.fromISO(dateString);
  if(!date.isValid){
    return 'invalid';
  }
  return date.toLocaleString(DateTime.TIME_24_SIMPLE);
}

const abbreviatedDateTimefromDateString = function (dateString) {
  if (!dateString) {
    return 'none';
  }
  const date = DateTime.fromJSDate(new Date(dateString));
  if(!date.isValid){
    return 'invalid';
  }

  return date.toLocaleString(DateTime.DATETIME_MED);
}

const abbreviatedDatefromDateString = function (dateString) {
  if (!dateString) {
    return 'none';
  }
  const date = DateTime.fromJSDate(new Date(dateString));
  if(!date.isValid){
    return 'invalid';
  }

  return date.toLocaleString(DateTime.DATE_MED);
}

export default {
  timeFromDateString,
  abbreviatedDatefromDateString,
  abbreviatedDateTimefromDateString,
};