import TargetService from "../../services/target.service";

const state = () => ({
  targets: [],
  res: {},
  selectedClubTargets: [],
  selectedTarget: {},
  selectedRangeTarget:[],
  updateTargetStatus: "",
  createTargetStatus: "",
  deleteTargetStatus:"",
  deleteTargetText: "",
  goBack: false,
  goBackPath: "",
});

// getters
const getters = {
  getClubTargets: (state) => state.selectedClubTargets.map(target => target.image),
};

// actions
const actions = {
  async create({ commit }, target) {
    commit("create_target_request");
    await TargetService.add(
      target,
      (res) => {
        commit("create_target_success", res.response);
      },
      (error) => {
        commit("create_target_error");
        console.error("error", error);
      }
    );
  },

  async update({ commit }, target) {
    commit("update_target_request");
    await TargetService.update(
      target,
      (res) => {
        commit("update_target_success", res.response);
      },
      (error) => {
        commit("update_target_error");
        console.error("error", error);
      }
    );
  },

  targets({ commit }) {
    TargetService.getTargets(
      (res) => {
        commit("get_targets_success", res.response);
      },
      (error) => {
        console.error("error", error);
      }
    );
  },

  targetsForClub({ commit }, clubId) {
    TargetService.getTargetsByClub(
      clubId,
      (res) => {
        commit("get_targets_for_club_success", res.response);
      },
      (error) => {
        console.error("error", error);
      }
    );
  },

  async deleteTarget({ commit }, targetId) {
    commit("delete_target_for_club_request");
    await TargetService.removeTarget(
      targetId,
      (res) => {
        commit("delete_target_for_club_success", res.response);
      },
      (error) => {
        commit("delete_target_for_club_error");
        console.error("error", error);
      }
    );
  },
};

// mutations
const mutations = {
  resetStatus(state) {
    state.createTargetStatus = "";
    state.goBack = false;
  },
  //--create
  create_target_request(state) {
    state.createTargetStatus = "loading";
  },
  create_target_success(state, res) {
    state.createTargetStatus = "success";
    state.res = res;
  },
  create_target_error(state) {
    state.createTargetStatus = "error";
  },
  //--update
  update_target_request(state) {
    state.updateTargetStatus = "loading";
  },
  update_target_success(state, res) {
    state.updateTargetStatus = "success";
    state.res = res;
  },
  update_target_error(state) {
    state.updateTargetStatus = "error";
  },
  //
  updateSelectedTarget(state, target) {
    state.selectedTarget = target;
  },
  get_targets_success(state, targets) {
    state.targets = targets;
  },
  get_targets_for_club_success(state, selectedClubTargets) {
    state.selectedClubTargets = selectedClubTargets;
  },

  delete_target_for_club_request(state){
    state.deleteTargetStatus = "loading";
  },

  delete_target_for_club_success(state, target){
    state.deleteTargetStatus = "success";
    state.deleteTargetText = target;
  },

  delete_target_for_club_error(state){
    state.deleteTargetStatus = "error";
  },

  create_another_target(state){
    state.goBack = true;
    state.createTargetStatus = "";
  },

  //update selected range target
  updateRangeTarget(state, res) {
    state.selectedRangeTarget = res;
  },
  //--
  updatePath(state, res) {
    state.goBackPath = res;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
