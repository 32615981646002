<template>
  <div>
    <confirmation
      v-if="isSubmitted"
      :title="'Branch has successfully been updated'"
      :status="updateBranchStatus"
      :fallback="'/branches'"
    />

    <div class="p-grid background" v-if="!isSubmitted">
      <div class="p-col-12">
        <div class="p-grid heading">
          <div class="p-col-2" @click="onBack">
            <i class="pi pi-arrow-left"></i>
          </div>
          <div class="p-col-8">Update club branch</div>
          <div class="p-col-2" />
        </div>
      </div>

      <div class="p-col-12 h-50-custom">
        <div class="open-file">
          <input
            ref="image"
            type="file"
            accept="image/*"
            @change="uploadImage($event)"
            class="pi pi-arrow-left"
            id="upload-photo"
          />
        </div>
        <div class="picture-holder">
          <label for="upload-photo">
            <img
              v-bind:src="branchModel.image"
              class="p-mr-2 bg-grey-custom image-holder-size"
              size="xlarge"
              shape="circle"
            />
          </label>
        </div>
      </div>

      <div class="p-col-12 p-pt-4 content-area">
        <div class="p-fluid p-formgrid p-grid">
          <div
            class="p-col-12 error-message required-image-error"
            v-if="branchModel.picture == '' && branchModel.branchImageUrl == ''"
          >
            {{ constants.IMAGE_VALIDATION.IMAGE_REQUIRED }}
          </div>
          <div class="p-col-12 error-message " v-if="isSizeExceeded">
            {{ constants.IMAGE_VALIDATION.EXCEEDED_IMAGE_SIZE_ERROR }}
          </div>
          <div class="p-col-12 p-text-left p-pt-4 p-pb-3">Branch details</div>

          <div class="p-field p-col-12">
            <span class="p-input-icon-left">
              <i class="pi pi-globe" />
              <InputText
                type="text"
                placeholder="Branch name"
                v-model="branchModel.branchName"
                :class="{
                  'p-invalid': v$.branchModel.branchName.required.$invalid
                }"
              />
            </span>
            <p
              class="error-message"
              v-if="v$.branchModel.branchName.required.$invalid"
            >
              * Branch name is required
            </p>
          </div>

          <div class="p-field p-col-12">
            <span class="p-input-icon-left">
              <i class="pi pi-envelope" />
              <InputText
                id="email"
                type="email"
                placeholder="Email address"
                v-model="branchModel.email"
                :class="{
                  'p-invalid':
                    v$.branchModel.email.required.$invalid ||
                    v$.branchModel.email.email.$invalid
                }"
              />
            </span>
            <p
              class="error-message"
              v-if="v$.branchModel.email.required.$invalid"
            >
              {{ constants.FORM_VALIDATION_ERRORS.EMAIL_REQUIRED }}
            </p>
            <p class="error-message" v-if="v$.branchModel.email.email.$invalid">
              {{ constants.FORM_VALIDATION_ERRORS.EMAIL_INVALID }}
            </p>
          </div>

          <div class="p-field p-col-12">
            <span class="p-input-icon-left">
              <i class="pi pi-map-marker" />
              <InputText
                id="branchAddress"
                type="text"
                placeholder="Location"
                v-model="branchModel.branchAddress"
                :class="{
                  'p-invalid': v$.branchModel.branchAddress.required.$invalid
                }"
              />
            </span>
            <p
              class="error-message"
              v-if="v$.branchModel.branchAddress.required.$invalid"
            >
              {{ constants.FORM_VALIDATION_ERRORS.LOCATION_REQUIRED }}
            </p>
          </div>
          <div class="p-field p-col-12 p-text-left">
            <span class="p-input-icon-left">
              <i class="pi pi-search index-1" />
              <Dropdown
                v-model="branchModel.provinceID"
                :options="provinces"
                class="p-pl-4"
                optionLabel="name"
                optionValue="value"
                placeholder="Province"
                :class="{
                  'p-invalid': v$.branchModel.provinceID.required.$invalid
                }"
              />
            </span>
          </div>

          <div class="p-field p-col-12">
            <span class="p-input-icon-left">
              <i class="pi pi-map" />
              <InputText
                id="googleMap"
                type="text"
                placeholder="Google maps link"
                v-model="branchModel.googleMaps"
                :class="{
                  'p-invalid': v$.branchModel.googleMaps.required.$invalid
                }"
              />
            </span>
            <p
              class="error-message"
              v-if="v$.branchModel.googleMaps.required.$invalid"
            >
              {{ constants.FORM_VALIDATION_ERRORS.GOOGLE_LINK_REQUIRED }}
            </p>
          </div>

          <div class="p-col-12 p-text-left p-pt-4 p-pb-3">Club admin</div>

          <div class="p-col-12 p-field">
            <span class="p-input-icon-left">
              <i class="pi pi-user" />

              <InputText
                type="text"
                placeholder="Admin name"
                v-model="user.name"
                disabled
              />
            </span>
          </div>
          <div class="p-field p-col-12">
            <span class="p-input-icon-left">
              <i class="pi pi-envelope" />

              <InputText
                type="text"
                placeholder="email address"
                v-model="user.email"
                disabled
              />
            </span>
          </div>
          <div class="p-col-12">
            <div
              class="p-col-12 error-message required-image-error"
              v-if="
                branchModel.picture == '' && branchModel.branchImageUrl == ''
              "
            >
              {{ constants.IMAGE_VALIDATION.IMAGE_REQUIRED }}
            </div>
            <div class="p-col-12 error-message " v-if="isSizeExceeded">
              {{ constants.IMAGE_VALIDATION.EXCEEDED_IMAGE_SIZE_ERROR }}
            </div>
            <p
              class="error-message"
              v-if="v$.branchModel.branchName.required.$invalid"
            >
              * Branch name is required
            </p>
            <p
              class="error-message"
              v-if="v$.branchModel.email.required.$invalid"
            >
              {{ constants.FORM_VALIDATION_ERRORS.EMAIL_REQUIRED }}
            </p>
            <p class="error-message" v-if="v$.branchModel.email.email.$invalid">
              {{ constants.FORM_VALIDATION_ERRORS.EMAIL_INVALID }}
            </p>
            <p
              class="error-message"
              v-if="v$.branchModel.branchAddress.required.$invalid"
            >
              {{ constants.FORM_VALIDATION_ERRORS.LOCATION_REQUIRED }}
            </p>
          </div>
          <div class="p-field p-col-12 p-pt-4">
            <Button
              class="p-button-rounded p-button-raised"
              type="button"
              @click="updateBranch()"
              label="Update"
              ><ProgressSpinner
                v-if="updateBranchStatus === 'loading'"
                class="progress-spinner-custom"
                strokeWidth="5"
                animationDuration=".5s"
              />
            </Button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import useVuelidate from "@vuelidate/core";
import { required, email, maxLength } from "@vuelidate/validators";
import { mapState } from "vuex";
import Confirmation from "../Confirmation.vue";

export default {
  components: { Confirmation },
  name: "updateBranch",
  setup() {
    return { v$: useVuelidate() };
  },
  created() {
    this.$store.dispatch("utilModule/provinces");
    this.$store.commit("branchModule/resetStatus");
    this.branchModel = this.selectedBranch;
  },
  data() {
    return {
      isSubmitted: false,
      selectedAdmin: { email: "", userName: "", id: "" },
      filteredList: null,
      branchModel: {
        branchName: "",
        branchAddress: "",
        provinceID: 0,
        Website: "",
        email: "",
        Telephone: "",
        googleMaps: "",
        userPhoto: "",
        userImageUrl: "",
        Description: "",
        UserID: "",
        picture: ""
      },
      isSizeExceeded: false
    };
  },
  validations: {
    branchModel: {
      branchName: {
        required,
        $lazy: true
      },
      branchAddress: {
        required,
        maxLength: maxLength(120),
        $lazy: true
      },
      provinceID: {
        required,
        $lazy: true
      },

      email: {
        required,
        email,
        $lazy: true
      },

      googleMaps: {
        required,
        $lazy: true
      }
    }
  },
  computed: {
    ...mapState("branchModule", ["updateBranchStatus", "selectedBranch"]),
    ...mapState("clubModule", ["selectedClub"]),
    ...mapState("utilModule", ["status", "provinces"]),
    ...mapState("authenticate", ["user"])
  },

  methods: {
    onBack() {
      this.$router.push({ name: "branches" });
      this.$store.commit("branchModule/updateSelectedBranch", {});
    },
    async updateBranch() {
      this.v$.$touch();
      if (!this.v$.$invalid) {
        let form = new FormData();
        form.append("branchID", this.selectedBranch.branchID);
        form.append("branchName", this.branchModel.branchName);
        form.append("userID", this.user.id);
        form.append("clubID", this.selectedClub.clubID);
        form.append("email", this.branchModel.email);
        form.append("branchAddress", this.branchModel.branchAddress);
        form.append("provinceID", this.branchModel.provinceID);
        form.append("googleMaps", this.branchModel.googleMaps);
        form.append("userPhoto", this.branchModel.picture);
        this.isSubmitted = true;
        await this.$store.dispatch("branchModule/update", form);
      }
    },

    uploadImage(event) {
      this.isSizeExceeded = false;
      this.branchModel.picture = event.target.files[0];
      const input = this.$refs.image;
      const files = input.files;
      this.fileSize = files[0].size;
      if (this.fileSize >= this.constants.IMAGE_VALIDATION.IMAGE_MAX_SIZE) {
        this.isSizeExceeded = true;
        this.branchModel.picture = "";
      }
      if (
        files &&
        files[0] &&
        this.fileSize < this.constants.IMAGE_VALIDATION.IMAGE_MAX_SIZE
      ) {
        const reader = new FileReader();
        reader.onload = e => {
          this.branchModel.image = e.target.result;
        };
        reader.readAsDataURL(files[0]);
        this.$emit("input", files[0]);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
:deep .p-avatar img {
  width: 65px;
  height: 65px;
  border-radius: 50px;
}

.required-image-error {
  margin-top: 20px;
}
</style>