<template>
  <div class="p-grid centered-container h-100hv container">
    <div class="heading">
      <div class="p-col-12">
        <img :src="logo" />
      </div>
      <div class="p-col-12 welcome">
        <div class="p-pb-2">HOWZIT</div>
        <div>Welcome to Quick Score Pro</div>
      </div>

      <div class="p-col-12 p-pt-4">
        <Button label="LOGIN" class="p-button-rounded p-button-raised p-px-2 p-m-0" @click="Login" />
      </div>
      <div class="p-col-12">
        <Button
          type="button"
          class="p-button-rounded p-button-raised p-button-outlined"
          @click="Register"
          label="REGISTER"
        />
      </div>
      <div class="p-col-12">
        <Button label="FAQ" class="p-button-text p-px-2 p-m-0" @click="onFaq" />
      </div>
    </div>
    <div class="ssl-logo">
      <img src="https://www.positivessl.com/images/seals/positivessl_trust_seal_sm_124x32.png" alt />
    </div>
  </div>
</template>

<script>
import logo from "../../assets/logo.png";

export default {
  name: "Home",
  data() {
    return {
      logo: logo
    };
  },
  methods: {
    Register() {
      this.$router.push({ name: "register" });
    },
    Login() {
      this.$router.push({ name: "login" });
    },
    onFaq() {
      this.$router.push({ name: "faq" });
    }
  }
};
</script>
<style  lang="scss"  scoped>
.welcome {
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 14px;
  align-items: center;
  text-align: center;
  letter-spacing: 1.75px;
  color: #3e4547;
}

.ssl-logo {
  position: absolute;
  bottom: 0;
}
</style>
