<template>
  <div class="p-grid centered-container">
    <div class="heading container">
      <div class="p-col-12 p-pt-4">
        <img :src="logo" />
      </div>

      <div class="p-col-12">
        <p class="welcome-text">
          Quick Score Pro (QSP) is a platform to easily track and manage shooting matches, manage clubs, branches and registrations and to track personal scores.
          You can create a club and build you own matches from your private target bank with photos of the targets for participants and match officials to see.
        </p>
        <p class="welcome-text">
          <span v-if="!readMoreActivated">{{targets.slice(0, 205)}}</span>
          <span v-if="readMoreActivated" v-html="targets"></span>
        </p>
        <a
          class="read-more"
          v-if="!readMoreActivated"
          @click="activateReadMore"
          href="#"
        >Read more...</a>
        <p class="welcome-text">
          <span v-if="!readMoreActivated">{{matches.slice(0, 0)}}</span>
          <span v-if="readMoreActivated" v-html="matches"></span>
        </p>
        <p class="welcome-text">
          <span v-if="!readMoreActivated">{{login.slice(0, 0)}}</span>
          <span v-if="readMoreActivated" v-html="login"></span>
        </p>
      </div>
      <div class="p-col-12 p-pt-4 p-px-4">
        <Button
          label="GET STARTED"
          class="p-button-rounded p-button-raised p-px-4 p-m-0"
          @click="goHome"
        />
      </div>
      <div class="p-col-12">
        <Button label="FAQ" class="p-button-text p-px-2 p-m-0" @click="onFaq" />
      </div>
    </div>
  </div>
</template>

<script>
import logo from "../../assets/logo.png";

export default {
  created() {
    if (location.href.includes("redirect")) {
      sessionStorage.setItem(
        "redirect-url",
        location.href.split("redirect=")[1]
      );
    }
  },
  data() {
    return {
      logo: logo,
      targets: `Targets in your target bank is used for creating your course or fire or ranges for your match with individual range descriptions.
          You can set the number of shots per range and the maximum points for the range.
          There are no limits to the amount of ranges that can be added to a match.
          It is all customizable to suite your needs.`,
      matches: `Matches can be created as a point scoring match or a time based match.
          You can also create your own categories and divisions for your matches and set fees per category.
          A club admin can add more admins to manage the club and admins can also add match officials to a specific match to capture scores for a specific match.`,
      login: `Log in as a user and find matches near you or search by club or by discipline.
          Quickly register for a match and track your scores live on the day of the shoot.
          Instantly check out leader boards and positions.
          Keep track of all your club shoots or your own shoots in one place.`,
      readMoreActivated: false
    };
  },
  methods: {
    goHome() {
      this.$router.push({ name: "home" });
    },
    onFaq() {
      this.$router.push({ name: "faq" });
    },
    activateReadMore() {
      this.readMoreActivated = true;
    }
  }
};
</script>

<style lang="scss" scoped>
.welcome-text {
  line-height: 1.5;
}
.read-more {
  color: #8b0e0f;
}
</style>