<template>
   <div class="p-grid background">
      <div class="p-col-12">
         <div class="p-grid heading">
            <div class="p-col-2">
               <router-link to="/dashboard">
                  <i class="pi pi-arrow-left"></i>
               </router-link>
            </div>
            <div class="p-col-8">
               <HeaderLogo/>
               <div>{{selectedMatch.name}}</div>
            </div>
            <div class="p-col-2"></div>
         </div>
      </div>
      <div class="p-col-12 h-sub-title p-pt-0 p-pb-4">
         {{ selectedMatch.name }}
      </div>
      <div class="p-col-12 p-pb-4 search"></div>
      <div class="p-col-12">
         <ProgressBar
            v-if="scoreBoard === 'loading'"
            mode="indeterminate"
            class="progress"
            />
      </div>
      <div class="p-col-12 p-p-4 content-area">
         <table class="table" v-if="scoreBoard.length > 0">
            <thead>
               <tr>
                  <th scope="col">Ranges</th>
                  <th scope="col">Score</th>
               </tr>
            </thead>
            <tbody>
               <tr v-for="(item) in finalScoreBoard" :key="item.id" >
                  <td>{{ item.fullNames }}</td>
                  <td >{{ item.score }}</td>
               </tr>
            </tbody>
         </table>
      </div>
   </div>
</template>
<script>
import { mapState } from "vuex";
import HeaderLogo from "../../../shared/HeaderLogo.vue"
export default {
    name: "scoreboard",
    components: { HeaderLogo },
    created() {
        this.myScores();
    },
    data() {
        return {
            finalScoreBoard: [],
            positionT: "",
            counter: 0,
            scores: [],
            divisionScores: [],
        };
    },
    computed: {
        ...mapState("captureScoreModule", ["scoreBoard", "scoreViewType", "selectedMatch","dropDownValue"]),
        ...mapState("authenticate", ["user", "is_admin"]),
    },
    methods: {

        myScores() {
            this.scores = [];
            this.finalScoreBoard = [];
            this.counter = 0;

            this.scoreBoard.forEach((x) => {
                this.finalScoreBoard.push({
                    fullNames: x.fullNames,
                    score: x.score,
                    percentage: x.percentage,
                    position: this.positionT
                });
                this.scores.push(x.score);
            })
        },
    },
};
</script>

<style lang="scss" scoped>
.club-item {
  .p-avatar.p-avatar-xl {
    width: 100px;
    height: 40px;
    font-size: 2rem;
    object-fit: contain;
  }
}
.match-name{
    height: 16px;
    left: 8.89%;
    right: 60.28%;
    top: calc(50% - 16px/2 - 189px);
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: #3E4547;
}
.club-name{
    height: 14px;
    left: 8.89%;
    right: 77.78%;
    top: calc(50% - 14px/2 - 172px);
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    color: #3E4547;
}

table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

td, th {
  border-bottom: 1px solid #e6e6e6;
  text-align: center;
  padding: 8px;
}

.dropdown{
height: 30px;
width: 84px;
left: 16px;
top: 17.73%;
bottom: 76.82%;
float: left;
border:none;
}

.row-background-color{
  background-color: red;
}
</style>