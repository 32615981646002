<template>
   <div class="p-grid background">
      <div class="p-col-12">
         <div class="p-grid heading">
            <div class="p-col-2">
               <router-link to="/dashboard">
                  <i class="pi pi-arrow-left"></i>
               </router-link>
            </div>
            <div class="p-col-8">
               <HeaderLogo/>
               <h4>Find a match</h4>
            </div>
            <div class="p-col-2">
               <router-link to="/clubs/create" v-if="is_admin">
                  <i class="pi pi-plus"></i>
               </router-link>
            </div>
         </div>
      </div>
      <div class="p-col-12 p-pb-4 search">
         <span class="p-input-icon-left">
            <i class="pi pi-search" />
            <InputText type="text" v-model.trim="searchMatch" @keyup="searchMatchByName" placeholder="Search by match name " />
         </span>
      </div>
      <div class="p-col-12 p-p-4 content-area">
         <div class="p-col-12">
            <ProgressBar
               v-if="findMatchStatus === 'loading'"
               mode="indeterminate"
               class="progress"
               />
         </div>
         <div class="p-col-12" v-if="matchList.length === 0 && findMatchStatus !== 'loading'">{{constants.EMPTY_STATE_TEXT.EMPTY_MATCH_LIST}}</div>
         <div class="p-col-12 pointer" v-for="matchData in matchList" :key="matchData.matchID">
            <list-item :match="matchData" />
         </div>
         <div v-if="viewMatch.length > 9 && findMatchStatus !== 'loading'" @click="loadMore">
            <LoadMore/>
         </div>
      </div>
   </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import ListItem from "./ListItem.vue";
import LoadMore from "../../main/LoadMore"
import HeaderLogo from "../../shared/HeaderLogo.vue"

export default {
    name: "matches",
    components: {
        ListItem,
        LoadMore,
        HeaderLogo
    },
    created() {
        this.skip = 0;
        this.matchList = [];
        this.$store.dispatch("eventModule/viewMatchesPerClub", {
            userID: this.user.id,
            skip: this.skip,
            matchName: this.searchMatch
        }).then(() => {
            this.viewMatch.forEach((x) => {
                this.matchList.push({
                    matchID: x.matchID,
                    branchName: x.branchName,
                    clubName: x.clubName,
                    clubImage: x.clubImage,
                    name: x.name,
                    type: x.type,
                    startDate: x.startDate,
                    endDate: x.endDate,
                    registrationStartDate: x.registrationStartDate,
                    registrationEndDate: x.registrationEndDate,
                    isUserRegistered: x.isUserRegistered,
                    matchDirector: x.matchDirector,
                    spotsLeft: x.spotsLeft,
                });
            })
        });
    },
    data() {
        return {
            skip: 0,
            matchList: [],
            searchMatch: null
        };
    },
    computed: {
        ...mapGetters({
            viewMatch: "eventModule/getViewMatchesPerClub"
        }),
        ...mapState("clubModule", ["selectedClub"]),
        ...mapState("eventModule", [
            "getMatchesByClubStatus",
            "findMatchStatus",
        ]),
        ...mapState("authenticate", ["user", "is_admin"]),

    },
    methods: {
        loadMore() {
            if(this.searchMatch){
                this.skip = this.skip + 10;
                this.$store.dispatch("eventModule/viewMatchesPerClub", {
                    userID: this.user.id,
                    skip: this.skip,
                    matchName: this.searchMatch
                }).then(() => {
                    this.viewMatch.forEach((x) => {
                        this.matchList.push({
                            matchID: x.matchID,
                            branchName: x.branchName,
                            clubName: x.clubName,
                            clubImage: x.clubImage,
                            name: x.name,
                            type: x.type,
                            startDate: x.startDate,
                            endDate: x.endDate,
                            registrationStartDate: x.registrationStartDate,
                            registrationEndDate: x.registrationEndDate,
                            isUserRegistered: x.isUserRegistered,
                            matchDirector: x.matchDirector,
                            spotsLeft: x.spotsLeft,
                        });
                    })
                });
            } else {
                this.skip = this.skip + 10;
                this.$store.dispatch("eventModule/viewMatchesPerClub", {
                    userID: this.user.id,
                    skip: this.skip,
                    matchName: null
                }).then(() => {
                    this.viewMatch.forEach((x) => {
                        this.matchList.push({
                            matchID: x.matchID,
                            branchName: x.branchName,
                            clubName: x.clubName,
                            clubImage: x.clubImage,
                            name: x.name,
                            type: x.type,
                            startDate: x.startDate,
                            endDate: x.endDate,
                            registrationStartDate: x.registrationStartDate,
                            registrationEndDate: x.registrationEndDate,
                            isUserRegistered: x.isUserRegistered,
                            matchDirector: x.matchDirector,
                            spotsLeft: x.spotsLeft,
                        });
                    })
                });
            }
        },
        searchMatchByName() {
            if(this.searchMatch){
                this.skip = 0;
                this.$store.dispatch("eventModule/viewMatchesPerClub", {
                    userID: this.user.id,
                    skip: this.skip,
                    matchName: this.searchMatch
                }).then(() => {
                    this.matchList = [];
                    this.viewMatch.forEach((x) => {
                        this.matchList.push({
                            matchID: x.matchID,
                            branchName: x.branchName,
                            clubName: x.clubName,
                            clubImage: x.clubImage,
                            name: x.name,
                            type: x.type,
                            startDate: x.startDate,
                            endDate: x.endDate,
                            registrationStartDate: x.registrationStartDate,
                            registrationEndDate: x.registrationEndDate,
                            isUserRegistered: x.isUserRegistered,
                            matchDirector: x.matchDirector,
                            spotsLeft: x.spotsLeft,
                        });
                    })
                });
            } else {
                this.skip = 0;
                this.$store.dispatch("eventModule/viewMatchesPerClub", {
                    userID: this.user.id,
                    skip: this.skip,
                    matchName: null
                }).then(() => {
                    this.matchList = [];
                    this.viewMatch.forEach((x) => {
                        this.matchList.push({
                            matchID: x.matchID,
                            branchName: x.branchName,
                            clubName: x.clubName,
                            clubImage: x.clubImage,
                            name: x.name,
                            type: x.type,
                            startDate: x.startDate,
                            endDate: x.endDate,
                            registrationStartDate: x.registrationStartDate,
                            registrationEndDate: x.registrationEndDate,
                            isUserRegistered: x.isUserRegistered,
                            matchDirector: x.matchDirector,
                            spotsLeft: x.spotsLeft,
                        });
                    })
                });
            }
        }
    },
};
</script>

<style lang="scss" scoped>
:deep .search {
  .p-input-filled .p-inputtext {
    border-bottom-left-radius: 30px;
    border-bottom-right-radius: 30px;
  }
  .p-inputtext {
    border-radius: 30px;
  }
}
</style>