<template>
   <div class="p-grid range">
      <div class="p-col-10 p-as-center range-text-align">
         <div class="">{{ item.name }}</div>
         <div class="">{{ item.targetName }}</div>
      </div>
      <div class="p-col-2 p-as-center">
         <div class="vertical-container" @click="editRange(item.rangeID)" v-if="item.updated == true">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
               <path fill-rule="evenodd" clip-rule="evenodd" d="M17.6589 3C17.4089 3 17.1489 3.1 16.9589 3.29L15.1289 5.12L18.8789 8.87L20.7089 7.04C21.0989 6.65 21.0989 6.02 20.7089 5.63L18.3689 3.29C18.1689 3.09 17.9189 3 17.6589 3ZM14.06 9.02L14.98 9.94L5.92 19H5V18.08L14.06 9.02ZM3 17.25L14.06 6.19L17.81 9.94L6.75 21H3V17.25Z" fill="black" fill-opacity="0.6"/>
            </svg>
         </div>
      </div>
   </div>
</template>

<script>
export default {
  name: "range",
  props: {
    item: {},
  },
  methods:{
    editRange(rangeId){
      this.$store.commit("eventModule/updateSelectedRange", rangeId);
      this.$store.dispatch("eventModule/rangeDetails", rangeId).then(() => {
        this.$router.push({ name: "UpdateRangeItem" });
      });
    }
  },
};
</script>

<style lang="scss" scoped>
.range {
  background: #fbfbfb;
  height: 82.77px;
  border: 1px solid #cbcfd0;
  border-radius: 4px;
}

.range-text-align{
  text-align:left;
}
</style>