<template>
  <div>
    <confirmation
      v-if="isSubmitted"
      :title="'Match has been successfully updated. '"
      :status="status"
      :fallback="'/club'"
    />
    <div
      class="p-grid p-ai-start vertical-container container"
      v-if="!isSubmitted"
    >
      <div class="p-col-12 p-text-left" v-if="page !== 5">
        <div @click="onBack"><i class="pi pi-arrow-left"></i></div>
      </div>
      <div class="p-col-6 p-pl-4" v-if="page !== 5">
        <radial-progress-bar
          :diameter="100"
          :completed-steps="page"
          :total-steps="4"
          startColor="#093248"
          stopColor="#093248"
          innerStrokeColor="lightgrey"
        >
          <div class="next">{{ page }}of 4</div>
        </radial-progress-bar>
      </div>
      <div class="p-col-16 p-pt-5" v-if="page !== 5">
        <div class="box">
          <div class="title-event">{{ headers[page].title }}</div>
          <div class="next">{{ headers[page].next }}</div>
        </div>
      </div>
      <div class="p-col-12">
        <update-match
          v-on:update-match="matchAdded"
          v-show="page === constants.PAGE_NUMBERS.MATCH_DETAILS"
        />
        <update-range
          v-on:update-range-list="rangeAdded"
          v-show="page === constants.PAGE_NUMBERS.RANGE_DETAILS"
        />
        <update-official
          v-on:update-officials="officialAdded"
          v-show="page === constants.PAGE_NUMBERS.OFFICIAL_DETAILS"
        />
        <update-advertisment
          v-on:updated-ad-list="advertAdded"
          v-show="page === constants.PAGE_NUMBERS.SPONSOR_DETAILS"
        />
      </div>
    </div>
  </div>
</template>

<script>
import UpdateRange from "./range/UpdateRange.vue";
import UpdateMatch from "./match/UpdateMatch.vue";
import UpdateOfficial from "./official/UpdateOfficial.vue";
import UpdateAdvertisment from "./ads/UpdateAdvertisment.vue";
import RadialProgressBar from "vue-radial-progress";
import { mapState, mapGetters } from "vuex";
import Confirmation from "../manage-clubs/Confirmation";

export default {
  components: {
    UpdateRange,
    UpdateMatch,
    UpdateOfficial,
    UpdateAdvertisment,
    RadialProgressBar,
    Confirmation
  },
  name: "Update Event",
  created() {
    this.$store.commit("eventModule/resetStatus");
  },
  data() {
    return {
      page: 1,
      isSubmitted: false,

      event: {},
      headers: {
        1: {
          title: "Create match",
          next: "Next: Add range"
        },
        2: {
          title: "Update range",
          next: "Next: Add official"
        },
        3: {
          title: "Add official",
          next: "Next: Advertisment"
        },
        4: {
          title: "Advertisment",
          next: "Next: Confirmation"
        },
        5: {
          title: "",
          next: ""
        }
      }
    };
  },
  computed: {
    ...mapState("eventModule", ["status", "matchID"]),
    ...mapGetters({
      userID: "authenticate/getUserId"
    })
  },
  methods: {
    matchAdded(matchModel) {
      this.event = matchModel;
      this.page = 2;
    },
    rangeAdded(ranges) {
      this.event.ranges = ranges;
      this.page = 3;
    },
    officialAdded(officials) {
      this.event.matchOfficials = officials;
      this.page = 4;
    },
    async advertAdded() {
      this.isSubmitted = true;
      this.event.UserID = this.userID;
      await this.$store.dispatch("eventModule/update", this.event);
    },
    onBack() {
      if (this.page > 1) this.page--;
      else {
        this.$router.push({
          name: "club"
        });
        this.event = {};
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.title-event {
  font-size: 23px;
  font-style: normal;
  font-weight: 700;

  letter-spacing: 1.25px;
  text-align: right;
}

.next {
  font-size: 12px;
  font-style: normal;
  font-weight: 400;

  letter-spacing: 1.25px;
  text-align: right;
}
.pi.pi-arrow-left {
  color: rgba(0, 0, 0, 0.6);
}
</style>