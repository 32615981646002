import { Service } from "./service";

class BranchService extends Service {
  constructor() {
    super();
  }

  async add(payload, cb, errorCb) {
    await this.https
      .post(`branchManagement/AddBranch`, payload)
      .then((res) => {
        cb(res.data);
      })
      .catch((error) => {
        errorCb(error);
      });
  }

  async update(payload, cb, errorCb) {
    await this.https
      .put(`branchManagement/UpdateBranch`, payload)
      .then((res) => {
        cb(res.data);
      })
      .catch((error) => {
        errorCb(error);
      });
  }

  async getBranchesByClub(clubId, cb, errorCb) {
    await this.https
      .get(`branchManagement/GetBranchesLinkedToClub/${clubId}`)
      .then((res) => {
        cb(res.data);
      })
      .catch((error) => {
        errorCb(error);
      });
  }
}

export default new BranchService();
