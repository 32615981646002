<template>
  <div class="p-grid background">
    <div class="p-col-12">
      <div class="p-grid heading">
        <div class="p-col-2">
          <router-link to="/dashboard">
            <i class="pi pi-arrow-left"></i>
          </router-link>
        </div>
        <div class="p-col-8">
          <HeaderLogo/>
          <h4>{{ constants.COMMON_TEXT.VIEW_SCORES }}</h4>
        </div>
        <div class="p-col-2"></div>
      </div>
    </div>
    <div class="p-col-12 p-pb-4 search"></div>
    <div class="p-col-12 p-p-4 content-area">
      <label v-if="matchListItems.length === 0">No scores found for this match. Please check again later.</label>
      <div  v-if="matchListItems.length > 0">
      <div
        class="p-col-12"
        v-for="c in matchListItems"
        :key="c.ClubID"
      >
        <match-list-item :match="c" />
      </div>
    </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import MatchListItem from "./MatchListItem.vue"
import HeaderLogo from "../../../shared/HeaderLogo.vue"
export default {
  name: "view",
  components: { MatchListItem, HeaderLogo },
  created() {
    if(this.scoreViewType == "Official"){
      this.$store.dispatch(
      "captureScoreModule/getMatchList", {
        UserId: this.user.id
      });
    }else if(this.scoreViewType == "Participant"){
      this.$store.dispatch(
      "captureScoreModule/getMyMatchList", {
        UserId: this.user.id,
      });
    }
  },
  data() {
    return { search: "" };
  },
  computed: {
    ...mapState("captureScoreModule", ["matchListItems", "scoreViewType"]),
    ...mapState("authenticate", ["user", "is_admin"]),
  },
  methods: {
  },
};
</script>

<style lang="scss" scoped>
.title-mini {
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  color: #0a0a0a;
}

.sub-value-mini {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  color: #979797;
}
</style>