import CategoryService from "../../services/category.service";

const state = () => ({
  selectedClubCategories: [],
  res: "",
  selectedCategory: {},
  createCategoryStatus: "",
  updateCategoryStatus: "",
  deleteCategoryStatus:"",
  deleteCategoryCode: "",
  goBack: false,
});

// getters
const getters = {
  //
};

// actions
const actions = {
  async create({ commit }, category) {
    commit("create_category_request");
    await CategoryService.add(
      category,
      (res) => {
        commit("create_category_success", res.response);
      },
      (error) => {
        commit("create_category_error");
        console.error("error", error);
      }
    );
  },

  async update({ commit }, category) {
    commit("update_category_request");
    await CategoryService.update(
      category,
      (res) => {
        commit("update_category_success", res.response);
      },
      (error) => {
        commit("update_category_error");
        console.error("error", error);
      }
    );
  },
  categoriesForClub({ commit }, clubId) {
    CategoryService.getCategoriesByClub(
      clubId,
      (res) => {
        commit("get_categories_success", res.response);
      },
      (error) => {
        console.error("error", error);
      }
    );
  },
  async deleteCategory({ commit }, categoryId) {
    commit("delete_category_request");
    await CategoryService.removeCategory(
      categoryId,
      (res) => {
        commit("delete_category_success", res.response);
      },
      (error) => {
        commit("delete_category_error", error.response.data.statusCode);
        console.error("error", error.response);
      }
    );
  },
};

// mutations
const mutations = {
  resetStatus(state) {
    state.createCategoryStatus = "";
    state.updateCategoryStatus = "";
    state.goBack = false;
  },
  //--create
  create_category_request(state) {
    state.createCategoryStatus = "loading";
  },
  create_category_success(state, res) {
    state.createCategoryStatus = "success";
    state.res = res;
  },
  create_category_error(state) {
    state.createCategoryStatus = "error";
  },
  //--update
  update_category_request(state) {
    state.updateCategoryStatus = "loading";
  },
  update_category_success(state, res) {
    state.updateCategoryStatus = "success";
    state.res = res;
  },
  update_category_error(state) {
    state.updateCategoryStatus = "error";
  },
  //
  updateSelectedCategory(state, category) {
    state.selectedCategory = category;
  },
  get_categories_success(state, categories) {
    state.selectedClubCategories = categories;
  },
  delete_category_request(state){
    state.deleteCategoryStatus = "loading";
  },
  delete_category_success(state, category){
    state.deleteCategoryStatus = "success";
    state.deleteCategoryCode = category;
  },

  delete_category_error(state, res){
    state.deleteCategoryStatus = "error";
    state.deleteCategoryCode = res;
  },

  create_another_category(state){
    state.goBack = true;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
