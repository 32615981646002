<template>
  <div class="p-grid p-ai-start vertical-container p-m-0">
    <Toast position="top-center toast-width" group="br" class="toast" />
    <div class="p-col-12">
      <ProgressBar
        v-if="linkSponsorStatus === 'loading'"
        mode="indeterminate"
        class="progress"
      />
    </div>
    <div class="p-col-12 p-mb-2">
      <div class="p-grid">
        <div class="p-col-6">
          <div class="ad-container">
            <label for="upload-photo">
              <div class="centered">
                <img
                  class="upload-file-image-width"
                  src="../../../images/addSponsor.png"
                  alt=""
                />
              </div>
            </label>
          </div>
          <input
            ref="image"
            type="file"
            accept="image/*"
            @change="uploadImage($event)"
            id="upload-photo"
          />
        </div>
        <div class="error-message " v-if="isSizeExceeded">
          {{ constants.IMAGE_VALIDATION.EXCEEDED_IMAGE_SIZE_ERROR }}
        </div>
        <div
          class="p-col-6"
          v-for="advert in sponsorDetails"
          :key="advert.sponsorID"
        >
          <div class="ad-container">
            <div class="top-left" @click="deleteSponsor(advert.sponsorID)">
              <svg
                width="18"
                height="18"
                viewBox="0 0 18 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M14.25 4.8075L13.1925 3.75L9 7.9425L4.8075 3.75L3.75 4.8075L7.9425 9L3.75 13.1925L4.8075 14.25L9 10.0575L13.1925 14.25L14.25 13.1925L10.0575 9L14.25 4.8075Z"
                  fill="black"
                  fill-opacity="0.6"
                />
              </svg>
            </div>
            <div class="centered">
              <img class="sponsor-image-width" :src="advert.logoUrl" alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="p-col-12">
      <Message
        severity="error"
        v-if="linkSponsorStatus === 'error'"
        :life="3000"
        :sticky="false"
        >An error occured. Please try again</Message
      >
    </div>
    <div class="p-field p-col-12">
      <Button
        :label="buttonTItle"
        class="p-button-raised p-button-rounded p-px-2 p-m-0"
        @click="onSaveRange"
      />
    </div>
  </div>
</template>
<script>
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import { mapState } from "vuex";
let picture = null;
const SAVE = "SAVE";
const SUBMIT = "SUBMIT";

export default {
  name: "Advertisment",
  setup() {
    return {
      v$: useVuelidate()
    };
  },
  data() {
    return {
      isAddNew: false,
      list: [],
      ad: {
        name: "",
        icon: null,
        sponsorLogo: null
      },
      isSizeExceeded: false
    };
  },
  validations: {
    ad: {
      icon: {
        required,
        $lazy: true
      },
      name: {
        required,
        $lazy: true
      }
    }
  },
  computed: {
    ...mapState("eventModule", [
      "status",
      "matchID",
      "sponsorDetails",
      "linkSponsorStatus"
    ]),
    buttonTItle() {
      return this.isAddNew ? SAVE : SUBMIT;
    }
  },
  methods: {
    onAdd() {
      this.isAddNew = !this.isAddNew;
      this.v$.$reset();
    },

    uploadImage(event) {
      this.isSizeExceeded = false;
      picture = event.target.files[0];
      const input = this.$refs.image;
      const files = input.files;
      this.fileSize = files[0].size;
      if (this.fileSize >= this.constants.IMAGE_VALIDATION.IMAGE_MAX_SIZE) {
        this.isSizeExceeded = true;
        picture = null;
      }
      if (
        files &&
        files[0] &&
        this.fileSize < this.constants.IMAGE_VALIDATION.IMAGE_MAX_SIZE
      ) {
        const reader = new FileReader();
        reader.onload = e => {
          this.ad.icon = e.target.result;
        };
        reader.readAsDataURL(files[0]);
        this.$emit("input", files[0]);
      }

      if (picture != null) {
        let form = new FormData();
        form.append("matchID", this.matchID);
        form.append("name", "Test");
        form.append("sponsorLogo", picture);
        this.$store.dispatch("eventModule/linkSponsor", form).then(() => {
          this.$store.dispatch("eventModule/getSponsors", this.matchID);
        });
      }
    },

    deleteSponsor(id) {
      this.$store.dispatch("eventModule/deleteSponsors", id).then(() => {
        this.$store
          .dispatch("eventModule/getSponsors", this.matchID)
          .then(() => {
            this.$toast.removeAllGroups();
            this.$toast.add({
              severity: "success",
              summary: "Sponsor removed successfully",
              group: "br",
              life: 1000
            });
          });
      });
    },

    async onSaveRange() {
      this.$emit("new-ad-list");
    }
  }
};
</script>
<style lang="scss" scoped>
.ad-container {
  width: 160px;
  height: 160px;
  position: relative;
  text-align: center;
  background-color: white;
  filter: drop-shadow(0px 4px 10px rgba(230, 230, 230, 0.5));
  border-radius: 18px;
}

.toast-width {
  width: 320px;
}

/* Centered text */
.centered {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.top-left {
  position: absolute;
  top: 8px;
  left: 16px;
}

.icon {
  z-index: 2;
  top: 50%;
  left: 45%;
  position: relative;
}

.index {
  z-index: 3;
}

.upload-file-image-width {
  width: 145px;
}

.sponsor-image-width {
  width: 110px;
}
</style>