<template>
  <div class="p-grid item p-mt-3">
    <div class="p-col-8 p-as-center p-text-left">
      <div class="p-col-12" v-if="scoreBoardStatus === 'loading'">
        <ProgressBar mode="indeterminate" class="progress" />
      </div>
      <div class="club-name">{{ selectedClub.clubName }}</div>
      <div class="match-name" v-if="match.branchName">Branch name: {{ match.branchName }}</div>
      <div class="match-name">Match name: {{ match.name }}</div>
      <div class="date-c">Match date: {{ new Date(match.startDate).toDateString() }}</div>
    </div>

    <div class="p-col-4 p-as-center p-text-right action">
      <div
        class="vertical-container"
        v-if="!is_admin && dateFilter === constants.DATE_FILTER.UPCOMING"
      >
        <div
          class="pointer"
          @click="onRegister"
          v-if="
            !match.isUserRegistered &&
              today >= new Date(match.registrationStartDate) &&
              today <= new Date(match.registrationEndDate)
          "
        >Register</div>
        <div v-if="match.isUserRegistered">Registered</div>
      </div>
    </div>

    <div class="p-col-12 seperator" v-if="is_admin">
      <div class="p-grid action">
        <div
          class="p-col-4 pointer"
          v-if="dateFilter === constants.DATE_FILTER.UPCOMING"
          @click="onShare"
        >SHARE</div>
        <div
          class="p-col-3 pointer"
          v-if="dateFilter === constants.DATE_FILTER.UPCOMING"
          @click="onEdit"
        >EDIT</div>

        <div
          class="p-col-6 pointer"
          v-if="dateFilter === constants.DATE_FILTER.ONGOING"
          @click="onView"
        >VIEW MATCH</div>

        <div
          :class="{
            'p-col-5 pointer': dateFilter === constants.DATE_FILTER.UPCOMING,
            'p-col-6 pointer': dateFilter === constants.DATE_FILTER.ONGOING,
            'p-col-12 pointer': dateFilter === constants.DATE_FILTER.COMPLETED
          }"
          @click="onViewScores"
        >VIEW SCORES</div>
      </div>
    </div>
    <div class="p-col-12 seperator" v-if="!is_admin">
      <div class="p-grid action">
        <div class="p-col-6 pointer" @click="onView">VIEW MATCH</div>

        <div
          :class="{
            'p-col-5 pointer': dateFilter === constants.DATE_FILTER.UPCOMING,
            'p-col-4 pointer': dateFilter === constants.DATE_FILTER.ONGOING,
            'p-col-4 pointer': dateFilter === constants.DATE_FILTER.COMPLETED
          }"
          @click="onViewScores"
        >VIEW SCORES</div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "matchItem",
  props: { match: Object, dateFilter: String },
  data() {
    return {
      today: new Date()
    };
  },
  computed: {
    ...mapState("authenticate", ["user", "is_admin"]),
    ...mapState("eventModule", ["matchRegistrationStatus"]),
    ...mapState("clubModule", ["selectedClub"]),
    ...mapState("captureScoreModule", ["scoreBoardStatus"])
  },
  methods: {
    onRegister() {
      this.$store.commit("eventModule/updateSelectedMatch", this.match);
      this.$router.push({ name: "match" });
    },
    onView() {
      this.$store.commit("eventModule/updateSelectedMatch", this.match);
      this.$router.push({ name: "view-match" });
    },
    onShare() {
      navigator.share({
        title: this.match.name,
        url: `${this.$FRONT_URL}/match${this.match.matchUrl}`
      });
    },
    onEdit() {
      this.$store.commit("eventModule/updateMatchID", this.match.matchID);
      this.$store.commit("eventModule/updateSelectedMatch", this.match);
      this.$store.commit(
        "eventModule/get_sponsors_for_match_success",
        this.match.sponsors
      );
      this.$router.push({ name: "update-event" });
    },
    onViewScores() {
      this.$store.commit("captureScoreModule/updateSelectedMatch", this.match);
      this.$store
        .dispatch("captureScoreModule/getOveralScoreBoard", this.match.matchID)
        .then(() => {
          this.$router.push({
            name: "scoreboard",
            params: { scoreBoardType: "leaderboard" }
          });
        });
    }
  }
};
</script>

<style lang="scss" scoped>
.seperator {
  border-top: 1px solid #cbcfd0;
}

.date-c {
  font-weight: 300;
  font-size: 12px;
  line-height: 24px;

  align-items: center;
  letter-spacing: 0.15px;

  color: #3e4547;
}

.register {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  text-align: right;

  color: #0a0a0a;
}

.club-name {
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  align-items: center;
  letter-spacing: 0.15px;
  color: #3e4547;
}

.match-name {
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 24px;

  align-items: center;
  letter-spacing: 0.15px;

  color: #3e4547;
}
</style>