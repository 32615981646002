<template>
   <div>
      <div class="p-grid item p-pb-2" @click="onExpand">
         <div class="p-col-3 p-as-center">
            <Avatar :image="match.image" size="xlarge" shape="circle" />
         </div>
         <div class="p-col-7 p-as-center p-text-left">
            <div class="vertical-container match-heading pointer">
               <div class="title-mini">{{ match.clubName }}</div>
               <div class="match-name">{{ match.name }}</div>
               <div class="date-c" v-if="!isExpanded">
                  Start date: {{ abbreviatedDatefromDateString(match.startDate) }}
               </div>
            </div>
         </div>
         <div class="p-col-2 p-as-center p-text-left">
            <div class="p-col-2 p-as-center">
               <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M10.5929 0L6.00293 4.32659L1.41293 0L0.00292969 1.33198L6.00293 7L12.0029 1.33198L10.5929 0Z" fill="black" fill-opacity="0.54"/>
               </svg>
            </div>
         </div>
      </div>
      <div class="p-grid" v-if="isExpanded">
         <div class="p-col-12 p-p-0">
            <div class="p-d-flex rows p-pl-2" v-if="match.branchName != null">
               <div class="p-col-5 p-text-left sub-t">Branch club name:</div>
               <div class="p-col-7 p-text-right sub-value-mini">
                  {{ match.branchName }}
               </div>
            </div>
            <div class="p-d-flex rows p-pl-2">
               <div class="p-col-5 p-text-left sub-t">Match name:</div>
               <div class="p-col-7 p-text-right sub-value-mini">
                  {{ match.name }}
               </div>
            </div>
            <div class="p-d-flex rows p-pl-2">
               <div class="p-col-6 p-text-left sub-t">Match type:</div>
               <div class="p-col-6 p-text-right sub-value-mini">
                  {{ match.type }}
               </div>
            </div>
            <div class="p-d-flex rows p-pl-2">
               <div class="p-col-5 p-text-left sub-t">Start date:</div>
               <div class="p-col-7 p-text-right sub-value-mini">
                  {{ new Date(match.startDate).toDateString() }}
               </div>
            </div>
            <div class="p-d-flex rows p-pl-2">
               <div class="p-col-5 p-text-left sub-t">End date:</div>
               <div class="p-col-7 p-text-right sub-value-mini">
                  {{ new Date(match.endDate).toDateString() }}
               </div>
            </div>
            <div class="p-d-flex rows p-pl-2">
               <div class="p-col-5 p-text-left sub-t">Start time:</div>
               <div class="p-col-7 p-text-right sub-value-mini">
                  {{ new Date(match.startDate).toLocaleTimeString() }}
               </div>
            </div>
            <div class="p-d-flex rows p-pl-2">
               <div class="p-col-5 p-text-left sub-t">End time:</div>
               <div class="p-col-7 p-text-right sub-value-mini">
                  {{ new Date(match.endDate).toLocaleTimeString() }}
               </div>
            </div>
            <div class="p-d-flex rows p-pl-2">
               <div class="p-col-6 p-text-left sub-t">Participants:</div>
               <div class="p-col-6 p-text-right sub-value-mini">
                  {{ match.participants }}
               </div>
            </div>
            <div class="p-d-flex rows p-pl-2">
               <div class="p-col-6 p-text-left sub-t">Ranges:</div>
               <div class="p-col-6 p-text-right sub-value-mini">
                  {{ match.ranges }}
               </div>
            </div>
            <div class="p-d-flex rows p-pl-2">
               <div class="p-col-3 p-text-left sub-t">Address:</div>
               <div class="p-col-9 p-text-right sub-value-mini">
                  <i> {{ match.location }} </i>
               </div>
            </div>
            <div class="p-d-flex rows p-pl-2">
               <div class="p-col-5 p-text-left sub-t">Map directions:</div>
               <div class="p-col-7 p-text-right sub-value-mini p-text-truncate">
                  <a :href="match.mapDirections" target="_blank">{{
                  match.mapDirections
                  }}</a>
               </div>
            </div>
            <div class="p-d-flex rows p-pl-2 action">
               <div class="p-col-5 p-text-right pointer" @click="onExpand">CLOSE</div>
               <div class="p-col-7 p-text-right pointer" @click="onCaptureScore" v-if="new Date(match.endDate) >= new Date()">
                  CAPTURE SCORES
               </div>
            </div>
         </div>
      </div>
   </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  props: {
    match: {},
  },
  data() {
    return { isExpanded: false };
  },
  computed: {
    ...mapState("authenticate", ["user", "is_admin"]),
  },
  methods: {
    onExpand() {
      this.isExpanded = !this.isExpanded;
    },
    onCaptureScore() {
      this.$store.dispatch(
        "captureScoreModule/rangesForMarch",
        this.match.matchId
      );
      this.$store.commit("captureScoreModule/updateSelectedMatch", this.match);
      this.$router.push({ name: "capture-score" });
    },
  },
};
</script>

<style lang="scss" scoped>
.title-mini {
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  color: #0a0a0a;
}

.sub-t {
  font-weight: bold;
  font-size: 12px;
  text-align: right;
  letter-spacing: 0.25px;
  color: #000000;
}

.sub-value-mini {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 20px;
  color: #979797;
  letter-spacing: 0.25px;
}

.rows {
  background: #ffffff;
  border-width: 0px 1px 1px 1px;
  border-color: #e1e1e1;
  border-style: solid;
  box-sizing: border-box;
}

.date-c {
  font-weight: 300;
  font-size: 13px;
  line-height: 24px;

  align-items: center;
  letter-spacing: 0.15px;

  color: #979797;
}
.match-heading {
   margin-left: 30px;
}
.p-avatar.p-avatar-xl {
   width: 100px;
   height: 40px;
   font-size: 2rem;
   object-fit: contain;
img {
   object-fit: cover;
}
}

.match-name {
  font-style: normal;
  font-size: 12px;
  color: #0a0a0a;
}
</style>