<template>
  <div class="p-grid p-ai-start vertical-container p-m-0">
    <div class="p-col-12">
      <div class="box">
        <div class="p-fluid p-grid p-formgrid p-text-left">
          <div class="p-field p-col-12 p-pt-2 h-text">Match details</div>
          <div class="p-field p-col-12">
            <span class="p-float-label p-input-icon-left">
              <svg
                class="svg-class"
                viewBox="0 0 25 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M12.6892 2C7.16922 2 2.69922 6.48 2.69922 12C2.69922 17.52 7.16922 22 12.6892 22C18.2192 22 22.6992 17.52 22.6992 12C22.6992 6.48 18.2192 2 12.6892 2ZM20.1692 9.16L15.1592 8.73L13.1592 4.02C16.3692 4.21 19.0692 6.29 20.1692 9.16ZM12.6992 13.98L15.0992 15.42L14.4592 12.69L16.5692 10.86L13.7892 10.62L12.6992 8.06L11.6092 10.63L8.82922 10.87L10.9392 12.7L10.3092 15.42L12.6992 13.98ZM12.2392 4.02L10.2392 8.74L5.21922 9.17C6.31922 6.29 9.01922 4.2 12.2392 4.02ZM4.92922 10.14C4.77922 10.74 4.69922 11.36 4.69922 12C4.69922 14.48 5.82922 16.7 7.60922 18.17L8.71922 13.42L4.92922 10.14ZM8.53922 18.82L12.6992 16.31L16.8592 18.81C15.6392 19.56 14.2192 20 12.6892 20C11.1692 20 9.74922 19.56 8.53922 18.82ZM16.6792 13.42L17.7892 18.17C19.5592 16.7 20.6992 14.48 20.6992 12C20.6992 11.36 20.6092 10.73 20.4692 10.14L16.6792 13.42Z"
                  fill="black"
                  fill-opacity="0.54"
                />
              </svg>
              <InputText
                id="name"
                type="text"
                class="p-invalid"
                placeholder="Match name"
                v-model="matchModel.name"
                :class="{ 'p-invalid': v$.matchModel.name.required.$invalid }"
              />
              <label for="inputtext-left">Match Name</label>
            </span>
            <p
              class="error-message"
              v-if="v$.matchModel.name.required.$invalid"
            >* Match name is required</p>
          </div>
          <div class="p-field p-col-12 p-pt-2">
            <span class="p-float-label p-input-icon-left">
              <svg
                class="svg-class"
                viewBox="0 0 25 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M4 2H20C21.1 2 22 2.9 22 4V16C22 17.1 21.1 18 20 18H6L2 22V4C2 2.9 2.9 2 4 2ZM5.17 16H20V4H4V17.17L5.17 16ZM11 5H13V11H11V5ZM13 13H11V15H13V13Z"
                  fill="black"
                  fill-opacity="0.54"
                />
              </svg>
              <InputText type="text" placeholder="Match type" v-model="matchModel.type" />
              <label for="inputtext-left">Match type</label>
            </span>
            <p
              class="error-message"
              v-if="v$.matchModel.type.required.$invalid"
            >* Match type is required</p>
          </div>
          <div class="p-field p-col-12 p-pt-2" v-if="selectedClubBranches.length > 0">
            <div class="p-field p-col-12 p-pt-2 h-text">Club Branch</div>
            <Dropdown
              id="branchID"
              v-model="matchModel.branchID"
              :options="selectedClubBranches"
              placeholder="Club branches"
              optionValue="branchID"
              optionLabel="branchName"
            />
          </div>
          <div class="p-px-2 p-pt-4 h-text">Match dates</div>
          <div class="p-grid p-py-4 p-px-2">
            <div class="p-col-6">
              <span>
                <Calendar
                  id="start"
                  v-model="matchModel.startDate"
                  dateFormat="dd/mm/yy"
                  placeholder="Start date"
                  :showTime="true"
                  :showIcon="true"
                  :yearNavigator="true"
                  :monthNavigator="true"
                  :manualInput="false"
                  yearRange="2020:2080"
                  panelClass="calendarMatchStart"
                >
                  <template #footer>
                    <div class="close-calendar">
                      <button
                        class="p-button p-component"
                        @click="closeCalendar('calendarMatchStart')"
                      >SET</button>
                    </div>
                  </template>
                </Calendar>
              </span>
            </div>
            <div class="p-col-6">
              <span>
                <Calendar
                  id="end"
                  v-model="matchModel.endDate"
                  dateFormat="dd/mm/yy"
                  placeholder="End date"
                  :showTime="true"
                  :showIcon="true"
                  :yearNavigator="true"
                  :monthNavigator="true"
                  :manualInput="false"
                  yearRange="2020:2080"
                  panelClass="calendarMatchEnd"
                >
                  <template #footer>
                    <div class="close-calendar">
                      <button
                        class="p-button p-component"
                        @click="closeCalendar('calendarMatchEnd')"
                      >SET</button>
                    </div>
                  </template>
                </Calendar>
              </span>
            </div>
          </div>
          <div class="p-px-2 p-pt-4 h-text">Match registration</div>
          <div class="p-grid p-py-4 p-px-2">
            <div class="p-col-6">
              <span>
                <Calendar
                  v-model="matchModel.registrationStartDate"
                  dateFormat="dd/mm/yy"
                  placeholder="Start date"
                  :showTime="true"
                  :showIcon="true"
                  :yearNavigator="true"
                  :monthNavigator="true"
                  :manualInput="false"
                  yearRange="2020:2080"
                  panelClass="calendarRegStart"
                >
                  <template #footer>
                    <div class="close-calendar">
                      <button
                        class="p-button p-component"
                        @click="closeCalendar('calendarRegStart')"
                      >SET</button>
                    </div>
                  </template>
                </Calendar>
              </span>
            </div>
            <div class="p-col-6">
              <span>
                <Calendar
                  v-model="matchModel.registrationEndDate"
                  dateFormat="dd/mm/yy"
                  placeholder="End date"
                  :showTime="true"
                  :showIcon="true"
                  :yearNavigator="true"
                  :monthNavigator="true"
                  :manualInput="false"
                  yearRange="2020:2080"
                  panelClass="calendarRegEnd"
                >
                  <template #footer>
                    <div class="close-calendar">
                      <button
                        class="p-button p-component"
                        @click="closeCalendar('calendarRegEnd')"
                      >SET</button>
                    </div>
                  </template>
                </Calendar>
              </span>
            </div>
          </div>
          <div class="p-col-12 reg-note p-pb-4">
            Participants will only be able to register for the match between the
            start and end date
          </div>
          <div class="p-field p-col-12 p-pt-2">
            <span class="p-float-label p-input-icon-left">
              <svg
                class="svg-class"
                viewBox="0 0 25 25"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M9.69922 12.9111C11.6292 12.9111 13.1992 11.3411 13.1992 9.41113C13.1992 7.48113 11.6292 5.91113 9.69922 5.91113C7.76922 5.91113 6.19922 7.48113 6.19922 9.41113C6.19922 11.3411 7.76922 12.9111 9.69922 12.9111ZM9.69922 7.91113C10.5292 7.91113 11.1992 8.58113 11.1992 9.41113C11.1992 10.2411 10.5292 10.9111 9.69922 10.9111C8.86922 10.9111 8.19922 10.2411 8.19922 9.41113C8.19922 8.58113 8.86922 7.91113 9.69922 7.91113ZM5.46922 17.9111H9.74922C9.71922 18.0711 9.69922 18.2411 9.69922 18.4111V19.9111H2.69922V18.4111C2.69922 16.0811 7.35922 14.9111 9.69922 14.9111C10.2592 14.9111 10.9492 14.9811 11.6792 15.1111C10.9692 15.5911 10.3792 16.1911 10.0392 16.9211C9.98424 16.9211 9.92676 16.9186 9.86929 16.9161L9.86922 16.9161L9.86915 16.9161C9.81167 16.9136 9.7542 16.9111 9.69922 16.9111C8.16922 16.9111 6.45922 17.4111 5.46922 17.9111ZM17.1992 15.4111C15.3592 15.4111 11.6992 16.4211 11.6992 18.4111V19.9111H22.6992V18.4111C22.6992 16.4211 19.0392 15.4111 17.1992 15.4111ZM19.6992 11.4111C19.6992 12.3511 19.1692 13.1611 18.4092 13.5911C18.0492 13.7911 17.6392 13.9111 17.1992 13.9111C16.7592 13.9111 16.3492 13.7911 15.9892 13.5911C15.2292 13.1611 14.6992 12.3511 14.6992 11.4111C14.6992 10.0311 15.8192 8.91113 17.1992 8.91113C18.5792 8.91113 19.6992 10.0311 19.6992 11.4111Z"
                  fill="black"
                  fill-opacity="0.54"
                />
              </svg>
              <InputNumber
                class="input-number"
                id="participants"
                placeholder="Number of participants"
                v-model="matchModel.numberOfParticipants"
                :class="{
                  'p-invalid':
                    v$.matchModel.numberOfParticipants.required.$invalid
                }"
              />
              <label for="inputtext-left">Number of participants</label>
            </span>
            <p
              class="error-message"
              v-if="v$.matchModel.numberOfParticipants.required.$invalid"
            >* Number of participants {{ errorMessage }}</p>
          </div>
          <div class="p-field p-col-8 p-pt-4 h-text">Match category</div>
          <div class="p-field p-col-2 p-pt-4 p-text-right icon-red">
            <div v-if="categoryList.length > 0" @click="onRemoveCategory">
              <i class="pi pi-minus"></i>
            </div>
          </div>
          <div class="p-field p-col-2 p-pt-4 p-text-right icon-red" @click="onAddCategory">
            <i class="pi pi-plus"></i>
          </div>
          <div class="p-col-12 p-p-0" v-for="index in categoryList.length" :key="index">
            <div class="p-field p-col-12" v-if="categoryList.length > 0">
              <span class="p-float-label">
                <Dropdown
                  id="categoryID"
                  v-model="categoryList[index - 1].categoryID"
                  :options="selectedClubCategories"
                  placeholder
                  optionValue="categoryID"
                  optionLabel="name"
                />
                <label for="inputtext-left">Match category</label>
              </span>
              <p
                class="error-message"
                v-if="
                  categoryList[index - 1].categoryID == '' &&
                    !isSelectedCategory
                "
              >* Please select a category</p>
            </div>
            <div class="p-field p-col-12" v-if="categoryList[index - 1].categoryID !== ''">
              <span class="p-float-label">
                <InputNumber
                  placeholder
                  mode="currency"
                  currency="ZAR"
                  v-model="categoryList[index - 1].amount"
                />
                <label for="inputtext-left">Match fee</label>
              </span>
              <p
                class="error-message"
                v-if="categoryList[index - 1].amount == undefined"
              >* Please provide category fee</p>
            </div>
          </div>
          <div class="p-field p-col-12 p-pt-3">
            <span class="p-float-label">
              <MultiSelect
                v-model="matchModel.matchDivision"
                :options="selectedClubDivisions"
                placeholder
                optionLabel="name"
                display="chip"
              />
              <label for="inputtext-left">Match division</label>
            </span>
          </div>
          <div class="p-field p-col-12 p-pt-3">
            <span class="p-float-label p-input-icon-left">
              <i class="pi pi-book" />
              <Textarea
                placeholder="Match details"
                v-model="matchModel.details"
                :autoResize="true"
                rows="5"
                cols="30"
              />
              <label for="inputtext-left">Match details</label>
            </span>
            <div class="reg-note p-pl-1">Additional match comments</div>
          </div>
          <div class="p-field p-col-8 p-pt-4 h-text">Address</div>
          <div class="p-field p-col-12 p-pt-3">
            <span class="p-input-icon-left">
              <i class="pi pi-map-marker" />
              <Textarea
                id="location"
                type="text"
                placeholder="Address"
                v-model="matchModel.location"
                :autoResize="true"
              />
            </span>
          </div>
          <div class="p-field p-col-12 p-pt-3">
            <span class="p-input-icon-left">
              <i class="pi pi-directions" />
              <InputText
                id="MapDirections"
                type="text"
                placeholder="Map Directions"
                v-model="matchModel.mapDirections"
              />
            </span>
          </div>
          <div class="p-col-12">
            <p
              class="error-message"
              v-if="v$.matchModel.name.required.$invalid"
            >* Match name is required</p>
            <p
              class="error-message"
              v-if="v$.matchModel.type.required.$invalid"
            >* Match type is required</p>
            <p
              class="error-message"
              v-if="v$.matchModel.numberOfParticipants.required.$invalid"
            >* Number of participants required</p>
          </div>
          <div class="p-col p-pt-4">
            <Button
              label="NEXT"
              class="p-button-raised p-button-rounded p-pl-2 p-pr-2 p-m-0"
              @click="onNext"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import { ZERO_ID } from "../../../helpers/constants.js";
export default {
  name: "UpdateMatch",
  setup() {
    return {
      v$: useVuelidate()
    };
  },
  created() {
    this.scrollToScreenTop();
    this.$store.dispatch(
      "branchModule/branchesForClub",
      this.selectedClub.clubID
    );
    this.$store.dispatch(
      "categoryModule/categoriesForClub",
      this.selectedClub.clubID
    );
    this.$store.dispatch(
      "divisionModule/divisionsForClub",
      this.selectedClub.clubID
    );
    this.matchModel = this.selectedMatch;
    this.matchModel.startDate = new Date(this.matchModel.startDate);
    this.matchModel.endDate = new Date(this.matchModel.endDate);
    this.matchModel.registrationStartDate = new Date(
      this.matchModel.registrationStartDate
    );
    this.matchModel.registrationEndDate = new Date(
      this.matchModel.registrationEndDate
    );
    for (var i in this.matchModel.matchCategories) {
      this.categoryList.push({
        matchCategoriesID: this.matchModel.matchCategories[i].matchCategoriesID,
        categoryID: this.matchModel.matchCategories[i].categoryID,
        categoryName: this.matchModel.matchCategories[i].name,
        amount: this.matchModel.matchCategories[i].amount
      });
    }
    this.isSelectedCategory = true;
  },
  data() {
    return {
      categoryList: [],
      matchModel: {
        availability: "",
        details: "",
        endDate: new Date(),
        isUserRegistered: "",
        location: "",
        mapDirections: "",
        matchCategories: [],
        matchDivision: [],
        matchID: "",
        matchOfficial: "",
        matchOfficials: [],
        matchUrl: [],
        name: "",
        numberOfParticipants: "",
        ranges: [],
        registrationEndDate: new Date(),
        registrationStartDate: new Date(),
        sponsors: [],
        startDate: new Date(),
        type: "",
        branchID: ZERO_ID.ID
      },
      isSelectedCategory: false
    };
  },
  validations: {
    matchModel: {
      name: {
        required,
        $lazy: true
      },
      type: {
        required,
        $lazy: true
      },
      startDate: {
        required,
        $lazy: true
      },
      endDate: {
        required,
        $lazy: true
      },
      registrationStartDate: {
        required,
        $lazy: true
      },
      registrationEndDate: {
        required,
        $lazy: true
      },
      numberOfParticipants: {
        required,
        $lazy: true
      },

      location: {
        required,
        $lazy: true
      }
    }
  },
  computed: {
    ...mapState("branchModule", ["selectedClubBranches"]),
    ...mapState("eventModule", ["status", "matchID", "selectedMatch"]),
    ...mapState("categoryModule", ["selectedClubCategories"]),
    ...mapState("divisionModule", ["selectedClubDivisions"]),
    ...mapState("clubModule", ["selectedClub"]),
    ...mapGetters({
      userID: "authenticate/getUserId"
    })
  },
  methods: {
    async onNext() {
      this.categorySelected();
      this.v$.$touch();
      this.matchModel.matchUrl = [];
      this.matchModel.matchCategories = [];
      if (
        !this.v$.$invalid &&
        this.categorySelected() &&
        this.matchFeeProvided()
      ) {
        for (var i in this.categoryList) {
          this.matchModel.matchCategories.push({
            matchCategoriesID: this.categoryList[i].matchCategoriesID,
            categoryID: this.categoryList[i].categoryID,
            categoryName: this.categoryList[i].categoryName,
            amount: this.categoryList[i].amount
          });
        }
        this.$emit("update-match", this.matchModel);
      }
    },
    onAddCategory() {
      this.categoryList.push({
        categoryID: "",
        amount: null
      });
    },
    onRemoveCategory() {
      if (this.categoryList.length > 0) this.categoryList.pop();
    },
    closeCalendar(calendarName) {
      const elements = document.getElementsByClassName(
        "p-calendar p-component p-inputwrapper  p-calendar-w-btn p-inputwrapper-filled"
      );
      let foundElement = undefined;
      let childElements = undefined;
      let index = -1;
      elements.forEach(element => {
        childElements = element.children;
        if (childElements.length > 0) {
          childElements.forEach(child => {
            index = child.className.indexOf(calendarName);
            if (index > 0) {
              childElements.forEach(c => {
                if (c.type == "button") {
                  foundElement = c;
                }
              });
            }
          });
        }
      });
      if (foundElement) {
        foundElement.click();
      }
    },
    categorySelected() {
      if (this.categoryList[0].categoryID == "") {
        this.isSelectedCategory = false;
        return false;
      }
      return true;
    },
    matchFeeProvided() {
      var isFee = true;
      if (this.categoryList[0].categoryID != "") {
        this.categoryList.forEach(element => {
          if (element.amount == undefined) {
            isFee = false;
            this.isSelectedCategory = false;
          }
        });
        return isFee;
      }
      return isFee;
    }
  }
};
</script>

<style lang="scss" scoped>
.reg-note {
  color: #cbcfd0;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 1.25px;
  text-align: left;
}
:deep .p-multiselect.p-multiselect-chip .p-multiselect-token {
  background: transparent;
}

.svg-class {
  top: 42%;
  position: absolute;
  width: 24px;
  height: 24px;
  left: 12px;
}

.input-number {
  padding-left: 25px;
}

.close-calendar {
  float: right;
}
:deep {
  .p-multiselect.p-multiselect-chip .p-multiselect-token {
    background: transparent;
  }
  .p-datepicker .p-datepicker-header .p-datepicker-title .p-datepicker-month,
  .p-datepicker .p-datepicker-header .p-datepicker-title select {
    margin-right: 0.5rem;
    padding: 10px;
    border: 0;
    box-shadow: 0 5px 5px -3px rgb(0 0 0 / 20%), 0 8px 10px 1px rgb(0 0 0 / 14%),
      0 3px 14px 2px rgb(0 0 0 / 12%);
  }
  .p-fluid .p-calendar {
    position: relative;
  }
  .p-fluid .p-calendar .p-inputtext {
    font-size: 12px;
    padding: 5px;
  }
  .p-datepicker {
    width: auto;
    left: -160px;
  }
  .p-datepicker:not(.p-datepicker-inline) .p-datepicker-header {
    display: -webkit-box;
  }
  .p-link .pi,
  .pi {
    color: rgba(0, 0, 0, 0.6);
  }
}
</style>