import { Service } from "./service";

class ClubService extends Service {
  constructor() {
    super();
  }

  async addClub(payload, cb, errorCb) {
    await this.https
      .post("ClubManagement/AddClub", payload, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then((res) => {
        cb(res.data);
      })
      .catch((error) => {
        errorCb(error);
      });
  }

  async updateClub(payload, cb, errorCb) {
    await this.https
      .post("ClubManagement/UpdateClub", payload, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then((res) => {
        cb(res.data);
      })
      .catch((error) => {
        errorCb(error);
      });
  }

  async clubsByUser(payload, cb, errorCb) {
    await this.https
      .get(`clubManagement/GetClubsLinkedToMember/${payload.userId}/${payload.clubName}`)
      .then((res) => {
        cb(res.data);
      })
      .catch((error) => {
        errorCb(error);
      });
  }

  async clubMatchPreviews(cb, errorCb) {
    await this.https
      .get(`clubManagement/MatchPreviews`)
      .then((res) => {
        cb(res.data);
      })
      .catch((error) => {
        errorCb(error);
      });
  }

  async getApprovedClubs(cb, errorCb) {
    await this.https
      .get("ClubManagement/GetApprovedClubs")
      .then((res) => {
        cb(res.data);
      })
      .catch((error) => {
        errorCb(error);
      });
  }

  async getClubImages(payload, cb, errorCb) {
    await this.https
      .get(`ClubManagement/GetClubList/${payload.skip}/${payload.clubName}`)
      .then((res) => {
        cb(res.data);
      })
      .catch((error) => {
        errorCb(error);
      });
  }


  async viewMatches(payload) {
    return this.https.get(`Match/ViewMatches/${payload.skip}/${payload.clubName}`);
  }

  async getClubAdmins(clubId, cb, errorCb) {
    await this.https
    .get(`clubManagement/GetClubAdmins/${clubId}`)
      .then((res) => {
        cb(res.data);
      })
      .catch((error) => {
        errorCb(error);
      });
  }

  async addClubAdmin(payload, cb, errorCb) {
    await this.https
      .post(`clubManagement/LinkAdminToClub`, payload)
      .then((res) => {
        cb(res.data);
      })
      .catch((error) => {
        errorCb(error);
      });
  }

  async removeClubAdmin(payload, cb, errorCb) {
    await this.https
      .post(`clubManagement/RemoveClubAdmin`, payload)
      .then((res) => {
        cb(res.data);
      })
      .catch((error) => {
        errorCb(error);
      });
  }
}

export default new ClubService();
