<template>
  <div>
    <confirmation
      v-if="isSubmitted"
      :title="'Target has successfully been updated'"
      :status="updateTargetStatus"
      :fallback="'/targets'"
    />
    <unsuccessful
      v-if="isDeleted"
      :title="deleteTargetText"
      :status="deleteTargetStatus"
      :fallback="'/targets'"
    />
    <div class="p-grid background" v-if="!isSubmitted && !isDeleted">
      <div class="p-col-12">
        <div class="p-grid heading">
          <div class="p-col-2 pointer" @click="onBack">
            <i class="pi pi-arrow-left"></i>
          </div>
          <div class="p-col-8">Update Target</div>
          <div class="p-col-2" />
        </div>
      </div>
      <div class="p-col-12 h-50-custom">
        <div class="open-file">
          <input
            ref="image"
            type="file"
            accept="image/*"
            @change="uploadImage($event)"
            class="pi pi-arrow-left"
            id="upload-photo"
          />
        </div>
        <div class="picture-holder">
          <label for="upload-photo">
          <img
            v-bind:src="targetModel.image"
            class="p-mr-2 bg-grey-custom image-holder-size pointer"
            size="xlarge"
            shape="circle"
          />
          </label>
        </div>
      </div>
      <div class="p-col-12 p-pt-4 content-area">
        <div class="p-fluid p-formgrid p-grid p-pt-4">
          <div class="p-field p-col-12">
            <div
              class="error-message"
              v-if="targetModel.picture == '' && targetModel.ImageUrl == ''"
            >
              {{ constants.IMAGE_VALIDATION.IMAGE_REQUIRED }}
            </div>
            <div class="error-message" v-if="isSizeExceeded">
              {{ constants.IMAGE_VALIDATION.EXCEEDED_IMAGE_SIZE_ERROR }}
            </div>
            <span class="p-input-icon-left">
              <i class="pi pi-globe" />
              <InputText
                type="text"
                placeholder="Target name"
                v-model="targetModel.name"
                :class="{
                  'p-invalid': v$.targetModel.name.required.$invalid
                }"
              />
            </span>
            <p
              class="error-message"
              v-if="v$.targetModel.name.required.$invalid"
            >
              * Target name is required
            </p>
          </div>
          <div class="p-field p-col-12 p-pt-4">
            <Button
              class="p-button-rounded p-button-raised"
              type="button"
              @click="updateTarget()"
              label="Update "
            >
              <ProgressSpinner
                v-if="updateTargetStatus === 'loading'"
                class="progress-spinner-custom"
                strokeWidth="5"
                animationDuration=".5s"
              />
            </Button>
          </div>
          <div class="p-field p-col-12 p-pt-4">
            <Button
              label="REMOVE"
              class="p-button-text p-px-2 p-m-0"
              @click="removeTarget"
            >
              <ProgressSpinner
                v-if="deleteTargetStatus === 'loading'"
                class="progress-spinner-custom"
                strokeWidth="5"
                animationDuration=".5s"
              />
            </Button>
          </div>
          <div class="p-field p-col-12 p-pt-4">
            <img
              class="target-image"
              v-bind:src="'' + targetModel.image"
              alt=""
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import { mapState } from "vuex";
import Confirmation from "../Confirmation.vue";
import Unsuccessful from "../Unsuccessful.vue";

export default {
  components: { Confirmation, Unsuccessful },
  name: "updateTarget",
  setup() {
    return { v$: useVuelidate() };
  },
  created() {
    this.$store.commit("targetModule/resetStatus");
    this.targetModel = this.selectedTarget;
  },
  data() {
    return {
      isSubmitted: false,
      isDeleted: false,
      targetModel: {
        name: "",
        image: null,
        picture: ""
      },
      isSizeExceeded: false
    };
  },
  validations: {
    targetModel: {
      name: {
        required,
        $lazy: true
      },
      image: {
        required,
        $lazy: true
      }
    }
  },
  computed: {
    ...mapState("targetModule", [
      "updateTargetStatus",
      "selectedTarget",
      "deleteTargetStatus",
      "deleteTargetText"
    ]),
    ...mapState("clubModule", ["selectedClub"]),
    ...mapState("utilModule", ["status", "provinces"]),
    ...mapState("authenticate", ["user"])
  },

  methods: {
    onBack() {
      this.$store.commit("targetModule/updateSelectedTarget", {});
      this.$router.push({ name: "targets" });
    },
    async updateTarget() {
      this.v$.$touch();
      if (!this.v$.$invalid) {
        let form = new FormData();
        form.append("targetID", this.selectedTarget.targetID);
        form.append("clubID", this.selectedClub.clubID);
        form.append("name", this.targetModel.name);
        form.append("image", this.targetModel.picture);
        this.isSubmitted = true;
        await this.$store.dispatch("targetModule/update", form);
      }
    },

    uploadImage(event) {
      this.isSizeExceeded = false;
      this.targetModel.picture = event.target.files[0];
      const input = this.$refs.image;
      const files = input.files;
      this.fileSize = files[0].size;
      if (this.fileSize >= this.constants.IMAGE_VALIDATION.IMAGE_MAX_SIZE) {
        this.isSizeExceeded = true;
        this.targetModel.picture = "";
      }
      if (
        files &&
        files[0] &&
        this.fileSize < this.constants.IMAGE_VALIDATION.IMAGE_MAX_SIZE
      ) {
        const reader = new FileReader();
        reader.onload = e => {
          this.targetModel.image = e.target.result;
        };
        reader.readAsDataURL(files[0]);
        this.$emit("input", files[0]);
      }
    },

    async removeTarget() {
      this.v$.$touch();
      await this.$store
        .dispatch("targetModule/deleteTarget", this.selectedTarget.targetID)
        .then(() => {
          if (
            this.deleteTargetText ==
            "This target has ranges linked to it and cannot be removed."
          ) {
            this.isDeleted = true;
          } else {
            this.$router.push({ name: "targets" });
          }
        });
    }
  }
};
</script>

<style lang="scss" scoped>
.target-image {
  width: 100%;
}
:deep .p-avatar img {
  border-radius: 50px;
}
</style>