<template>
  <div>
    <confirmation
      v-if="resetPasswordStatus === 'success'"
      :title="'You have successfully updated your Password '"
      :status="resetPasswordStatus"
      :mutation="'authenticate/resetStatus'"
      :fallback="'/login'"
    />
    <div
      class="p-grid centered-container h-70 container"
      v-if="resetPasswordStatus !== 'success'"
    >
      <div class="p-col-12">
        <div class="p-grid heading">
          <div class="p-col-1">
            <router-link to="/update-profile">
              <i class="pi pi-arrow-left"></i>
            </router-link>
          </div>
          <div class="p-col-11 p-pt-6">
            <HeaderLogo />
          </div>
        </div>
      </div>
      <div class="p-col-12">
        <Message
          severity="error"
          v-if="resetPasswordStatus === 'error'"
          :life="3000"
          :sticky="false"
          >{{
            requestResponse.response !== undefined
              ? `${requestResponse.response}`
              : "Couldn't reset password. Please try again"
          }}</Message
        >
      </div>

      <div class="p-col-12">
        <div class="box box-margin">
          <div class="p-fluid p-grid p-formgrid p-text-left">
            <div class="p-col-12">
              <ProgressBar
                v-if="resetPasswordStatus === 'loading'"
                mode="indeterminate"
                class="progress"
              />
            </div>
            <div class="p-field p-col-12">
              <span class="p-input-icon-left">
                <i class="pi pi-lock lock-icon" />
                <Password
                  v-model="newPassword.currentPassword"
                  toggleMask
                  placeholder="Current Password"
                  strongRegex="^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})"
                  :class="{
                    'p-invalid':
                      v$.newPassword.currentPassword.required.$invalid ||
                      v$.newPassword.currentPassword.minLength.$invalid
                  }"
                />
              </span>
            </div>
            <div class="p-field p-col-12">
              <span class="p-input-icon-left">
                <i class="pi pi-lock lock-icon" />
                <Password
                  v-model="newPassword.password"
                  toggleMask
                  placeholder="New Password"
                  strongRegex="^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})"
                  :class="{
                    'p-invalid':
                      v$.newPassword.password.required.$invalid ||
                      v$.newPassword.password.minLength.$invalid
                  }"
                >
                  <template #footer>
                    <Divider />
                    <ul class="p-pl-2 p-ml-2 p-mt-0 list-items">
                      <li>{{ lowerCase }}</li>
                      <li>{{ upperCase }}</li>
                      <li>{{ numeric }}</li>
                      <li>{{ characters }}</li>
                    </ul>
                  </template>
                </Password>
              </span>
            </div>

            <div class="p-field p-col-12 button-top-margin">
              <span class="p-input-icon-left">
                <i class="pi pi-lock lock-icon" />
                <span>
                  <Password
                    v-model="newPassword.confirmPassword"
                    toggleMask
                    placeholder="Confirm Password"
                    strongRegex="^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})"
                    @change="isMatch"
                    :class="{
                      'p-invalid':
                        v$.newPassword.confirmPassword.required.$invalid
                    }"
                  >
                    <template #footer>
                      <Divider />
                      <ul class="p-pl-2 p-ml-2 p-mt-0 list-items">
                        <li>{{ lowerCase }}</li>
                        <li>{{ upperCase }}</li>
                        <li>{{ numeric }}</li>
                        <li>{{ characters }}</li>
                        <li>Password must match</li>
                      </ul>
                    </template>
                  </Password>
                </span>
              </span>
              <p
                class="error-message"
                v-if="
                  newPassword.password !== newPassword.confirmPassword &&
                    newPassword.confirmPassword.length !== 0
                "
              >
                Confirm password must match password
              </p>
            </div>
            <div class="p-col-12">
              <Button
                type="button"
                class="p-button-rounded p-button-raised"
                :class="[
                  isDisabled ? disabled - create - button : create - button
                ]"
                v-bind:disabled="!isDisabled"
                @click="onUpdatePassword"
                label="CHANGE PASSWORD"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import useVuelidate from "@vuelidate/core";
import { required, minLength } from "@vuelidate/validators";
import { mapState } from "vuex";
import successImage from "../../assets/successImage.svg";
import Confirmation from "../settings/Confirmation";
import HeaderLogo from "../shared/HeaderLogo.vue";

export default {
  components: {
    Confirmation,
    HeaderLogo
  },
  name: "UpdatePassword",
  setup() {
    return { v$: useVuelidate() };
  },
  created() {
    this.$store.commit("authenticate/resetStatus");
  },
  data() {
    return {
      passwordsMatch: null,
      newPassword: {
        currentPassword: "",
        password: "",
        confirmPassword: ""
      },
      successImage: successImage,
      lowerCase: "At least one lowercase",
      upperCase: "At least one uppercase",
      numeric: "At least one numeric",
      characters: "Minimum 8 characters"
    };
  },
  validations: {
    newPassword: {
      currentPassword: {
        required,
        minLength: minLength(8),
        $lazy: true
      },
      password: {
        required,
        minLength: minLength(8),
        $lazy: true
      },
      confirmPassword: {
        required,
        $lazy: true
      }
    }
  },
  computed: {
    ...mapState("authenticate", [
      "resetPasswordStatus",
      "resetPasswordResponse",
      "user",
      "requestResponse"
    ]),
    isDisabled() {
      var disabled =
        this.newPassword.confirmPassword.length > 0 &&
        this.newPassword.password.length > 0 &&
        this.newPassword.currentPassword.length > 0 &&
        !this.isMatch();
      return disabled;
    }
  },
  methods: {
    isMatch() {
      var isMatch =
        this.newPassword.password != this.newPassword.confirmPassword;
      return isMatch;
    },
    async onUpdatePassword() {
      this.v$.$touch();
      if (this.newPassword.password === this.newPassword.confirmPassword)
        this.passwordsMatch = true;
      if (!this.v$.$invalid && this.passwordsMatch) {
        await this.$store.dispatch("authenticate/updatePassword", {
          id: this.user.id,
          currentPassword: this.newPassword.currentPassword,
          password: this.newPassword.password
        });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.heading {
  font-size: 20px;
  font-weight: bold;
  align-items: center;
  text-align: center;
  letter-spacing: 1.25px;
  color: #3e4547;
  .pi {
    color: #000000;
  }
}

.h-70 {
  height: 70vh;
}

.progress {
  height: 0.09em;
  width: 98%;
  margin-left: 2px;
}

.box-margin {
  margin-bottom: 350px;
}

.disabled-create-button {
  background: #d8d8d8;
  height: 46.99px;
  color: #bdbdbd;
}

.create-button {
  height: 46.99px;
}

.lock-icon {
  z-index: 1;
}

::v-deep .p-inputtext {
  padding-left: 45px;
}

.success-text {
  font-family: Roboto;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
  letter-spacing: 1.25px;
  color: #093248;
}

.button-top-margin {
  margin-bottom: 80px;
}

.list-items {
  line-height: 24px;
}

.container {
  overflow: hidden;
}
</style>
