<template>
  <div>
    <div
      class="p-grid centered-container h-100hv"
      v-if="getMatchStatus === 'loading' || getMatchStatus === 'error'"
    >
      <div class="p-col-12 p-text-center" v-if="getMatchStatus === 'loading'">
        Loading match...
        <ProgressBar mode="indeterminate" />
      </div>

      <div class="p-col-12 p-text-center" v-if="getMatchStatus === 'error'">
        <div class="p-col-12">Match not found.</div>
        <div class="p-col-12">
          <Button
            class="p-button-rounded p-button-raised"
            type="button"
            label="Continue"
            @click="onContinue"
          />
        </div>
      </div>
    </div>

    <div class="p-pl-2" v-if="getMatchStatus === 'success'">
      <confirmation
        v-if="isSubmitted"
        :title="'Your request to join match was successful '"
        :status="joinMatchStatus"
        :fallback="'/dashboard'"
      />

      <div class="p-grid container" v-if="!isSubmitted">
        <div class="p-col-12">
          <div class="p-grid heading">
            <div class="p-col-2">
              <div @click="goBack">
                <i class="pi pi-arrow-left"></i>
              </div>
            </div>
            <div class="p-col-8">{{ selectedMatch.name }}</div>
            <div class="p-col-2"></div>
          </div>
        </div>

        <div class="p-col-12 p-p-3">
          <div class="p-fluid p-grid p-formgrid p-text-left">
            <div class="p-field p-col-12 p-pt-2 h-text">My details</div>
            <div class="p-field p-col-12">
              <span class="p-float-label p-input-icon-left">
                <i class="pi pi-user" />
                <InputText
                  id="name"
                  type="text"
                  readonly="readonly"
                  :modelValue="user.name"
                />
                <label for="inputtext-left">Name</label>
              </span>
            </div>

            <div class="p-field p-col-12 p-pt-2">
              <span class="p-float-label p-input-icon-left">
                <i class="pi pi-envelope" />
                <InputText
                  type="text"
                  readonly="readonly"
                  :modelValue="user.email"
                />
                <label for="inputtext-left">Email Address</label>
              </span>
            </div>

            <div class="p-field p-col-12 p-pt-2">
              <span class="p-float-label p-input-icon-left">
                <i class="pi pi-id-card" />
                <InputText
                  type="text"
                  readonly="readonly"
                  :modelValue="user.idNumber"
                />
                <label for="inputtext-left">ID Number</label>
              </span>
            </div>
            <div class="p-col-12 reg-note p-pb-4">
              Please make sure that your personal details are correct before
              proceeding (If you need to update your details, proceed to the
              profile section)
            </div>

            <div class="p-field p-col-12 p-pt-2 h-text">Match details</div>
            <div class="p-field p-col-12">
              <span class="p-float-label p-input-icon-left">
                <i class="pi pi-globe" />
                <InputText
                  id="name"
                  type="text"
                  readonly="readonly"
                  :modelValue="selectedMatch.name"
                />
                <label for="inputtext-left">Match Name</label>
              </span>
            </div>

            <div class="p-field p-col-12 p-pt-2">
              <span class="p-float-label p-input-icon-left">
                <i class="pi pi-info-circle" />
                <InputText
                  type="text"
                  readonly="readonly"
                  :modelValue="selectedMatch.type"
                />
                <label for="inputtext-left">Match type</label>
              </span>
            </div>

            <div class="p-field p-col-6 p-pt-3">
              <span class="p-input-icon-left">
                <label>Start date</label>
                <i class="pi pi-calendar" />
                <Calendar
                  id="start"
                  readonly="readonly"
                  :modelValue="new Date(selectedMatch.startDate).toDateString()"
                />
              </span>
            </div>
            <div class="p-field p-col-6 p-pt-3">
              <span class="p-input-icon-left">
                <label>End date</label>
                <i class="pi pi-calendar" />
                <Calendar
                  id="end"
                  readonly="readonly"
                  :modelValue="new Date(selectedMatch.endDate).toDateString()"
                />
              </span>
            </div>
            <div class="p-field p-col-6 p-pt-3">
              <span class="p-input-icon-left">
                <i class="pi pi-clock" />
                <Calendar
                  id="start"
                  readonly="readonly"
                  :modelValue="
                    new Date(selectedMatch.startDate).toLocaleTimeString()
                  "
                />
              </span>
            </div>
            <div class="p-field p-col-6 p-pt-3">
              <span class="p-input-icon-left">
                <i class="pi pi-clock" />
                <Calendar
                  id="end"
                  readonly="readonly"
                  :modelValue="
                    new Date(selectedMatch.endDate).toLocaleTimeString()
                  "
                />
              </span>
            </div>
            <div class="p-field p-col-12 p-pt-2 h-text">Match registration</div>

            <div class="p-field p-col-6 p-pt-3">
              <span class="p-input-icon-left">
                <label>Start date</label>
                <i class="pi pi-calendar" />
                <Calendar
                  id="start"
                  readonly="readonly"
                  :modelValue="
                    new Date(selectedMatch.registrationStartDate).toDateString()
                  "
                  :showTime="true"
                />
              </span>
            </div>
            <div class="p-field p-col-6 p-pt-3">
              <span class="p-input-icon-left">
                <label>End date</label>
                <i class="pi pi-calendar" />
                <Calendar
                  id="end"
                  readonly="readonly"
                  :modelValue="
                    new Date(selectedMatch.registrationEndDate).toDateString()
                  "
                  :showTime="true"
                />
              </span>
            </div>

            <div class="p-field p-col-6 p-pt-3">
              <span class="p-input-icon-left">
                <i class="pi pi-clock" />
                <Calendar
                  id="start"
                  readonly="readonly"
                  :modelValue="
                    new Date(
                      selectedMatch.registrationStartDate
                    ).toLocaleTimeString()
                  "
                  :showTime="true"
                />
              </span>
            </div>
            <div class="p-field p-col-6 p-pt-3">
              <span class="p-input-icon-left">
                <i class="pi pi-clock" />
                <Calendar
                  id="end"
                  readonly="readonly"
                  :modelValue="
                    new Date(
                      selectedMatch.registrationEndDate
                    ).toLocaleTimeString()
                  "
                  :showTime="true"
                />
              </span>
            </div>
            <div class="p-field p-col-12 p-pt-2">
              <span class="p-float-label p-input-icon-left">
                <i class="pi pi-info-circle" />
                <InputText
                  type="text"
                  readonly="readonly"
                  :modelValue="`Only ${selectedMatch.availability} spots left`"
                />
                <label for="inputtext-left">Remaining spots</label>
              </span>
            </div>

            <div
              class="p-field p-col-12 p-pt-2"
              v-clipboard:copy="`${url}${selectedMatch.matchUrl}`"
              v-clipboard:success="onCopy"
              v-clipboard:error="onError"
            >
              <span class="p-float-label p-input-icon-left">
                <i class="pi pi-copy" />
                <InputText
                  type="text"
                  readonly="readonly"
                  :modelValue="`${url}${selectedMatch.matchUrl}`"
                />
                <label for="inputtext-left">Match Url</label>
              </span>

              <Toast position="center" />
            </div>

            <div class="p-field p-col-12 p-pt-2">
              <span class="p-float-label p-input-icon-left">
                <i class="pi pi-book" />
                <Textarea
                  readonly="readonly"
                  placeholder="Match details"
                  :modelValue="selectedMatch.details"
                  :autoResize="true"
                  rows="5"
                  cols="30"
                />
                <label for="inputtext-left">Match details</label>
              </span>
            </div>

            <div class="p-col-12 reg-note p-pb-4">
              Additional match comments
            </div>

            <div
              class="p-field p-col-12 p-pt-2 h-text"
              v-if="selectedMatch.sponsors.length > 0"
            >
              Sponsors
            </div>

            <div
              class="p-field p-col-12 p-pt-2"
              v-if="selectedMatch.sponsors.length < 5"
            >
              <img
                class="sponsor-image"
                v-for="advert in selectedMatch.sponsors"
                :key="advert.sponsorID"
                :src="advert.sponsorImage"
                alt=""
              />
            </div>

            <div
              class="p-field p-col-12 p-pt-2 custom-carousel"
              v-if="selectedMatch.sponsors.length > 4"
            >
              <Carousel
                :value="selectedMatch.sponsors"
                :numVisible="getVisibleItems"
                :numScroll="1"
                :responsiveOptions="responsiveOptions"
                class="custom-carousel"
                :circular="true"
                :autoplayInterval="3000"
              >
                <template #item="slotProps">
                  <div class="product-item">
                    <Avatar
                      :image="slotProps.data.sponsorImage"
                      class="p-mr-2"
                      size="xlarge"
                      shape="circle"
                    />
                  </div>
                </template>
              </Carousel>
            </div>

            <div class="p-field p-col-12 p-pt-2 h-text">Address</div>
            <div class="p-field p-col-12">
              <span class="p-input-icon-left">
                <i class="pi pi-directions" />
                <Textarea
                  id="name"
                  type="text"
                  readonly="readonly"
                  :modelValue="selectedMatch.location"
                  :autoResize="true"
                />
              </span>
            </div>

            <div class="p-field p-col-12 p-pt-3">
              <div class="p-grid">
                <div class="p-col-2 p-pl-4"><i class="pi pi-map-marker" /></div>
                <div class="p-col-8 p-pl-0 map-link">
                  <a :href="selectedMatch.mapDirections" target="_blank">{{
                    selectedMatch.mapDirections
                  }}</a>
                </div>
              </div>
            </div>

            <div class="p-col-12">
              <Button
                type="button"
                class="p-button-text p-px-2 p-m-0"
                @click="onCancel"
                label="Back"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";

import { mapState } from "vuex";
import Confirmation from "../manage-clubs/Confirmation.vue";

export default {
  components: { Confirmation },
  name: "match",
  setup() {
    return { v$: useVuelidate() };
  },
  created() {
    this.$store.commit("eventModule/resetStatus");
    this.$store.dispatch(
      "eventModule/match",
      this.$route.params.match_url === undefined ||
        this.$route.params.match_url === ""
        ? this.selectedMatch.matchID
        : this.$route.params.match_url
    );
  },

  data() {
    return {
      isSubmitted: false,
      dateFormat: "mm-dd-yy",
      url: this.$FRONT_URL + "/match",
      register: {
        category: "",
        division: "",
        matchID: ""
      },
      responsiveOptions: [
        {
          breakpoint: "1024px",
          numVisible: 4,
          numScroll: 2
        },
        {
          breakpoint: "600px",
          numVisible: 4,
          numScroll: 2
        },
        {
          breakpoint: "480px",
          numVisible: 4,
          numScroll: 2
        }
      ]
    };
  },
  validations: {
    register: {
      category: {
        required,
        $lazy: true
      },
      division: {
        required,
        $lazy: true
      }
    }
  },
  computed: {
    ...mapState("eventModule", [
      "selectedMatch",
      "getMatchStatus",
      "joinMatchStatus"
    ]),
    ...mapState("authenticate", ["user", "is_admin"]),
    getVisibleItems() {
      return this.selectedMatch.sponsors.length < 4
        ? this.selectedMatch.sponsors.length
        : 4;
    }
  },
  methods: {
    goBack() {
      this.$store.commit("eventModule/updateSelectedMatch", {});
      this.$router.go(-1)
    },
    onCancel() {
      this.$router.push({ name: "dashboard" });
    },
    onCopy() {
      this.$toast.add({
        severity: "success",
        summary: "Copied",
        life: 300
      });
    },
    onError() {
      alert("Failed to copy texts");
    },
    onContinue() {
      this.$router.push({ name: "dashboard" });
    }
  }
};
</script>

<style lang="scss" scoped>
.heading {
  padding-top: 20px;
  align-items: center;
  text-align: center;
  font-weight: bold;
  font-size: 22px;
  letter-spacing: 1.25px;
  color: #000000;

  .pi {
    color: #000000;
  }
}

.reg-note {
  color: #cbcfd0;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 1.25px;
  text-align: left;
}
:deep
  .p-datepicker
  .p-datepicker-header
  .p-datepicker-title
  .p-datepicker-month,
:deep .p-datepicker .p-datepicker-header .p-datepicker-title select {
  margin-right: 0.5rem;
  padding: 10px;
  border: 0;
  box-shadow: 0 5px 5px -3px rgb(0 0 0 / 20%), 0 8px 10px 1px rgb(0 0 0 / 14%),
    0 3px 14px 2px rgb(0 0 0 / 12%);
}
.p-grid {
  overflow: auto;
}
i {
  left: 1rem;
  color: rgba(0, 0, 0, 0.6);
}
.map-link {
  overflow: hidden;
}
:deep .p-dropdown-trigger-icon {
  color: rgba(0, 0, 0, 0.6);
}
.pi-calendar,
.pi-clock {
  z-index: 1;
}
:deep .p-fluid .p-calendar .p-inputtext {
  font-size: 13px;
  margin-left: 20px;
}
.p-input-icon-left {
  label {
    padding: 16px;
  }
}
.p-input-icon-left .pi-calendar {
  margin-top: 0px;
}
.product-item .p-avatar.p-avatar-circle {
  border: 2px solid #a4a4a4;
  border-style: dashed;
}
.product-item :deep .p-avatar img {
  border-radius: 50px;
  object-fit: cover;
}

.sponsor-image {
  margin-right: 10px;
  width: 70px;
  height: 70px;
  border-style: dashed;
  border-radius: 50%;
}

@media only screen and (min-width: 801px) {
  .container {
    width: 410px;
  }
}
</style>