<template>
  <div class="p-grid background">
    <div class="p-col-12">
      <div class="p-grid heading">
        <div class="p-col-2">
          <img class="p-mr-2 user-image" :src="user.image" onerror="this.src='https://qspqa.quickscorepro.co.za/img/user-image.jpg'"/>
        </div>
        <div class="p-col-8 dashboard-text">
          <HeaderLogo/>
          Dashboard
        <h4 class="welcome-text">Welcome {{user.firstName + " " + user.surname}}</h4></div>
        <div class="p-col-2 pointer" @click="logout">
          <i class="pi pi-sign-out"></i>
        </div>
      </div>
    </div>
    <div class="p-col-12 score-content-area p-p-0" v-if="user.isAdmin">
      <TabView
        class="p-mt-2"
        :activeIndex="activeTab"
        @tab-change="onTabChange($event)"
      >
        <TabPanel header="Admin"><admin /> </TabPanel>

        <TabPanel header="Participant">
          <participant />
        </TabPanel>

        <TabPanel header="Official">
          <official />
        </TabPanel>
      </TabView>
    </div>

    <participant v-if="!user.isAdmin" />
  </div>
</template>

<script>
import { mapState } from "vuex";
import Admin from "./Admin.vue";
import Official from './Official.vue';
import Participant from "./Participant.vue";
import HeaderLogo from "../shared/HeaderLogo.vue"

export default {
  name: "Dashboard",
  components: { Admin, Participant, Official, HeaderLogo },
  created() {
    if(this.user.isAdmin){
      this.$store.commit("captureScoreModule/updateScoreViewType", "Official");
    }
    if(this.activeTab == 1 || this.activeTab == 2){
      this.$store.commit("authenticate/updateActingRole", false);
    }
    this.$store.dispatch("authenticate/isOfficial", this.user.id)
  },
  data() {
    return {};
  },
  computed: {
    ...mapState("authenticate", ["user", "activeTab", "is_official"]),
    is_admin: {
      get() {
        return this.$store.state.authenticate.is_admin;
      },
      set(value) {
        this.$store.commit("authenticate/updateActingRole", value);
      },
    },
  },
  methods: {
    logout() {
      this.$store.dispatch("authenticate/logout");
    },
    onTabChange(event) {
      this.is_admin = event.index === this.constants.ADMIN_TAB;
      this.$store.commit("authenticate/updateActiveTab", event.index);
    },
  },
};
</script>

<style lang="scss" scoped>
:deep .p-selectbutton .p-button {
  border-radius: 20px;
}

:deep .p-selectbutton .p-button.p-highlight {
  border-radius: 20px;
}

:deep .p-selectbutton .p-button {
  border: none;
}

.user-role {
  background: #fff;
  border-radius: 20px;
}

.user-image{
  width:48px;
  height:48px;
  border-radius: 50%;
}

.dashboard-text{
  font-family: Roboto;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  letter-spacing: 0px;
}

.welcome-text{
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0px;
}

:deep .p-tabview .p-tabview-panels {
  background: transparent;
  padding: 0px;
}

:deep .p-tabview .p-tabview-nav {
  background: transparent;
  border: none;
  justify-content: center;
}

:deep .p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link {
  background: transparent;
  border-color: transparent;
  color: #fff;
}

:deep .p-tabview .p-tabview-nav li .p-tabview-nav-link {
  background: transparent;
  color: #bebebeb0;
}
.p-avatar img {
    object-fit: contain;
}
</style>