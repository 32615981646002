<template>
  <div>
    <div v-if="forgotPasswordStatus === 'success'">
      <confirmation
        :title="'Email sent, please follow intructions from the email '"
        :status="forgotPasswordStatus"
        :mutation="'authenticate/resetStatus'"
        :fallback="'/login'"
      />
    </div>
    <div
      class="p-grid centered-container h-70 container"
      v-if="forgotPasswordStatus !== 'success'"
    >
      <div class="p-col-12">
        <div class="p-grid heading">
          <div class="p-col-1">
            <router-link to="/home">
              <i class="pi pi-arrow-left"></i>
            </router-link>
          </div>
          <div class="p-col-11 p-pt-6">
            <HeaderLogo />
          </div>
        </div>
      </div>
      <div class="p-col-12">
        <Message
          severity="error"
          v-if="forgotPasswordStatus === 'error'"
          :life="3000"
          :sticky="false"
          >{{
            requestResponse.response !== undefined
              ? `${requestResponse.response}`
              : "Couldn't send email. Please try again"
          }}</Message
        >
      </div>

      <div class="p-col-12">
        <div class="box box-margin">
          <div class="p-fluid p-grid p-formgrid p-text-left">
            <div class="p-col-12">
              <ProgressBar
                v-if="forgotPasswordStatus === 'loading'"
                mode="indeterminate"
                class="progress"
              />
            </div>
            <div class="p-field p-col-12">
              <span class="p-input-icon-left">
                <i class="pi pi-envelope" />
                <InputText
                  id="email"
                  type="email"
                  placeholder="Email address"
                  autocomplete="username"
                  v-model="forgotPassword.email"
                  :class="{
                    'p-invalid':
                      v$.forgotPassword.email.required.$invalid ||
                      v$.forgotPassword.email.email.$invalid
                  }"
                />
              </span>
              <p
                class="error-message"
                v-if="v$.forgotPassword.email.required.$invalid"
              >
                {{ constants.FORM_VALIDATION_ERRORS.EMAIL_REQUIRED }}
              </p>
              <p
                class="error-message"
                v-if="v$.forgotPassword.email.email.$invalid"
              >
                {{ constants.FORM_VALIDATION_ERRORS.EMAIL_INVALID }}
              </p>
            </div>
            <div class="p-col-12 reg-note p-pl-4 button-top-margin">
              A link will be sent to the above email address.
            </div>

            <div class="p-col-12">
              <Button
                type="button"
                class="p-button-rounded p-button-raised"
                :class="[isDisabled ? disabled - send - button : send - button]"
                v-bind:disabled="!isDisabled"
                @click="onForgotPassword"
                label="SEND"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import useVuelidate from "@vuelidate/core";
import { required, email } from "@vuelidate/validators";
import { mapState } from "vuex";
import Confirmation from "../settings/Confirmation";
import HeaderLogo from "../shared/HeaderLogo.vue";

export default {
  name: "forgotPassword",
  components: {
    Confirmation,
    HeaderLogo
  },
  setup() {
    return { v$: useVuelidate() };
  },
  created() {
    this.$store.commit("authenticate/resetStatus");
  },
  data() {
    return {
      forgotPassword: {
        email: ""
      }
    };
  },
  validations: {
    forgotPassword: {
      email: {
        required,
        email,
        $lazy: true
      }
    }
  },
  computed: {
    ...mapState("authenticate", ["forgotPasswordStatus", "requestResponse"]),
    isDisabled() {
      return this.forgotPassword.email.length > 0;
    }
  },
  methods: {
    async onForgotPassword() {
      this.v$.$touch();
      if (!this.v$.$invalid) {
        await this.$store.dispatch(
          "authenticate/forgotPassword",
          this.forgotPassword.email
        );
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.heading {
  font-size: 20px;
  font-weight: bold;
  align-items: center;
  text-align: center;
  letter-spacing: 1.25px;
  color: #3e4547;

  .pi {
    color: #000000;
  }
}

.h-70 {
  height: 70vh;
}

.progress {
  height: 0.09em;
  width: 98%;
  margin-left: 2px;
}

.reg-note {
  color: #cbcfd0;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 1.25px;
  text-align: left;
}

.box-margin {
  margin-bottom: 450px;
}

.send-button {
  background: #d8d8d8;
  height: 46.99px;
  color: #bdbdbd;
}

.disabled-send-button {
  height: 46.99px;
}

.button-top-margin {
  margin-bottom: 80px;
}

.container {
  overflow: hidden;
}
</style>
