<template>
  <div>
    <confirmation
      v-if="isSubmitted"
      :title="'Admin(s) has successfully been added'"
      :status="addClubAdminStatus"
      :fallback="'/club'"
    />

    <div
      class="p-grid centered-container container p-pl-2 p-pr-2"
      v-if="!isSubmitted"
    >
      <div class="p-grid">
        <div class="p-col-12 p-pt-6">
          <div class="p-grid heading">
            <div class="p-col-1">
              <router-link to="/club">
                <i class="pi pi-arrow-left arrow-color"></i>
              </router-link>
            </div>
            <div class="p-col-11">
              <HeaderLogo />
            </div>
            <div class="p-col-12">
              <h4 class="heading-color">Add admin</h4>
            </div>
          </div>
        </div>
        <div class="p-col-12 p-pb-4 search">
          <h4 class="add-admin-label">Admin details</h4>
          <span class="p-input-icon-left">
            <i class="pi pi-search" />
            <AutoComplete
              class="autocomplete-border"
              v-model="selectedOfficials"
              :suggestions="searchUserList"
              placeholder="Search by name"
              @complete="onEmailChange($event)"
              @item-select="onDropDownClick($event)"
              @clear="onClear()"
              field="email"
            />
          </span>
          <div class="p-col-12">
            <ProgressBar
              v-if="isLoading"
              mode="indeterminate"
              class="progress"
            />
          </div>
          <p class="error-message" v-if="!adminFound && !isSearchCleared">
            * No user with that name found
          </p>
          <p></p>
        </div>
      </div>
      <div v-for="(input, index) in inputs" :key="inputs[index].Email">
        <div class="p-grid item h-80-custom p-pl-1 admin-item-border">
          <div class="p-col-3 p-as-center pic">
            <Avatar class="p-mr-2 user-icon" :image="inputs[index].image" />
          </div>
          <div class="p-col-6 p-as-center p-text-left">
            <div class="vertical-container">
              <div class="p-col-12 p-text-truncate p-pt-0">
                <span class="user-name">
                  {{ inputs[index].firstName }}
                </span>
              </div>
              <div class="p-col-12 p-text-truncate p-pt-0">
                <span class="user-email">
                  {{ inputs[index].Email }}
                </span>
              </div>
            </div>
          </div>
          <div class="p-col-3 p-as-center p-text-right">
            <div class="vertical-container">
              <div class="box">
                <div
                  class="text-primary"
                  :key="inputs[index].Email"
                  @click="removeAdminFromList(inputs[index].Email)"
                >
                  <i class="pi pi-times x-color"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="p-col-12">
        <Message
          severity="error"
          v-if="searchStatus === 'error'"
          :life="3000"
          :sticky="false"
          >An error occured. Please try again</Message
        >
      </div>
      <div class="p-field p-col-12 p-pt-4">
        <Button
          class="p-button-rounded p-button-raised"
          type="button"
          v-show="showButton"
          :disabled="disableButton"
          @click="addAdmin()"
          label="MAKE CLUB ADMIN "
        >
          <ProgressSpinner
            v-if="addClubAdminStatus === 'loading'"
            class="progress-spinner-custom"
            strokeWidth="5"
            animationDuration=".5s"
          />
        </Button>
        <h4 class="club-label" v-show="showButton">
          Club: {{ selectedClub.clubName }}
        </h4>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import Confirmation from "../Confirmation.vue";
import HeaderLogo from "../../shared/HeaderLogo";
export default {
  components: { Confirmation, HeaderLogo },
  name: "AddAdmin",
  created() {
    this.adminFound = true;
  },
  data() {
    return {
      isSubmitted: false,
      adminModel: {
        Email: "",
        ClubId: ""
      },
      filteredList: null,
      selectedOfficials: [],
      email: "",
      query: "",
      inputs: [],
      showButton: false,
      disableButton: true,
      clubName: "",
      adminFound: false,
      isSearchCleared: false,
      isLoading: false
    };
  },
  computed: {
    ...mapState("authenticate", ["searchStatus", "searchUserList"]),
    ...mapState("clubModule", ["selectedClub", "addClubAdminStatus"])
  },
  methods: {
    async onEmailChange(event) {
      if (event.query.length >= 3) {
        if (this.searchStatus === "loading") {
          this.isLoading = true;
        }
        this.isSearchCleared = false;
        this.showButton = true;
        setTimeout(() => {
          this.getUsers(event.query);
        }, 500);
      }
    },

    addInput() {
      this.inputs.push(this.inputs.length + 1);
    },

    removeAdminFromList(e) {
      for (var i in this.inputs) {
        if (this.inputs[i].Email == e) {
          this.inputs.splice(i, 1);
        }
      }
      if (this.inputs.length <= 0) {
        this.showButton = false;
      }
    },

    async getUsers(query) {
      await this.$store
        .dispatch("authenticate/userSearchByName", query)
        .then(() => {
          if (this.searchUserList.length == 0) {
            this.adminFound = false;
            this.isLoading = false;
          } else {
            this.adminFound = true;
          }
        });
      this.query = query;
      if (this.searchStatus === "error") {
        this.adminFound = true;
        this.isLoading = false;
      }
    },
    onSelect(user) {
      if (!this.selectedOfficials.find(i => i.email === user.email))
        this.selectedOfficials.push(user);
    },
    async onDropDownClick(query) {
      this.selectedClub = this.selectedClub.clubName;
      this.disableButton = false;
      this.inputs.push({
        image: query.value.image,
        Email: query.value.email,
        firstName: query.value.firstName
      });
    },

    async addAdmin() {
      if (this.inputs.length > 0) {
        this.isSubmitted = true;
        await this.inputs.forEach(x => {
          this.adminModel.Email = x.Email;
          this.adminModel.ClubId = this.selectedClub.clubID;
          this.$store.dispatch("clubModule/addClubAdmin", this.adminModel);
        });
      }
    },

    onClear() {
      this.isSearchCleared = true;
      this.isLoading = false;
    }
  }
};
</script>

<style lang="scss" scoped>
.input-border {
  border: 1px solid #cbcfd0 !important;
}

.customColor {
  background-color: #ffffff !important;
}

.club-label {
  color: #595959;
  left: 4.44%;
  align-items: left;
  text-align: left;
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
}

.admin-item-border {
  border: none;
}

.user-icon {
  width: 40px;
  height: 40px;
}

.user-name {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 28px;
}

.user-email {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
}

.arrow-color {
  color: #757575 !important;
}

.heading-color {
  color: #3e4547 !important;
}

.add-admin-label {
  left: 4.44%;
  align-items: left;
  text-align: left;
}

.autocomplete-border {
  position: initial;
  background-color: #fbfbfb;
  border: 1px solid #cbcfd0;
  box-sizing: border-box;
  border-radius: 50px;
  padding-left: 30px;
}
.x-color {
  color: #3e4547;
}
</style>