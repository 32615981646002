import { createStore } from "vuex";
import authenticate from "./modules/authenticate";
import utilModule from "./modules/util.module";
import eventModule from "./modules/event.module";
import clubModule from "./modules/club.module";
import categoryModule from "./modules/category.module";
import targetModule from "./modules/target.module";
import branchModule from "./modules/branch.module";
import divisionModule from "./modules/division.module";
import captureScoreModule from "./modules/event.capture.score.module";

import createPersistedState from "vuex-persistedstate";

export default createStore({
  state: {},
  mutations: {},
  actions: {},
  plugins: [
    createPersistedState({}),
  ],
  modules: {
    authenticate,
    utilModule,
    eventModule,
    clubModule,
    categoryModule,
    targetModule,
    branchModule,
    divisionModule,
    captureScoreModule,
  },
});
