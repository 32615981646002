<template>
  <div class="p-grid centered-container h-100hv container">
    <div class="p-col-12 p-text-center" v-if="status === 'loading'">
      Submitting...
      <ProgressBar mode="indeterminate" />
    </div>

    <div class="p-col-12 p-text-center" v-if="status === 'success'">
      <img src="../../images/successLogo.png" alt="" />
      <p class="success-text">{{ title }}</p>
      <Button
        class="p-button-rounded p-button-raised"
        type="button"
        label="DONE"
        @click="onContinue"
      />
    </div>
    <div class="p-col-12 p-text-center" v-if="status === 'error'">
      <div class="p-col-12">
        <Message severity="error" :sticky="true"
          >An error eccured. Please try again</Message
        >
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Confirmation",
  props: {
    status: String,
    fallback: String,
    title: String,
    mutation: String
  },
  methods: {
    onContinue() {
      this.$store.commit(this.mutation);
      this.$router.push(this.fallback);
    }
  }
};
</script>

<style lang="scss" scoped>
.success-text {
  font-family: Roboto;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
  letter-spacing: 1.25px;
  color: #093248;
}
</style>