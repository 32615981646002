<template>
  <div class="p-grid background">
    <div class="p-col-12">
      <div class="p-grid heading">
        <div class="p-col-2" @click="$router.go(-1)">
          <i class="pi pi-arrow-left"></i>
        </div>
        <div class="p-col-8 dashboard-text">
          <HeaderLogo />
          <div class="p-pb-3"></div>
          <div class="p-pb-3">{{selectedMatch.name}}</div>
          <div class="p-pb-3">{{selectedMatch.clubName}}</div>
          <div v-if="scoreBoardType === 'myScores'">My individual scores</div>
          <div v-if="scoreBoardType === 'leaderboard'">Leaderboard</div>
        </div>
      </div>
    </div>
    <div class="p-col-12 p-pb-4 search"></div>
    <div class="p-col-12">
      <ProgressBar v-if="scoreBoard === 'loading'" mode="indeterminate" class="progress" />
    </div>
    <div class="p-col-12 p-p-4 content-area">
      <Toast position="top-center" group="br" class="toast" />
      <div class="col-6 p-text-left p-pb-4" v-if="scoreBoardType === 'leaderboard'">
        <select name="filters" id="filters" class="dropdown" @change="onDropDownChange($event)">
          <option v-for="item in dropDownvalue" :key="item.id" :value="item.value">{{item.name}}</option>
          <option value="MyScores" v-if="scoreViewType =='Participant'">My Scores</option>
        </select>
      </div>
      <div class="p-text-left total" v-if="scoreBoardType === 'myScores' && scoreBoard.length > 0">
        <label>Total: {{myScoreTotal()}}</label>
      </div>
      <div class="p-text-right p-pb-3">
        <svg
          class="pointer"
          @click="downloadTable()"
          v-if="scoreBoard.length > 0 && canEditScores || scoreBoard.length > 0  && scoreBoardType === 'myScores'"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M19 9.5H15V3.5H9V9.5H5L12 16.5L19 9.5ZM11 11.5001V5.50008H13V11.5001H14.17L12 13.6701L9.82996 11.5001H11ZM19 20.5V18.5H5V20.5H19Z"
            fill="black"
            fill-opacity="0.6"
          />
        </svg>
        <svg
          class="pointer"
          v-if="canEditScores && scoreBoard.length > 0"
          @click="adminEditScore"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M17.658 3C17.408 3 17.148 3.1 16.958 3.29L15.128 5.12L18.878 8.87L20.708 7.04C21.098 6.65 21.098 6.02 20.708 5.63L18.368 3.29C18.168 3.09 17.918 3 17.658 3ZM14.058 9.02L14.978 9.94L5.91805 19H4.99805V18.08L14.058 9.02ZM2.99805 17.25L14.058 6.19L17.808 9.94L6.74805 21H2.99805V17.25Z"
            fill="black"
            fill-opacity="0.6"
          />
        </svg>
      </div>
      <div class="col-6 p-text-left label" v-if="scoreBoard.length === 0">
        <label>No scores found for this match. Please check again later.</label>
      </div>
      <div
        v-if="dropDownValue === 'Overall' && scoreBoard.length > 0 && scoreBoardType === 'leaderboard'"
      >
        <score-table :finalScores="columns" :scoresData="finalScoreBoard" />
      </div>
      <div v-if="dropDownValue === 'Division' && scoreBoard.length > 0">
        <score-table :finalScores="divsionColumns" :scoresData="finalScoreBoard" />
      </div>
      <div v-if="dropDownValue === 'Category' && scoreBoard.length > 0">
        <score-table :finalScores="categoryColumns" :scoresData="finalScoreBoard" />
      </div>
      <div v-if="dropDownValue === 'MyScores' && scoreBoard.length > 0">
        <score-table :finalScores="myScoresColumns" :scoresData="finalScoreBoard" />
      </div>
      <div
        v-if="dropDownValue === 'Overall' && scoreBoard.length > 0 && scoreBoardType === 'myScores'"
      >
        <score-table :finalScores="myScoresColumns" :scoresData="finalScoreBoard" />
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
import ScoreTable from "./ScoreTable.vue";
import HeaderLogo from "../../../shared/HeaderLogo.vue";
export default {
  name: "scoreboard",
  components: { ScoreTable, HeaderLogo },
  created() {
    this.$store.commit("captureScoreModule/updateDownload", "");
    this.$store.commit("captureScoreModule/updateDropDownValue", "Overall");
    this.$store.dispatch("captureScoreModule/canEditScore", {
      MatchId: this.selectedMatch.matchID,
      UserId: this.user.id
    });
  },
  mounted() {
    this.loadScoreBoard();
  },
  data() {
    return {
      finalScoreBoard: [],
      positionT: "",
      counter: 0,
      scores: [],
      divisionScores: [],
      dropDownvalue: [
        { name: "Overall", value: "Overall" },
        { name: "Division", value: "Division" },
        { name: "Category", value: "Category" }
      ],
      columns: [
        { field: "fullNames", header: "Name" },
        { field: "score", header: "Score" },
        { field: "position", header: "Position" },
        { field: "percentage", header: "%" }
      ],
      divsionColumns: [
        { field: "fullNames", header: "Name" },
        { field: "score", header: "Score" },
        { field: "division", header: "Division" },
        { field: "percentage", header: "%" }
      ],
      categoryColumns: [
        { field: "fullNames", header: "Name" },
        { field: "score", header: "Score" },
        { field: "category", header: "Category" },
        { field: "percentage", header: "%" }
      ],
      myScoresColumns: [
        { field: "fullNames", header: "Range" },
        { field: "score", header: "Score" }
      ],
      scoreBoardType: this.$route.params.scoreBoardType
    };
  },
  computed: {
    ...mapState("captureScoreModule", [
      "scoreBoard",
      "scoreViewType",
      "selectedMatch",
      "dropDownValue",
      "download",
      "canEditScores"
    ]),
    ...mapState("authenticate", ["user", "is_admin"])
  },
  methods: {
    onViewScores(club) {
      this.$router.push({
        name: "view-club-matches"
      });
      this.$store.commit("clubModule/updateSelectedClub", club);
    },
    onDropDownChange(e) {
      if (e.target.value == "Division") {
        this.$store.commit(
          "captureScoreModule/updateDropDownValue",
          "Division"
        );
        this.$store
          .dispatch(
            "captureScoreModule/getDivisionScoreBoard",
            this.selectedMatch.matchID
          )
          .then(() => {
            this.loadDivisionScoreBoard();
          });
      } else if (e.target.value == "Category") {
        this.$store.commit(
          "captureScoreModule/updateDropDownValue",
          "Category"
        );
        this.$store
          .dispatch(
            "captureScoreModule/getCategoryScoreBoard",
            this.selectedMatch.matchID
          )
          .then(() => {
            this.loadCategoryScoreBoard();
          });
      } else if (e.target.value == "MyScores") {
        this.$store.commit(
          "captureScoreModule/updateDropDownValue",
          "MyScores"
        );
        this.$store
          .dispatch("captureScoreModule/getMyScores", {
            MatchId: this.selectedMatch.matchID,
            UserId: this.user.id
          })
          .then(() => {
            this.myScores();
          });
      } else if (e.target.value == "Overall") {
        this.$store.commit("captureScoreModule/updateDropDownValue", "Overall");
        this.$store
          .dispatch(
            "captureScoreModule/getOveralScoreBoard",
            this.selectedMatch.matchID
          )
          .then(() => {
            this.loadScoreBoard();
          });
      }
    },
    loadScoreBoard() {
      this.scores = [];
      this.finalScoreBoard = [];
      this.counter = 0;

      this.scoreBoard.forEach(x => {
        if (this.scores.includes(x.score)) {
          if (this.counter.toString().slice(-1) == 1) {
            this.positionT = this.counter + "st";
          } else if (this.counter.toString().slice(-1) == 2) {
            this.positionT = this.counter + "nd";
          } else if (this.counter.toString().slice(-1) == 3) {
            this.positionT = this.counter + "rd";
          } else if (this.counter.toString().slice(-1) > 3) {
            this.positionT = this.counter + "th";
          } else {
            this.positionT = this.counter + "th";
          }
          this.finalScoreBoard.push({
            fullNames: x.fullNames,
            score: x.score,
            percentage: x.percentage.toFixed(2),
            position: this.positionT + " tied"
          });
        } else {
          this.counter = this.counter + 1;
          if (this.counter.toString().slice(-1) == 1) {
            this.positionT = this.counter + "st";
          } else if (this.counter.toString().slice(-1) == 2) {
            this.positionT = this.counter + "nd";
          } else if (this.counter.toString().slice(-1) == 3) {
            this.positionT = this.counter + "rd";
          } else if (this.counter.toString().slice(-1) > 3) {
            this.positionT = this.counter + "th";
          } else {
            this.positionT = this.counter + "th";
          }
          this.finalScoreBoard.push({
            fullNames: x.fullNames,
            score: x.score,
            percentage: x.percentage.toFixed(2),
            position: this.positionT
          });
        }
        this.scores.push(x.score);
      });
    },
    myScores() {
      this.scores = [];
      this.finalScoreBoard = [];
      this.counter = 0;

      this.scoreBoard.forEach(x => {
        this.counter = this.counter + 1;
        if (this.counter.toString().slice(-1) == 1) {
          this.positionT = this.counter + "st";
        } else if (this.counter.toString().slice(-1) == 2) {
          this.positionT = this.counter + "nd";
        } else if (this.counter.toString().slice(-1) == 3) {
          this.positionT = this.counter + "rd";
        } else if (this.counter.toString().slice(-1) > 3) {
          this.positionT = this.counter + "th";
        } else {
          this.positionT = this.counter + "th";
        }
        this.finalScoreBoard.push({
          fullNames: x.fullNames,
          score: x.score,
          percentage: x.percentage.toFixed(2),
          position: this.positionT
        });
        this.scores.push(x.score);
      });
    },
    loadDivisionScoreBoard() {
      this.scores = [];
      this.divisionScores = [];
      this.finalScoreBoard = [];
      this.counter = 0;

      this.scoreBoard.forEach(x => {
        this.finalScoreBoard.push({
          fullNames: x.fullNames,
          score: x.score,
          division: x.division,
          percentage: x.percentage.toFixed(2),
          position: this.positionT
        });

        this.scores.push(x.score);
      });
    },
    loadCategoryScoreBoard() {
      this.scores = [];
      this.finalScoreBoard = [];
      this.counter = 0;

      this.scoreBoard.forEach(x => {
        this.finalScoreBoard.push({
          fullNames: x.fullNames,
          score: x.score,
          category: x.category,
          percentage: x.percentage.toFixed(2),
          position: this.positionT
        });

        this.scores.push(x.score);
      });
    },
    adminEditScore() {
      this.$router.push({
        name: "admin-score-edit"
      });
    },
    downloadTable() {
      this.$store.commit("captureScoreModule/updateDownload", "download");
    },
    myScoreTotal() {
      var total = 0;
      this.scoreBoard.forEach(element => {
        total = total + element.score;
      });
      return total;
    }
  }
};
</script>

<style lang="scss" scoped>
.club-item {
  .p-avatar.p-avatar-xl {
    width: 100px;
    height: 40px;
    font-size: 2rem;
    object-fit: contain;
  }
}
.match-name {
  height: 16px;
  left: 8.89%;
  right: 60.28%;
  top: calc(50% - 16px / 2 - 189px);
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #3e4547;
}
.club-name {
  height: 14px;
  left: 8.89%;
  right: 77.78%;
  top: calc(50% - 14px / 2 - 172px);
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  color: #3e4547;
}

table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

td,
th {
  border-bottom: 1px solid #e6e6e6;
  text-align: center;
  padding: 8px;
}

.dropdown {
  height: 30px;
  width: 84px;
  left: 16px;
  top: 17.73%;
  bottom: 76.82%;
  float: left;
  border: none;
}

.row-background-color {
  background-color: red;
}

.label {
  color: #000000;
  padding-top: 50px;
}

.total {
  font-weight: bold;
}
</style>