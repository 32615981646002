export const COMMON_TEXT = {
  FIND_CLUB: "Find club",
  FIND_MATCH: "Find match",
  REGISTERED_MATCHES: "Registered matches",
  MY_SCORES: "My scores",
  ASSIGNED_MATCHES: "Assigned matches",
  VIEW_SCORES: "View scores",
  AS_PARTICIPANT: "As Participant",
  MY_CLUBS: "My Clubs"
};

export const ADMIN_TAB = 0;
export const PARTICIPANT_TAB = 1;

export const DATE_FILTER = {
  UPCOMING: "UPCOMING",
  COMPLETED: "COMPLETED",
  ONGOING: "ONGOING",
};

export const REGISTRATION = {
  MAIN: "MAIN",
  TERMS: "TERMS",
  POLICY: "POLICY",
};

export const MATCH_STATUS_TYPES = {
  ON_GOING: "On-Going",
}

export const PAGE_NUMBERS = {
  MATCH_DETAILS: 1,
  RANGE_DETAILS: 2,
  OFFICIAL_DETAILS: 3,
  SPONSOR_DETAILS: 4,
}

export const ZERO_ID = {
  ID: "00000000-0000-0000-0000-000000000000",
}

export const IMAGE_VALIDATION = {
  IMAGE_MAX_SIZE: 6 * 1024 * 1024,
  EXCEEDED_IMAGE_SIZE_ERROR: "* Please upload an image not bigger than 5 MB in size",
  IMAGE_REQUIRED: "* Image required"
}

export const EMPTY_STATE_TEXT = {
  EMPTY_MATCH_LIST: "No matches found",
  EMPTY_CLUB_LIST: "No clubs found",
}

export const FORM_VALIDATION_ERRORS = {
  EMAIL_REQUIRED: "* Email address is required",
  EMAIL_INVALID: "* Enter a valid email address",
  LOCATION_REQUIRED: "* Location is required",
  GOOGLE_LINK_REQUIRED: "* Google maps link is required",
}
