<template>
  <div  class="list-items-w-border">
  <Accordion>
    <AccordionTab v-for="( summary, index ) in matchSummary" :key="index">
      <template #header>
        <div class="p-grid nested-grid">
          <div class="p-col-3 p-d-flex p-flex-column p-jc-center p-ai-start">
            <img alt="icon" :src="require(`@/assets/icons/${summary.iconSrc}`)"/>
          </div>
          <div class="p-col-8 p-d-flex p-flex-column p-jc-center p-ai-start">
            <label class="p-text-bold p-text-left">{{summary.title}}</label>
            <label class="p-text-left">
              {{summary.upcomingMatches}} upcoming match{{summary.upcomingMatches == 1 ? '': 'es'}}
            </label>
          </div>
        </div>
      </template>
      <div class="p-d-flex p-flex-column" v-for="match  in summary.matches" :key="match.matchId">
        <Divider class="top-divider" />
        <div class="p-d-flex p-jc-between p-px-3 p-pb-2">
          <label class="p-text-bold t-d-none">{{match.name}}</label>
          <router-link :to="{ name:'view-match', params: {match_url:match.matchID}}">
            <label class="text-primary pointer">View Match</label>
          </router-link>
        </div>
      </div>
    </AccordionTab>
  </Accordion>
  </div>
</template>

<script>
export default {
  name: 'match-summary-accordion',
  props: {
    matchSummary: { type: Array, default: () => [] }
  },
  methods: {
  },
}
</script>

<style lang="scss" scoped>
img {
  width: 100%;
}

:deep(.p-accordion-header-link) {
  padding: 10px !important;
}

:deep(.p-accordion-content) {
  padding: 0 !important;
  padding-bottom: 5px !important;
}

:deep(.p-divider-horizontal).top-divider {
  margin-top: 0;
  margin-bottom: 10px;
}
</style>