<template>
  <div class="list-items-w-border">
    <Accordion>
      <AccordionTab v-for="(match, index) in matches" :key="index">
        <template #header>
          <div class="p-d-flex p-flex-column">
            <label class="p-text-bold p-text-left">{{ match.name }}</label>
            <label
              class="p-text-left"
            >{{match.status == constants.MATCH_STATUS_TYPES.ON_GOING ? constants.MATCH_STATUS_TYPES.ON_GOING : new Date(match.startDate).toDateString()}}</label>
          </div>
        </template>
        <div class="p-d-flex p-jc-between">
          <label class="p-text-bold">Event:</label>
          <router-link :to="{ name:'view-match', params: {match_url:match.matchID}}">
            <label class="text-primary">{{ match.name}}</label>
          </router-link>
        </div>
        <Divider />
        <div class="p-d-flex p-jc-between">
          <label class="p-text-bold">Participant number:</label>
          <label>{{ match.participantNo}}</label>
        </div>
        <Divider v-if="match.branchName != null" />
        <div class="p-d-flex p-jc-between" v-if="match.branchName !=null">
          <label class="p-text-bold">Club Branch:</label>
          <label>{{ match.branchName}}</label>
        </div>
        <Divider />
        <div class="p-d-flex p-jc-between">
          <label class="p-text-bold">Match Type:</label>
          <label>{{ match.type || "none" }}</label>
        </div>
        <Divider />
        <div class="p-d-flex p-jc-between">
          <label class="p-text-bold">Start Time:</label>
          <label>{{ timeFromDateString(match.startDate) }}</label>
        </div>
        <Divider />
        <div class="p-d-flex p-jc-between">
          <label class="p-text-bold">End Time:</label>
          <label>{{ timeFromDateString(match.endDate) }}</label>
        </div>
        <Divider />
        <div class="p-d-flex p-flex-row p-jc-end">
          <div class="action p-p-2 pointer" @click="onViewMyScores(match)">MY SCORES</div>
          <div class="action p-p-2 pointer" @click="onViewScores(match)">LEADERBOARD</div>
        </div>
      </AccordionTab>
    </Accordion>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "registered-match-details-accordion",
  props: {
    matches: { type: Array, default: () => [] }
  },
  methods: {
    onViewScores(match) {
      this.$store.commit("captureScoreModule/updateSelectedMatch", match);
      this.$store
        .dispatch("captureScoreModule/getOveralScoreBoard", match.matchID)
        .then(() => {
          this.$router.push({
            name: "scoreboard",
            params: { scoreBoardType: "leaderboard"}
          });
        });
    },

    onViewMyScores(match) {
      this.$store.commit("captureScoreModule/updateSelectedMatch", match);
      this.$store
        .dispatch("captureScoreModule/getMyScores", {
          MatchId: match.matchID,
          UserId: this.user.id
        })
        .then(() => {
          this.$router.push({
            name: "scoreboard",
            params: { scoreBoardType: "myScores" }
          });
        });
    }
  },
  computed: {
  ...mapState("authenticate", ["user"])
  }
};
</script>

<style lang="scss" scoped>
</style>