<template>
  <div>
    <confirmation
      v-if="isSubmitted && !goBack"
      :title="'New target has successfully been created'"
      :status="createTargetStatus"
      :fallback="'/club'"
      :mutation="'targetModule/create_another_target'"
    />

    <div class="p-grid background" v-if="!isSubmitted || goBack">
      <div class="p-col-12">
        <div class="p-grid heading">
          <div class="p-col-1 p-pb-4">
            <router-link to="/club">
              <i class="pi pi-arrow-left"></i>
            </router-link>
          </div>
          <div class="p-col-11 p-pt-3">
            <HeaderLogo />
          </div>
          <div class="p-col-12">Target bank</div>
        </div>
      </div>

      <div class="p-col-12 h-50-custom">
        <div class="open-file">
          <input
            ref="image"
            type="file"
            accept="image/*"
            @change="uploadImage($event)"
            class="pi pi-arrow-left"
            id="upload-photo"
          />
        </div>
        <div class="picture-holder">
          <label for="upload-photo">
            <img
              v-bind:src="targetModel.image"
              class="p-mr-2 bg-grey-custom image-holder-size pointer"
              size="xlarge"
              shape="circle"
            />
          </label>
        </div>
      </div>

      <div class="p-col-12 p-pt-4 content-area">
        <div class="p-fluid p-formgrid p-grid p-pt-4">
          <div class="p-field p-col-12">
            <div
              class="error-message"
              v-if="v$.targetModel.picture.required.$invalid"
            >
              {{ constants.IMAGE_VALIDATION.IMAGE_REQUIRED }}
            </div>
            <div class="error-message" v-if="isSizeExceeded">
              {{ constants.IMAGE_VALIDATION.EXCEEDED_IMAGE_SIZE_ERROR }}
            </div>
            <span class="p-input-icon-left">
              <i class="pi pi-globe" />
              <InputText
                type="text"
                placeholder="Target name"
                v-model="targetModel.name"
                :class="{
                  'p-invalid': v$.targetModel.name.required.$invalid
                }"
              />
            </span>
            <p
              class="error-message"
              v-if="v$.targetModel.name.required.$invalid"
            >
              * Target name is required
            </p>
          </div>

          <div class="p-field p-col-12 p-pt-4">
            <Button
              class="p-button-rounded p-button-raised"
              type="button"
              @click="createTarget()"
              label="Create "
              ><ProgressSpinner
                v-if="createTargetStatus === 'loading'"
                class="progress-spinner-custom"
                strokeWidth="5"
                animationDuration=".5s"
              />
            </Button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import { mapState } from "vuex";
import Confirmation from "../Confirmation.vue";
import CameraIcon from "../../../assets/icons/camera-icon.svg";
import HeaderLogo from "../../shared/HeaderLogo.vue";

export default {
  components: { Confirmation, HeaderLogo },
  name: "createTarget",
  setup() {
    return { v$: useVuelidate() };
  },
  created() {
    this.$store.commit("targetModule/resetStatus");
  },
  data() {
    return {
      isSubmitted: false,
      targetModel: {
        name: "",
        image: CameraIcon,
        picture: ""
      },
      isSizeExceeded: false
    };
  },
  validations: {
    targetModel: {
      name: {
        required,
        $lazy: true
      },
      image: {
        required,
        $lazy: true
      },
      picture: {
        required,
        $lazy: true
      }
    }
  },
  computed: {
    ...mapState("targetModule", ["createTargetStatus", "goBack"]),
    ...mapState("clubModule", ["selectedClub"]),
    ...mapState("utilModule", ["status", "provinces"]),
    ...mapState("authenticate", ["user"])
  },

  methods: {
    async createTarget() {
      this.$store.commit("targetModule/resetStatus");
      this.v$.$touch();
      if (!this.v$.$invalid) {
        let form = new FormData();
        form.append("clubID", this.selectedClub.clubID);
        form.append("name", this.targetModel.name);
        form.append("image", this.targetModel.picture);
        this.isSubmitted = true;
        await this.$store.dispatch("targetModule/create", form).then(() => {
          (this.targetModel = {
            name: "",
            image: CameraIcon
          }),
            this.$store.commit("clubModule/show_back_button");
        });
      }
    },

    uploadImage(event) {
      this.isSizeExceeded = false;
      this.targetModel.picture = event.target.files[0];
      const input = this.$refs.image;
      const files = input.files;
      this.fileSize = files[0].size;
      if (this.fileSize >= this.constants.IMAGE_VALIDATION.IMAGE_MAX_SIZE) {
        this.isSizeExceeded = true;
        this.targetModel.picture = "";
      }
      if (
        files &&
        files[0] &&
        this.fileSize < this.constants.IMAGE_VALIDATION.IMAGE_MAX_SIZE
      ) {
        const reader = new FileReader();
        reader.onload = e => {
          this.targetModel.image = e.target.result;
        };
        reader.readAsDataURL(files[0]);
        this.$emit("input", files[0]);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.picture-holder :deep .p-avatar img {
  border-radius: 50px;
  object-fit: cover;
}
</style>