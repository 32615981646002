<template>
  <div class="p-grid centered-container h-100hv">
    <div class="p-col-12 p-text-center" v-if="status === 'loading'">
      Submitting...
      <ProgressBar mode="indeterminate" />
    </div>

    <div class="p-col-12 p-text-center" v-if="status === 'success'">
    <img src="../../images/unsuccesfulLogo.png" alt="">
      <p>{{ title }}</p>
      <Button
        class="p-button-rounded p-button-raised"
        type="button"
        label="Continue"
        @click="onContinue"
      />
    </div>
    <div class="p-col-12 p-text-center" v-if="status === 'error'">
    <img src="../../images/unsuccesfulLogo.png" alt="">
      <p>{{ title }}</p>
      <Button
        class="p-button-rounded p-button-raised"
        type="button"
        label="Continue"
        @click="onContinue"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "Unsuccessful",
  props: {
    status: String,
    fallback: String,
    title: String,
  },
  methods: {
    onContinue() {
      this.$router.push(this.fallback);
    },
  },
};
</script>

<style lang="scss" scoped>
</style>