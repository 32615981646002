<template>
  <div class="p-grid centered-container container">
    <div class="p-col-12 logo-padding">
      <div class="p-grid heading">
        <div class="p-col-1">
          <router-link to="/home">
            <i class="pi pi-arrow-left"></i>
          </router-link>
        </div>
        <div class="p-col-11">
          <HeaderLogo />
        </div>
      </div>
    </div>
    <div class="p-col-12">
      <Message
        severity="error"
        v-if="status === 'error'"
        :life="3000"
        :sticky="false"
        >{{requestResponse.response !== undefined ? `${requestResponse.response}`: "Login failed. Please try again" }}</Message
      >
    </div>

    <div class="p-col-12">
      <div class="box box-margin">
        <div class="p-fluid p-grid p-formgrid p-text-left">
          <div class="p-col-12">
            <ProgressBar
              v-if="status === 'loading'"
              mode="indeterminate"
              class="progress"
            />
          </div>
          <div class="p-field p-col-12">
            <span class="p-float-label p-input-icon-left">
              <i class="pi pi-envelope" />
              <InputText
                id="email"
                type="email"
                autocomplete="username"
                v-model="login.email"
                :class="{
                  'p-invalid':
                    v$.login.email.required.$invalid ||
                    v$.login.email.email.$invalid,
                }"
              />
               <label for="inputtext-left">Email address</label>
            </span>
            <p class="error-message" v-if="v$.login.email.required.$invalid ||v$.login.email.email.$invalid">* Enter a valid email address</p>
          </div>
          <div class="p-field p-col-12 button-top-margin">
            <span class="p-float-label p-input-icon-left">
                <i class="pi pi-lock icon-styling" />
                <Password
                  v-model="login.password"
                  toggleMask
                  placeholder="Password"
                  autocomplete="password"
                  :feedback="false"
                  :class="{
                  'p-invalid':
                  v$.login.email.required.$invalid ||
                  v$.login.password.minLength.$invalid,
                  }">
                </Password>
            </span>
            <p class="error-message" v-if="v$.login.email.required.$invalid || v$.login.password.minLength.$invalid">* Enter a valid password</p>
          </div>
          <div class="p-col-12">
            <Button
              type="button"
              class="p-button-rounded p-button-raised"
              :class= "[isDisabled ? disabled-login-button : login-button]"
              v-bind:disabled='!isDisabled'
              @click="auth"
              label="LOGIN"
            />
          </div>
          <div class="p-col-12">
            <div class="p-pb-2"></div>
            <Button
              type="button"
              class="p-button-rounded p-button-raised p-button-outlined"
              @click="register"
              label="REGISTER"
            />
          </div>
          <div class="p-col-12">
            <div class="p-pb-2"></div>
            <Button
              type="button"
              class="p-button-text p-px-2 p-m-0 p-pt-4"
              @click="onForgotPassword"
              label="FORGOT PASSWORD?"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import useVuelidate from "@vuelidate/core";
import { required, email, minLength } from "@vuelidate/validators";
import { mapState } from "vuex";
import HeaderLogo from "../shared/HeaderLogo.vue"

export default {
  name: "login",
  components: { HeaderLogo },
  setup() {
    return { v$: useVuelidate() };
  },
  created() {
    this.$store.commit("authenticate/resetStatus");
  },
  data() {
    return {
      login: {
        email: "",
        password: "",
      },
    };
  },
  validations: {
    login: {
      email: {
        required,
        email,
        $lazy: true,
      },
      password: {
        required,
        minLength: minLength(8),
        $lazy: true,
      },
    },
  },
  computed: {
    ...mapState("authenticate", ["status", "requestResponse"]),
    isDisabled() {
      var disabled = this.login.email.length > 0 && this.login.password.length > 0;
      return disabled;
    }
  },
  methods: {
    async auth() {
      this.v$.$touch();
      if (!this.v$.$invalid) {
        await this.$store.dispatch("authenticate/login", this.login);
      }
    },
    register() {
      this.$router.push({ name: "register" });
    },
    onForgotPassword() {
      this.$router.push({ name: "forgot-password" });
    },
  },
};
</script>

<style lang="scss" scoped>
.heading {
  font-size: 20px;
  font-weight: bold;
  align-items: left;
  text-align: center;
  letter-spacing: 1.25px;
  color: #3E4547;

  .pi {
    color: #000000;
  }
}

.h-70 {
  height: 70vh;
}

.progress {
  height: 0.09em;
  width: 98%;
  margin-left: 2px;
}

.lock-icon {
  z-index: 1;
}

.box-margin {
  margin-bottom: 250px;
}

.disabled-login-button {
  background: #D8D8D8;
  height: 46.99px;
  color: #BDBDBD;
}

.login-button {
  height: 46.99px;
}

::v-deep .p-inputtext {
    padding-left: 45px;
}

.button-top-margin {
  margin-bottom: 50px;
}

.icon-styling{
  z-index: 1000;
}

.logo-padding {
  padding-top: 40px;
}
</style>
