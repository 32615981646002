<template>
  <div :class="stretch ? 'stretch' : 'p-d-flex p-jc-center'" @click="onTileClick()">
      <div class="p-card c-tile pointer">
        <div class="p-header p-pb-2">
          <img class="inner-tile" alt="icon" :src="require(`@/assets/icons/${iconSrc}`)"/>
        </div>
        <div class="h-text p-card-title c-title">
          <label>{{ title }}</label>
        </div>
      </div>
  </div>
</template>

<script>
export default {
  name: "dashboard-tile",
  props: { 
    title: String,
    navigateTo: String,
    iconSrc: { type: String, default: 'default-icon.png' },
    stretch: { type: Boolean, default: false }
  },
  methods:{
    onTileClick(){
      //Official
      if(this.title == "Assigned matches" && this.navigateTo =="assigned-matches"){
        this.$router.push({ name: "assigned-matches" });
      }else if(this.title == "View scores" && this.navigateTo =="view-scores"){
        this.$store.commit("captureScoreModule/updateScoreViewType", "Official");
        this.$router.push({ name: "view-scores" });
      }
      //Participant
      else if(this.title == "Find club" && this.navigateTo =="clubs"){
        this.$router.push({ name: "clubs" });
      }else if(this.title == "Find match" && this.navigateTo =="matches"){
        this.$router.push({ name: "matches" });
      }else if(this.title == "Registered matches" && this.navigateTo =="registered-matches"){
        this.$router.push({ name: "registered-matches" });
      }else if(this.title == "My Scores" && this.navigateTo =="view-scores"){
        this.$store.commit("captureScoreModule/updateScoreViewType", "Participant");
        this.$router.push({ name: "view-scores" });
      }
      //Admin
      else if(this.title == "My Clubs" && this.navigateTo =="clubs"){
        this.$router.push({ name: "clubs" });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.c-tile {
  border-radius: 18px;
  text-decoration: none;
  min-width: 200px;
  .inner-tile {
    height: 100px;
    width: 100%;
    max-width: 100px;
    margin-top: 10px;
  }

  .c-title {
    font-weight: normal !important;
    padding: 10px;
  }
}

.t-d-none {
  text-decoration: none;
}

</style>