<template>
  <div>
    <div class="p-d-flex p-pt-2">
      <div class="p-col-10 p-text-left p-pl-0 p-pb-3 h-text">{{ title }}</div>
      <div class="p-col-2 p-text-right">
        <div @click="addCallBack" v-if="showAdd">
          <i class="pi pi-plus"></i>
        </div>
      </div>
    </div>

    <div class="item h-80-custom p-jc-center p-ai-center" v-if="imagePaths.length === 0">
      <label>{{ noContentText }}</label>
    </div>

    <div class="item h-80-custom" v-if="imagePaths.length > 0">
      <div class="p-col-8 p-as-center custom-image">
        <AvatarGroup>
          <Avatar size="xlarge" shape="circle" v-for=" ( image, key ) in imagePaths.slice(0,5)" :key="key" :image="image"/>
        </AvatarGroup>
      </div>

      <div class="p-col-4 p-as-center p-text-right text-primary pointer">
        <div @click="viewAllCallback">
          <label>View all</label>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "avatar-group-with-link",
  props: {
    title: { type: String, default: "Title" },
    imagePaths: { type: Array, default: () => [] },
    noContentText: { type: String, default: "No Content" },
    addCallBack: { type: Function, default: () => null},
    viewAllCallback: { type: Function, default: () => null},
    showAdd: { type: Boolean, default: true }
  }
}
</script>

<style lang="scss" scoped>
:deep(.p-avatar img) {
  border-radius: 50%;
  object-fit: cover;
}
</style>
