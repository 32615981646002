<template>
  <div>
    <confirmation
      v-if="isSubmitted"
      :title="'Category has successfully been updated'"
      :status="updateCategoryStatus"
      :fallback="'/club'"
    />

    <unsuccessful
      v-if="isDeleted"
      :title="'This category has been linked to a match and cannot be removed.'"
      :status="deleteCategoryStatus"
      :fallback="'/categories'"
    />

    <div class="p-grid background" v-if="!isSubmitted && !isDeleted">
      ">
      <div class="p-col-12">
        <div class="p-grid heading">
          <div class="p-col-2">
            <router-link to="/categories">
              <i class="pi pi-arrow-left"></i>
            </router-link>
          </div>
          <div class="p-col-8">
            <HeaderLogo />
            Match category
          </div>
          <div class="p-col-2" />
        </div>
      </div>

      <div class="p-col-12 p-pt-4 content-area">
        <div class="p-fluid p-formgrid p-grid p-p-4">
          <div class="p-field p-col-12">
            <span class="p-input-icon-left">
              <i class="pi pi-globe" />
              <InputText
                type="text"
                placeholder="Category name"
                v-model="categoryModel.name"
                :class="{
                  'p-invalid': v$.categoryModel.name.required.$invalid
                }"
              />
            </span>
            <p
              class="error-message"
              v-if="v$.categoryModel.name.required.$invalid"
            >
              * Category name is required
            </p>
          </div>
          <div class="p-col-12">
            <Message
              severity="error"
              v-if="isDeleteError"
              :life="3000"
              :sticky="false"
              >An error occured. Please try again</Message
            >
          </div>
          <div class="p-field p-col-12 p-pt-4">
            <Button
              class="p-button-rounded p-button-raised"
              type="button"
              @click="updateCategory()"
              label="Update "
              ><ProgressSpinner
                v-if="updateCategoryStatus === 'loading'"
                class="progress-spinner-custom"
                strokeWidth="5"
                animationDuration=".5s"
              />
            </Button>
            <div class="p-field p-col-12 p-pt-4">
              <Button
                label="REMOVE"
                class="p-button-text p-px-2 p-m-0"
                @click="removeCategory"
              >
                <ProgressSpinner
                  v-if="deleteCategoryStatus === 'loading'"
                  class="progress-spinner-custom"
                  strokeWidth="5"
                  animationDuration=".5s"
                />
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import { mapState } from "vuex";
import Confirmation from "../Confirmation.vue";
import Unsuccessful from "../Unsuccessful.vue";
import HeaderLogo from "../../shared/HeaderLogo.vue";

export default {
  components: { Confirmation, Unsuccessful, HeaderLogo },
  name: "updateCategory",
  setup() {
    return { v$: useVuelidate() };
  },
  created() {
    this.$store.commit("categoryModule/resetStatus");
    this.categoryModel = this.selectedCategory;
  },
  data() {
    return {
      isSubmitted: false,
      isDeleted: false,
      categoryModel: {
        name: "",
        clubID: ""
      },
      isDeleteError: false
    };
  },
  validations: {
    categoryModel: {
      name: {
        required,
        $lazy: true
      }
    }
  },
  computed: {
    ...mapState("categoryModule", [
      "updateCategoryStatus",
      "selectedCategory",
      "deleteCategoryStatus",
      "deleteCategoryCode"
    ]),
    ...mapState("clubModule", ["selectedClub"]),
    ...mapState("authenticate", ["user"])
  },

  methods: {
    async updateCategory() {
      this.v$.$touch();
      if (!this.v$.$invalid) {
        this.isSubmitted = true;
        await this.$store.dispatch("categoryModule/update", this.categoryModel);
      }
    },
    async removeCategory() {
      this.v$.$touch();
      this.isDeleteError = false;
      await this.$store
        .dispatch(
          "categoryModule/deleteCategory",
          this.selectedCategory.categoryID
        )
        .then(() => {
          if (this.deleteCategoryCode == 400) {
            this.isDeleted = true;
          } else {
            if (this.deleteCategoryStatus === "success") {
              this.$router.push({ name: "categories" });
            } else if (
              this.deleteCategoryStatus === "error" &&
              this.deleteCategoryCode === undefined
            ) {
              this.isDeleteError = true;
            }
          }
        });
    }
  }
};
</script>

<style lang="scss" scoped></style>
