import EventService from "../../services/event.service";

const state = () => ({
  status: "",
  findMatchStatus: "",
  event: {},
  events: [],
  selectedMatch: {},
  matchID: "",
  selectedClubMatches: [],
  joinMatchStatus: "",
  getMatchStatus: "",
  assignedMatches: [],
  assignedMatchesStatus: "",
  registeredMatches: [],
  assignedMatchesSummary: {},
  participantMatchSummary: {},
  clubsMatchSummary: {},
  myClubsMatchSummary: {},
  viewMatchesPerClub: [],
  getMatchesByClubStatus: "",
  sponsorDetails:{},
  deleteSponsor: "",
  selectedRange:"",
  deletedRange:"",
  rangeInformation: {},
  updateRangeStatus:"",
  linkSponsorStatus: "",
});

// getters
const getters = {
  createEventStatus: (state) => state.status,
  getRegisteredMatches: (state) => state.registeredMatches,
  getAssignedMatchesSummary: (state) => state.assignedMatchesSummary,
  getParticipantMatcheSummary: (state) => state.participantMatchSummary,
  getClubsMatchSummary: (state) => state.clubsMatchSummary,
  getMyClubsMatchSummary: (state) => state.myClubsMatchSummary,
  getViewMatchesPerClub: (state) => state.viewMatchesPerClub,
};

// actions
const actions = {
  async create({ commit }, event) {
    commit("create_event_request");
    await EventService.add(
      event,
      (res) => {
        commit("create_event_success", res.response.matchID);
      },
      (error) => {
        commit("create_event_error");
        console.error("error", error);
      }
    );
  },
  async update({ commit }, event) {
    commit("update_event_request");
    await EventService.update(
      event,
      (res) => {
        commit("update_event_success", res.response);
      },
      (error) => {
        commit("update_event_error");
        console.error("error", error);
      }
    );
  },

  async matchesForClub({ commit }, payload) {
    commit("get_matches_by_club_request");
    await EventService.getMatchesByClub(
      payload,
      (res) => {
        commit("get_matches_by_club_success", res.response);
      },
      (error) => {
        console.error("error", error);
        commit("get_matches_by_club_error");
      }
    );
  },

  async match({ commit }, matchId) {
    commit("get_match_request");
    await EventService.getMatch(
      matchId,
      (res) => {
        commit("get_match_success", res.response);
      },
      (error) => {
        console.error("error", error);
        commit("get_match_error");
      }
    );
  },
  async joinMatch({ commit }, payload) {
    commit("join_match_request");
    await EventService.joinMatch(
      payload,
      (res) => {
        commit("join_match_success", res.response);
      },
      (error) => {
        console.error("error", error);
        commit("join_match_error", error.response.data);
      }
    );
  },

  async assignedMatches({ commit }, payload) {
    commit("assigned_matches_request");
    await EventService.assignedMatches(
      payload,
      (res) => {
        commit("assigned_matches_success", res.response);
      },
      (error) => {
        console.error("error", error);
        commit("assigned_matches_error");
      }
    );
  },

  async officialMatchesToday({ commit }, userId) {
    return await EventService.officialMatchesToday(userId).then((res) =>
      commit("update_upcoming_official_match_summary", res.data)
    );
  },

  async participantMatchesToday({ commit }, userId) {
    return await EventService.participantMatchesToday(userId).then((res) =>
      commit("update_upcoming_participant_match_summary", res.data)
    );
  },

  async myClubMatches({ commit }, userId) {
    return await EventService.myClubMatches(userId).then((res) =>
      commit("update_myclubs_upcoming_match_summary", res.data)
    );
  },

  async adminMatchesToday({ commit }, userId) {
    return await EventService.adminMatchesToday(userId).then((res) =>
      commit("update_upcoming_admin_match_summary", res.data)
    );
  },

  async linkSponsor({ commit }, sponsor) {
    commit("link_sponsor_request");
    await EventService.linkSponsor(
      sponsor,
      (res) => {
        commit("link_sponsor_success", res.response);
      },
      (error) => {
        commit("link_sponsor_error");
        console.error("error", error);
      }
    );
  },

  async getRegisteredMatchesById({ commit }, userId) {
    return await EventService.getRegisteredMatchesById(userId).then((res) => {
      commit("update_registered_matches", res.data.response);
    });
  },

  async viewMatchesPerClub({ commit }, payload) {
    commit("update_view_matches_per_club_request");
    return await EventService.viewMatchesPerClub(payload)
      .then((res) => commit("update_view_matches_per_club", res.data.response));
  },

  async rangeDetails({ commit }, rangeId) {
    commit("get_range_details_request");
    await EventService.rangeDetails(
      rangeId,
      (res) => {
        commit("get_range_details_success", res.response);
      },
      (error) => {
        commit("get_range_details_error");
        console.error("error", error);
      }
    );
  },

  async updateRangeDetails({ commit }, event) {
    commit("update_range_request");
    await EventService.updateRange(
      event,
      (res) => {
        commit("update_range_success", res.response);
      },
      (error) => {
        commit("update_range_error");
        console.error("error", error);
      }
    );
  },

  async deleteRange({ commit }, rangeId) {
    await EventService.deleteRange(
      rangeId,
      (res) => {
        commit("delete_range_success", res.response);
      },
      (error) => {
        console.error("error", error);
      }
    );
  },

  async getSponsors({ commit }, matchId) {
    await EventService.getSponsorsDetails(
      matchId,
      (res) => {
        commit("get_sponsors_for_match_success", res.response);
      },
      (error) => {
        console.error("error", error);
      }
    );
  },

  async deleteSponsors({ commit }, sponsorId) {
    await EventService.deleteSponsor(
      sponsorId,
      (res) => {
        commit("delete_sponsor_success", res.response);
      },
      (error) => {
        console.error("error", error);
      }
    );
  },
};

// mutations
const mutations = {
  resetStatus(state) {
    state.status = "";
    state.joinMatchStatus = "";
    state.getMatchStatus = "";
    state.sponsorDetails = ""; 
  },
  updateMatchID(state, matchID){
    state.matchID = matchID
  },
  //
  create_event_request(state) {
    state.status = "loading";
  },
  create_event_success(state, id) {
    state.status = "success";
    state.matchID = id;
  },
  create_event_error(state) {
    state.status = "error";
  },
  //
  //
  update_event_request(state) {
    state.status = "loading";
  },
  update_event_success(state, id) {
    state.status = "success";
    state.matchID = id;
  },
  update_event_error(state) {
    state.status = "error";
  },
  //
  get_matches_by_club_request(state) {
    state.getMatchesByClubStatus = "loading";
    state.selectedClubMatches = [];
  },
  get_matches_by_club_success(state, matches) {
    state.selectedClubMatches = matches;
    state.getMatchesByClubStatus = "success";
  },
  get_matches_by_club_error(state) {
    state.getMatchesByClubStatus = "error";
  },
  //
  link_sponsor_request(state) {
    state.linkSponsorStatus = "loading";
  },
  link_sponsor_success(state, res) {
    state.linkSponsorStatus = "success";
    state.linkSponsorStatus = res;
  },
  link_sponsor_error(state) {
    state.linkSponsorStatus = "error";
  },
  //
  updateSelectedMatch(state, match) {
    state.selectedMatch = match;
  },
  updateAssignedMatch(state, match) {
    state.assignedMatch = match;
  },
  //
  get_match_request(state) {
    state.getMatchStatus = "loading";
  },
  get_match_success(state, match) {
    state.getMatchStatus = "success";
    state.selectedMatch = match;
  },
  get_match_error(state) {
    state.getMatchStatus = "error";
  },
  //
  join_match_request(state) {
    state.joinMatchStatus = "loading";
  },
  join_match_success(state, res) {
    state.joinMatchStatus = "success";
    state.joinResponse = res;
  },
  join_match_error(state, res) {
    state.joinMatchStatus = "error";
    state.joinResponse = res
  },
  //
  assigned_matches_request(state) {
    state.assignedMatchesStatus = "loading";
  },
  assigned_matches_success(state, res) {
    state.assignedMatchesStatus = "success";
    state.assignedMatches = res;
  },
  assigned_matches_error(state) {
    state.assignedMatchesStatus = "error";
  },
  //
  update_upcoming_official_match_summary(state, payload) {
    state.assignedMatchesSummary = Object.freeze({
      upcomingMatches: payload.count,
      matches: payload.response,
    });
  },
  update_upcoming_participant_match_summary(state, payload) {
    state.participantMatchSummary = Object.freeze({
      upcomingMatches: payload.count,
      matches: payload.response,
    });
  },
  update_myclubs_upcoming_match_summary(state, payload) {
    state.myClubsMatchSummary = Object.freeze({
      upcomingMatches: payload.count,
      matches: payload.response,
    });
  },

  update_upcoming_admin_match_summary(state, payload) {
    state.clubsMatchSummary = Object.freeze({
      upcomingMatches: payload.count,
      matches: payload.response,
    });
  },
  //
  update_registered_matches(state, payload) {
    state.registeredMatches = payload;
  },

  update_view_matches_per_club_request(state) {
    state.findMatchStatus = "loading";
  },

  update_view_matches_per_club(state, payload) {
    state.findMatchStatus = "success";
    state.viewMatchesPerClub = payload;
  },
  //
  get_sponsors_for_match_request(state) {
    state.removeClubAdminStatus = "loading";
  },

  get_sponsors_for_match_success(state, res) {
    state.removeClubAdminStatus = "success";
    state.sponsorDetails = res; 
  },

  get_sponsors_for_match_error(state) {
    state.removeClubAdminStatus = "error";
  },

  delete_sponsor_success(state) {
    state.deleteSponsor = "success";
  },

  get_range_details_request(state) {
    state.rangeInformation = "loading";
  },

  get_range_details_success(state, res) {
    state.rangeInformation = "success";
    state.rangeInformation = res; 
  },

  get_range_details_error(state) {
    state.rangeInformation = "error";
  },

  updateSelectedRange(state, range) {
    state.selectedRange = range;
  },

  update_range_request(state){
    state.updateRangeStatus = "loading"
  },

  update_range_success(state, res){
    state.updateRangeStatus = "success"
    state.updateRangeStatus = res; 
  },

  delete_range_success(state){
    state.updateRangeStatus = "success"
  },

  update_range_error(state){
    state.updateRangeStatus = "error"
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
