import axios from "axios";
import router from "../router";
import production from "../environment/production.json";
import development from "../environment/development.json";

let BASE_URL;
let config;

if (process.env.NODE_ENV === "production") {
  config = Object.freeze(production);
} else {
  config = Object.freeze(development);
}

BASE_URL = config.URL;

export class Service {
  constructor() {
    this.https = axios.create({
      baseURL: BASE_URL,
    });

    this.setup();
  }

  setup() {
    this.https.interceptors.request.use(
      async (config) => {
        const token = localStorage.getItem("token");
        config.headers = {
          Authorization: `Bearer ${token}`,
          Accept: "application/json",
        };
        return config;
      },
      (error) => {
        Promise.reject(error);
      }
    );
    this.https.interceptors.response.use(
      function(response) {
        return response;
      },
      function(error) {
        if (error.toString().includes("401")) {
          router.push({ name: "login" });
          localStorage.removeItem("token");
          localStorage.removeItem("vuex");
        }
        return Promise.reject(error);
      }
    );
  }
}
