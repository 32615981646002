<template>
  <div class="p-col-12">
    <div class="p-grid heading">
      <div class="p-col-2">
        <router-link :to="navigateBack">
          <i class="pi pi-arrow-left"></i>
        </router-link>
      </div>
      <div class="p-col-8">
        <HeaderLogo/>
        <h4>{{ title }}</h4>
      </div>
    </div>
  </div>
</template>

<script>
import HeaderLogo from "../shared/HeaderLogo.vue"
export default {
  name: 'navbar-mobile',
  components: { HeaderLogo },
  props: {
    title: { type: String, required: true },
    navigateBack: { type: String, default: "/dashboard" },
  },
}
</script>