import CaptureScoreService from "../../services/event.capture.score.service";

const state = () => ({
  selectedUserId:"",
  selectedUserDetails:"",
  scoreViewType:"",
  showRanges:false,
  dropDownValue: "Overall",
  confirmationPopupVisibility:false,
  getMatchRangesStatus: "",
  selectedRange: {},
  selectedMatch: {},
  matchRanges: {},
  participantResults: [],
  getParticipantStatus: "",
  captureScoreResponse: "",
  captureScoreStatus: "",
  scores: [],
  isAddNew: true,
  rangeScoresResults: [],
  getRangeScoresStatus: "",
  editScoreResponse: "",
  editScoreStatus: "",
  deleteScore: "",
  matchListItems: "",
  scoreBoard: {},
  download:"",
  canEditScores: false,
  scoreBoardStatus: false
});

// getters
const getters = {
  createEventStatus: (state) => state.status,
};

// actions
const actions = {
  async rangesForMarch({ commit }, matchId) {
    commit("get_match_ranges_request");
    await CaptureScoreService.rangesForMarch(
      matchId,
      (res) => {
        commit("get_match_ranges_success", res.response);
      },
      (error) => {
        commit("get_match_ranges_error");
        console.error("error", error);
      }
    );
  },
  async getRangesForParticipant({ commit }, payload) {
    commit("get_match_ranges_request");
    await CaptureScoreService.rangeForParticipant(
      payload,
      (res) => {
        commit("get_match_ranges_success", res.response);
      },
      (error) => {
        commit("get_match_ranges_error");
        console.error("error", error);
      }
    );
  },
  async participant({ commit }, payload) {
    commit("get_participant_request");
    await CaptureScoreService.userByParticipantNo(
      payload,
      (res) => {
        commit("get_participant_success", res.response);
      },
      (error) => {
        commit("get_participant_error");
        console.error("error", error);
      }
    );
  },

  async captureScore({ commit }, participantNumber) {
    commit("capture_score_request");
    await CaptureScoreService.captureScore(
      participantNumber,
      (res) => {
        commit("capture_score_success", res.response);
      },
      (error) => {
        commit("capture_score_error");
        console.error("error", error);
      }
    );
  },

  async rangeScores({ commit }, payload) {
    commit("get_range_scores_request");
    await CaptureScoreService.matchRangeScoreList(
      payload,
      (res) => {
        commit("get_range_scores_success", res.response);
      },
      (error) => {
        commit("get_range_scores_error");
        console.error("error", error);
      }
    );
  },

  async rangeScoresPerUser({ commit }, payload) {
    commit("get_range_scores_request");
    await CaptureScoreService.matchScoresPerRangePerUser(
      payload,
      (res) => {
        commit("get_range_scores_success", res.response);
      },
      (error) => {
        commit("get_range_scores_error");
        console.error("error", error);
      }
    );
  },

  async editParticipantScore({ commit }, payload) {
    commit("edit_score_request");
    await CaptureScoreService.editScore(
      payload,
      (res) => {
        commit("edit_score_success", res.response);
      },
      (error) => {
        commit("edit_score_error");
        console.error("error", error);
      }
    );
  },

  async deleteParticipantScore({ commit }, matchScoreID) {
    await CaptureScoreService.deleteScore(
      matchScoreID,
      (res) => {
        commit("delete_score_success", res.response);
      },
      (error) => {
        console.error("error", error);
      }
    );
  },

  async getMatchList({ commit }, payload) {
    commit("get_match_list_request");
    await CaptureScoreService.MatchList(
      payload,
      (res) => {
        commit("get_match_list_success", res.response);
      },
      (error) => {
        commit("get_match_list_error");
        console.error("error", error);
      }
    );
  },

  async getMyMatchList({ commit }, payload) {
    commit("get_match_list_request");
    await CaptureScoreService.MyMatchList(
      payload,
      (res) => {
        commit("get_match_list_success", res.response);
      },
      (error) => {
        commit("get_match_list_error");
        console.error("error", error);
      }
    );
  },

  async getOveralScoreBoard({ commit }, matchId) {
    commit("get_score_board_request");
    await CaptureScoreService.overalScoreBoard(
      matchId,
      (res) => {
        commit("get_score_board_success", res.response);
      },
      (error) => {
        commit("get_score_board_error");
        console.error("error", error);
      }
    );
  },

  async getDivisionScoreBoard({ commit }, matchId) {
    commit("get_score_board_request");
    await CaptureScoreService.divisionScoreBoard(
      matchId,
      (res) => {
        commit("get_score_board_success", res.response);
      },
      (error) => {
        commit("get_score_board_error");
        console.error("error", error);
      }
    );
  },

  async getCategoryScoreBoard({ commit }, matchId) {
    commit("get_score_board_request");
    await CaptureScoreService.categoryScoreBoard(
      matchId,
      (res) => {
        commit("get_score_board_success", res.response);
      },
      (error) => {
        commit("get_score_board_error");
        console.error("error", error);
      }
    );
  },

  async getMyScores({ commit }, payload) {
    commit("get_score_board_request");
    await CaptureScoreService.MyScores(
      payload,
      (res) => {
        commit("get_score_board_success", res.response);
      },
      (error) => {
        commit("get_score_board_error");
        console.error("error", error);
      }
    );
  },

  async canEditScore({ commit }, payload) {
    commit("can_edit_score_request");
    await CaptureScoreService.EditScoreValidation(
      payload,
      (res) => {
        commit("can_edit_score_success", res.canEdit);
      },
      (error) => {
        commit("can_edit_score_error");
        console.error("error", error);
      }
    );
  },
};

// mutations
const mutations = {
  resetStatus(state) {
    state.getMatchRangesStatus = "";
    this.scoreBoardStatus = ""
  },
   //
   updateDropDownValue(state, dropDownValue) {
    state.dropDownValue = dropDownValue;
  },
  updateSelectedUserId(state, selectedUserId) {
    state.selectedUserId = selectedUserId;
  },
  updateSelectedUserDetails(state, selectedUserDetails) {
    state.selectedUserDetails = selectedUserDetails;
  },
  //
  get_match_ranges_request(state) {
    state.getMatchRangesStatus = "loading";
  },
  get_match_ranges_success(state, res) {
    state.getMatchRangesStatus = "success";
    state.matchRanges = res;
  },
  get_match_ranges_error(state) {
    state.getMatchRangesStatus = "error";
  },
  //
  get_participant_request(state) {
    state.getParticipantStatus = "loading";
  },
  get_participant_success(state, res) {
    state.getParticipantStatus = "success";
    state.participantResults = res;
  },
  get_participant_error(state) {
    state.getParticipantStatus = "error";
  },
  //
  capture_score_request(state) {
    state.captureScoreStatus = "loading";
  },
  capture_score_success(state) {
    state.captureScoreStatus = "success";
    state.captureScoreResponse = "";
    state.scores = [];
  },
  capture_score_error(state) {
    state.captureScoreStatus = "error";
  },
  updateScores(state, scores) {
    state.scores = scores;
  },
  addScore(state, score) {
    state.scores = [
      ...state.scores.filter(
        (element) => element.participantNo !== score.participantNo
      ),
      score,
    ]; //avoid duplicates
  },
  editScore(state, payload) {
    let score = state.scores.find(
      (s) => s.participantNo === payload.participantNo
    );
    score.score = payload.score;
    state.scores = [
      ...state.scores.filter(
        (element) => element.participantNo !== payload.participantNo
      ),
      score,
    ];
  },

  //download table
  updateDownload(state, download) {
    state.download = download;
  },

  updateScoreViewType(state, scoreViewType) {
    state.scoreViewType = scoreViewType;
  },

  updateShowRanges(state, showRanges) {
    state.showRanges = showRanges;
  },
  
  //Confirmation Popup admin modal

  updateConfirmationPopupVisibility(state, confirmationPopupVisibility) {
    state.confirmationPopupVisibility = confirmationPopupVisibility;
  },

  //Confirmation Popup admin modalend

  updateIsAddNew(state, value) {
    state.isAddNew = value;
  },
  //
  updateSelectedRange(state, range) {
    state.rangeScoresResults = [];
    state.selectedRange = range;
  },
  updateSelectedMatch(state, match) {
    state.selectedMatch = match;
  },

  get_range_scores_request(state) {
    state.getRangeScoresStatus = "loading";
  },
  get_range_scores_success(state, res) {
    state.getRangeScoresStatus = "success";
    state.rangeScoresResults = res;
  },
  get_range_scores_error(state) {
    state.getRangeScoresStatus = "error";
  },
  
  edit_score_request(state) {
    state.editScoreStatus = "loading";
  },
  edit_score_success(state, res) {
    state.editScoreStatus = "success";
    state.editScoreResponse = res;
    state.scores = [];
  },
  edit_score_error(state) {
    state.editScoreStatus = "error";
  },
  delete_score_success(state) {
    state.deleteScore = "success";
  },
  get_match_list_request(state) {
    state.matchListItems = "loading";
  },
  get_match_list_success(state, res) {
    state.matchListItems = "success";
    state.matchListItems = res;
  },
  get_match_list_error(state) {
    state.matchListItems = "error";
  },
  get_score_board_request(state) {
    state.scoreBoardStatus = "loading";
  },
  get_score_board_success(state, res) {
    state.scoreBoardStatus = "success";
    state.scoreBoard = res;
  },
  get_score_board_error(state) {
    state.scoreBoardStatus = "error";
  },
  can_edit_score_request(state) {
    state.canEditScores = "loading";
  },
  can_edit_score_success(state, res) {
    state.canEditScores = "success";
    state.canEditScores = res;
  },
  can_edit_score_error(state) {
    state.canEditScores = "error";
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
