<template>
  <div class="p-col-12 p-p-4 content-area">
    <div class="p-d-flex p-jc-center">
      <dashboard-tile :stretch="true" title="My Clubs" navigateTo="clubs" iconSrc="my-clubs-icon.png" />
    </div>
    <div class="p-col-12 p-text-left p-pl-0 p-pt-4 p-pb-3 h-text">Today</div>
    <div class="p-col-12 p-text-left p-pl-0 p-pt-4 p-pb-3" v-if="matchSummary.length === 0 && matchClubSummary.length === 0">
        No matches to administer today
    </div>
    <match-summary-accordion :matchSummary="matchClubSummary"/>
    <match-summary-accordion :matchSummary="matchSummary"/>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import DashboardTile from '../shared/DashboardTile.vue';
import MatchSummaryAccordion from './MatchSummaryAccordion.vue';
export default {
  name: "admin",
  data() {
    return {
      matchSummary: [],
      matchClubSummary: [],
    }
  },
  components: { DashboardTile, MatchSummaryAccordion },
  methods: {
  },
  created() {
    this.$store.dispatch("eventModule/myClubMatches", this.userId)
    .then(() => {
      if (this.myClubMatchSummary.upcomingMatches > 0) {
        const data = {
          ...this.myClubMatchSummary,
          title: this.constants.COMMON_TEXT.MY_CLUBS,
          iconSrc: 'my-clubs-icon.png',
        }
        this.matchClubSummary = [...this.matchClubSummary, data];
      }
    });

    this.$store.dispatch("eventModule/adminMatchesToday", this.userId)
    .then(() => {
      if (this.clubMatchSummary.upcomingMatches > 0) {
        const data = {
          ...this.clubMatchSummary,
          title: this.constants.COMMON_TEXT.AS_PARTICIPANT,
          iconSrc: 'as-participant-icon.png',
        }
        this.matchSummary = [...this.matchSummary, data];
      }
    });
  },
  computed: {
    ...mapGetters({
      clubMatchSummary: "eventModule/getClubsMatchSummary",
      myClubMatchSummary: "eventModule/getMyClubsMatchSummary",
      userId: "authenticate/getUserId",
    }),
  },
};
</script>

<style lang="scss" scoped>
</style>