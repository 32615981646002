

<template>
  <div class="p-grid p-ai-start vertical-container p-m-0">
    <div class="p-col-12">
      <div class="box">
        <div class="p-fluid p-grid p-formgrid p-text-left">
          <div class="p-field p-col-12 p-pt-2 h-text">Match details</div>
          <div class="p-field p-col-12">
            <span class="p-input-icon-left">
              <i class="pi pi-globe" />
              <InputText
                id="name"
                type="text"
                :class="{ 'p-invalid': v$.match.name.required.$invalid }"
                placeholder="Match name"
                v-model="match.name"
              />
            </span>
            <p
              class="error-message"
              v-if="v$.match.name.required.$invalid"
            >* Match name {{ errorMessage }}</p>
          </div>

          <div class="p-field p-col-12 p-pt-2">
            <span class="p-input-icon-left">
              <i class="pi pi-info-circle" />
              <InputText
                type="text"
                :class="{ 'p-invalid': v$.match.type.required.$invalid }"
                placeholder="Match type"
                v-model="match.type"
              />
            </span>
            <p
              class="error-message"
              v-if="v$.match.type.required.$invalid"
            >* Match type {{ errorMessage }}</p>
          </div>

          <div class="p-field p-col-12 p-pt-2" v-if="selectedClubBranches.length > 0">
            <div class="p-field p-col-12 p-pt-2 h-text">Club Branch</div>
            <Dropdown
              id="branchID"
              v-model="match.branchID"
              :options="selectedClubBranches"
              placeholder="Club branches"
              optionValue="branchID"
              optionLabel="branchName"
            />
          </div>

          <div class="p-px-2 p-pt-4 h-text">Match dates</div>
          <div class="p-grid p-py-4 p-px-2">
            <div class="p-col-6">
              <span>
                <Calendar
                  id="start"
                  v-model="match.startDate"
                  dateFormat="dd/mm/yy"
                  placeholder="Start date"
                  :showTime="true"
                  :showIcon="true"
                  :class="{ 'p-invalid': v$.match.startDate.required.$invalid }"
                  :yearNavigator="true"
                  :monthNavigator="true"
                  :manualInput="false"
                  yearRange="2020:2080"
                  panelClass="calendarMatchStart"
                >
                  <template #footer>
                    <div class="close-calendar">
                      <button
                        class="p-button p-component"
                        @click="closeCalendar('calendarMatchStart')"
                      >SET</button>
                    </div>
                  </template>
                </Calendar>
              </span>
            </div>
            <div class="p-col-6">
              <span>
                <Calendar
                  id="end"
                  v-model="match.endDate"
                  dateFormat="dd/mm/yy"
                  placeholder="End date"
                  :showTime="true"
                  :showIcon="true"
                  :yearNavigator="true"
                  :monthNavigator="true"
                  :manualInput="false"
                  yearRange="2020:2080"
                  panelClass="calendarMatchEnd"
                  :class="{ 'p-invalid': v$.match.endDate.required.$invalid }"
                >
                  <template #footer>
                    <div class="close-calendar">
                      <button
                        class="p-button p-component"
                        @click="closeCalendar('calendarMatchEnd')"
                      >SET</button>
                    </div>
                  </template>
                </Calendar>
              </span>
            </div>
          </div>

          <div class="p-px-2 p-pt-4 h-text">Match registration</div>
          <div class="p-grid p-py-4 p-px-2">
            <div class="p-col-6">
              <span>
                <Calendar
                  v-model="match.registrationStartDate"
                  dateFormat="dd/mm/yy"
                  placeholder="Start date"
                  :showTime="true"
                  :showIcon="true"
                  :yearNavigator="true"
                  :monthNavigator="true"
                  :manualInput="false"
                  yearRange="2020:2080"
                  panelClass="calendarRegStart"
                  :class="{
                    'p-invalid':
                      v$.match.registrationStartDate.required.$invalid
                  }"
                >
                  <template #footer>
                    <div class="close-calendar">
                      <button
                        class="p-button p-component"
                        @click="closeCalendar('calendarRegStart')"
                      >SET</button>
                    </div>
                  </template>
                </Calendar>
              </span>
            </div>
            <div class="p-col-6">
              <span>
                <Calendar
                  v-model="match.registrationEndDate"
                  dateFormat="dd/mm/yy"
                  placeholder="End date"
                  :showTime="true"
                  :showIcon="true"
                  :yearNavigator="true"
                  :monthNavigator="true"
                  :manualInput="false"
                  yearRange="2020:2080"
                  panelClass="calendarRegEnd"
                  :class="{
                    'p-invalid': v$.match.registrationEndDate.required.$invalid
                  }"
                >
                  <template #footer>
                    <div class="close-calendar">
                      <button
                        class="p-button p-component"
                        @click="closeCalendar('calendarRegEnd')"
                      >SET</button>
                    </div>
                  </template>
                </Calendar>
              </span>
            </div>
          </div>

          <div class="p-col-12 reg-note p-pb-4">
            Participants will only be able to register for the match between the
            start and end date
          </div>
          <div class="p-field p-col-12 p-pt-3">
            <div class="p-col-12 p-text-left p-pl-0 p-pt-4 p-pb-3 h-text">Number of participants</div>
            <InputNumber
              id="participants"
              placeholder="Number of participants"
              v-model="match.numberOfParticipants"
              :class="{
                'p-invalid': v$.match.numberOfParticipants.required.$invalid
              }"
            />
            <!-- </span> -->
            <p
              class="error-message"
              v-if="v$.match.numberOfParticipants.required.$invalid"
            >* Number of participants {{ errorMessage }}</p>
          </div>

          <div class="p-field p-col-8 p-pt-4 h-text">Match category</div>
          <div class="p-field p-col-2 p-pt-4 p-text-right icon-red">
            <div v-if="categoryList.length > 1" @click="onRemoveCategory">
              <i class="pi pi-minus"></i>
            </div>
          </div>
          <div class="p-field p-col-2 p-pt-4 p-text-right icon-red" @click="onAddCategory">
            <i class="pi pi-plus"></i>
          </div>
          <div class="p-col-12 p-p-0" v-for="index in categoryList.length" :key="index">
            <div class="p-field p-col-12">
              <span class="p-float-label">
                <Dropdown
                  id="categoryID"
                  v-model="categoryList[index - 1].categoryID"
                  :options="selectedClubCategories"
                  placeholder="Match category"
                  optionValue="categoryID"
                  optionLabel="name"
                />
              </span>
              <p
                class="error-message"
                v-if="
                  categoryList[index - 1].categoryID == '' &&
                    !isSelectedCategory
                "
              >* Please select a category</p>
            </div>

            <div class="p-field p-col-12" v-if="categoryList[index - 1].categoryID !== ''">
              <InputNumber
                placeholder="Match fee"
                mode="currency"
                currency="ZAR"
                v-model="categoryList[index - 1].amount"
              />
              <p
                class="error-message"
                v-if="categoryList[index - 1].amount == undefined"
              >* Please provide category fee</p>
            </div>
          </div>

          <div class="p-field p-col-12 p-pt-3">
            <span class="p-float-label">
              <MultiSelect
                v-model="match.matchDivision"
                :options="selectedClubDivisions"
                placeholder="Match division"
                optionValue="divisionID"
                optionLabel="name"
                display="chip"
              />
            </span>
          </div>

          <div class="p-field p-col-12 p-pt-3">
            <span class="p-input-icon-left">
              <i class="pi pi-book" />
              <Textarea
                placeholder="Match details"
                v-model="match.details"
                :autoResize="true"
                rows="5"
                cols="30"
              />
            </span>
            <div class="reg-note p-pl-1">Additional match comments</div>
          </div>

          <div class="p-field p-col-8 p-pt-4 h-text">Address</div>

          <div class="p-field p-col-12 p-pt-3">
            <span class="p-input-icon-left">
              <i class="pi pi-directions" />
              <Textarea
                id="location"
                type="text"
                placeholder="Address"
                v-model="match.location"
                :class="{ 'p-invalid': v$.match.location.required.$invalid }"
                :autoResize="true"
              />
            </span>
            <p
              class="error-message"
              v-if="v$.match.location.required.$invalid"
            >* Address {{ errorMessage }}</p>
          </div>
          <div class="p-field p-col-12 p-pt-3">
            <span class="p-input-icon-left">
              <i class="pi pi-map-marker" />
              <InputText
                id="MapDirections"
                type="text"
                placeholder="Map Directions"
                v-model="match.mapDirections"
              />
            </span>
          </div>
          <div class="p-col-12">
            <p
              class="error-message"
              v-if="v$.match.name.required.$invalid"
            >* Match name {{ errorMessage }}</p>
            <p
              class="error-message"
              v-if="v$.match.type.required.$invalid"
            >* Match type {{ errorMessage }}</p>
            <p
              class="error-message"
              v-if="v$.match.numberOfParticipants.required.$invalid"
            >* Number of participants {{ errorMessage }}</p>
          </div>
          <div class="p-col p-pt-4">
            <Button
              label="NEXT"
              class="p-button-raised p-button-rounded p-pl-2 p-pr-2 p-m-0"
              @click="onNext"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import { mapState } from "vuex";
import { ZERO_ID } from "../../../helpers/constants.js";

export default {
  name: "Match",
  setup() {
    return {
      v$: useVuelidate()
    };
  },
  created() {
    this.$store.dispatch(
      "branchModule/branchesForClub",
      this.selectedClub.clubID
    );
    this.$store.dispatch(
      "categoryModule/categoriesForClub",
      this.selectedClub.clubID
    );
    this.$store.dispatch(
      "divisionModule/divisionsForClub",
      this.selectedClub.clubID
    );
    this.isSelectedCategory = true;
  },
  data() {
    return {
      errorMessage: "is required",
      categoryList: [
        {
          categoryID: "",
          amount: null
        }
      ],
      match: {
        clubID: "",
        branchID: ZERO_ID.ID,
        name: "",
        type: "",
        startDate: new Date(),
        endDate: new Date(),
        registrationStartDate: new Date(),
        registrationEndDate: new Date(),
        numberOfParticipants: null,
        matchDivision: [],
        details: "",
        location: "",
        mapDirections: "",
        ranges: [],
        sponsors: [],
        matchCategories: [],
        matchOfficials: []
      },
      isSelectedCategory: false
    };
  },
  validations: {
    match: {
      name: {
        required,
        $lazy: true
      },
      type: {
        required,
        $lazy: true
      },
      startDate: {
        required,
        $lazy: true
      },
      endDate: {
        required,
        $lazy: true
      },
      registrationStartDate: {
        required,
        $lazy: true
      },
      registrationEndDate: {
        required,
        $lazy: true
      },
      numberOfParticipants: {
        required,
        $lazy: true
      },

      location: {
        required,
        $lazy: true
      }
    }
  },
  computed: {
    ...mapState("categoryModule", ["selectedClubCategories"]),
    ...mapState("divisionModule", ["selectedClubDivisions"]),
    ...mapState("branchModule", ["selectedClubBranches"]),
    ...mapState("clubModule", ["selectedClub"])
  },
  methods: {
    async onNext() {
      this.categorySelected();
      this.v$.$touch();
      if (
        !this.v$.$invalid &&
        this.categorySelected() &&
        this.matchFeeProvided()
      ) {
        this.match.clubID = this.selectedClub.clubID;
        this.match.matchCategories = this.categoryList;
        this.match.matchDivision = this.match.matchDivision.map(o => ({
          divisionID: o
        }));
        this.$emit("new-match", this.match);
      }
    },
    onAddCategory() {
      this.categoryList.push({
        categoryID: "",
        amount: null
      });
    },
    onRemoveCategory() {
      if (this.categoryList.length > 1) this.categoryList.pop();
    },
    closeCalendar(calendarName) {
      const elements = document.getElementsByClassName(
        "p-calendar p-component p-inputwrapper  p-calendar-w-btn p-inputwrapper-filled"
      );
      let foundElement = undefined;
      let childElements = undefined;
      let index = -1;
      elements.forEach(element => {
        childElements = element.children;
        if (childElements.length > 0) {
          childElements.forEach(child => {
            index = child.className.indexOf(calendarName);
            if (index > 0) {
              childElements.forEach(c => {
                if (c.type == "button") {
                  foundElement = c;
                }
              });
            }
          });
        }
      });
      if (foundElement) {
        foundElement.click();
      }
    },
    categorySelected() {
      if (this.categoryList[0].categoryID == "") {
        this.isSelectedCategory = false;
        return false;
      }
      return true;
    },
    matchFeeProvided() {
      var isFee = true;
      if (this.categoryList[0].categoryID != "") {
        this.categoryList.forEach(element => {
          if (element.amount == undefined) {
            isFee = false;
            this.isSelectedCategory = false;
          }
        });
        return isFee;
      }
      return isFee;
    }
  }
};
</script>

<style lang="scss" scoped>
.reg-note {
  color: #cbcfd0;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 1.25px;
  text-align: left;
}

.close-calendar {
  float: right;
}

:deep {
  .p-multiselect.p-multiselect-chip .p-multiselect-token {
    background: transparent;
  }
  .p-datepicker .p-datepicker-header .p-datepicker-title .p-datepicker-month,
  .p-datepicker .p-datepicker-header .p-datepicker-title select {
    margin-right: 0.5rem;
    padding: 10px;
    border: 0;
    box-shadow: 0 5px 5px -3px rgb(0 0 0 / 20%), 0 8px 10px 1px rgb(0 0 0 / 14%),
      0 3px 14px 2px rgb(0 0 0 / 12%);
  }
  .p-fluid .p-calendar {
    position: relative;
  }
  .p-fluid .p-calendar .p-inputtext {
    font-size: 12px;
    padding: 5px;
  }
  .p-datepicker {
    width: auto;
    left: -160px;
  }
  .p-datepicker:not(.p-datepicker-inline) .p-datepicker-header {
    display: -webkit-box;
  }
  .p-link .pi,
  .pi {
    color: rgba(0, 0, 0, 0.6);
  }
}
</style>

