import dateHelper from "./dateHelper";
import scrollHelper from "./scrollHelper";

import {
  COMMON_TEXT,
  ADMIN_TAB,
  PARTICIPANT_TAB,
  DATE_FILTER,
  REGISTRATION,
  MATCH_STATUS_TYPES,
  PAGE_NUMBERS,
  ZERO_ID,
  IMAGE_VALIDATION,
  EMPTY_STATE_TEXT,
  FORM_VALIDATION_ERRORS
} from "./constants";

export default {
  methods: {
    timeFromDateString: dateHelper.timeFromDateString,
    abbreviatedDatefromDateString: dateHelper.abbreviatedDatefromDateString,
    abbreviatedDateTimefromDateString: dateHelper.abbreviatedDateTimefromDateString,
    scrollToScreenTop: scrollHelper.scrollToScreenTop,
  },
  created() {
    this.constants = {
      COMMON_TEXT,
      ADMIN_TAB,
      PARTICIPANT_TAB,
      DATE_FILTER,
      REGISTRATION,
      MATCH_STATUS_TYPES,
      PAGE_NUMBERS,
      ZERO_ID,
      IMAGE_VALIDATION,
      EMPTY_STATE_TEXT,
      FORM_VALIDATION_ERRORS
    };
  },
};